<div id="root" fxLayout="column" fxFlexFill>
  <h1 id="title">Test Title</h1>

  <div id="container" fxLayout="row" fxFlex>
    <div id="container-left" fxLayout="column" fxFlex="50">
      <h2>Left</h2>
      <div id="table-left" fxFlex>
        <div id="table-content-left"></div>
      </div>
    </div>
    <div id="container-right" fxLayout="column" fxFlex="50">
      <h2>Right</h2>
      <div id="table-right" fxFlex>
        <div id="table-content-right"></div>
      </div>
    </div>
  </div>
</div>
