<div *ngIf="brmCloud.loggedOnUser$ | async; else notLoggedOn" class="mat-elevation-z8">
  <table *ngIf="(sessions$ | async)?.length; else noData" mat-table [dataSource]="dataSource" class="full-width-table" aria-label="Elements">
    <!-- Session date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Session Date</th>
      <td mat-cell *matCellDef="let session">
        <a [routerLink]="['/userSessions', userID, session.sessionID]">{{session.date.toDate() | date:'medium'}}</a>
      </td>
    </ng-container>

    <!-- Recording type Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let session">{{session.email}}</td>
    </ng-container>

    <!-- Recording type Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let session">{{session.description}}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let session">
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <ng-template #noData>
    <div style="padding: 1em">There are no sessions with you as the athlete.</div>
  </ng-template>
</div>

<ng-template #notLoggedOn>
  <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> to see your data.
</ng-template>
