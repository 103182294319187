<mat-toolbar class="bread-crumb" [ngStyle]="{'background-color': bgColor}">
  <mat-list class="bread-crumb-list" *ngIf="breadcrumbs.length">
      <mat-list-item>
          <span class="bread-crumb-spacer">
              <span *ngFor="let item of breadcrumbs; let i = index">
                <!-- Selection menu -->
                <mat-menu #menu="matMenu">
                  <button *ngFor="let dropItem of item.dropdownItems" mat-menu-item>{{dropItem.label}}</button>
                </mat-menu>
                <button *ngIf="item.dropdownItems?.length" mat-button [matMenuTriggerFor]="menu">{{ item.label }}</button>

                <!-- Link -->
                <a *ngIf="!item.dropdownItems?.length && item?.url" [routerLink]="item?.url" class="bread-crumb-list-item" [ngStyle]="{'font-size': fontSize}" [ngStyle]="{'color': fontColor}">{{ item.label }}</a>

                <!-- Just text -->
                <span *ngIf="!item.dropdownItems?.length && !item?.url" class="bread-crumb-list-item" [ngStyle]="{'font-size': fontSize}"  [ngStyle]="{'color': lastLinkColor}">{{ item.label }}</span>

                <!-- Separator -->
                <span *ngIf="breadcrumbs.length !== i+1">{{symbol}}</span>
              </span>
          </span>
      </mat-list-item>
  </mat-list>
</mat-toolbar>
