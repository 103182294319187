import { Component, ViewChild, Inject } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialogRef } from '@angular/material/dialog';

import { BrmCloudService } from '../../lib/brm-cloud.service';
import { BRMUser } from '../../lib/BRMUser';
import { FirebaseError } from 'firebase/app';

@Component({
  selector: 'brm-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  constructor(public readonly brmCloud: BrmCloudService) { }

}

///// Register User Dialog /////

@Component({
  selector: 'brm-register-user-dialog',
  templateUrl: 'register-user-dialog.html',
  styleUrls: ['register-user-dialog.scss']
})
export class RegisterUserDialog {
  error = "";
  get registering() { return this.brmCloud.registeringUser; }

  formData = {
    email: '',
    firstName: '',
    lastName: '',
    langCode: 'en'
  };

  constructor(public dialogRef: MatDialogRef<RegisterUserDialog>, private brmCloud: BrmCloudService) {}

  registerUser() {
    console.log("Registering user with data", this.formData);
    this.brmCloud.registerUser(this.formData.email, this.formData.firstName, this.formData.lastName, this.formData.langCode)
    .then(() => {
      console.log("Register User successful");
      this.error = "";
      this.dialogRef.close(true);
    }).catch((error: FirebaseError) => {
      console.log("Register User failed: ", error);
      this.error = error.message;
    });
  }
}
