import { Component, Input, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, map, of } from 'rxjs';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { MotionDataService } from '../services/motion-data.service';
import { MotionData, TrackingPoint } from '../services/motion-data';
import { MotionAnalysis } from '../services/motion-analysis';
import { MotionRecordingInfo } from '../services/motion-recording-info.interface';
import { MotionBody3D } from '../motion3d/motion-body-3d';
import { PedalCyclesGraphComponent } from '../pedalcycles-graph/pedalcycles-graph.component';
import { Motion3DComponent } from '../motion3d/motion3d.component';

@Component({
  selector: 'motion-recording-details-cycling',
  templateUrl: './recording-details-cycling.component.html',
  styleUrls: ['./recording-details-cycling.component.scss']
})
export class RecordingDetailsCyclingComponent implements OnInit, AfterViewInit {

  @ViewChild(MotionBody3D) motionBody3D!: MotionBody3D;
  @ViewChild(PedalCyclesGraphComponent) pedalCyclesGraph!: PedalCyclesGraphComponent;

  // Add TrackingPoint class so we can use in our html
  TrackingPoint = TrackingPoint;

  cyclistID: string|null = null;
  recordingID: string|null = null;

  recording$: Observable<MotionRecordingInfo|undefined>;

  recordingData$: Observable<MotionData>;
  recordingData: MotionData|null = null;

  motionAnalysis: MotionAnalysis|null = null;

  // @ViewChild(Motion3DComponent) motion3D_right!: Motion3DComponent;
  @ViewChild("#motion_right") motion3D_right!: Motion3DComponent;
  @ViewChild("#motion_back") motion3D_back!: Motion3DComponent;
  slowMotion: boolean = false;

  constructor(private route: ActivatedRoute, public readonly brmCloud: BrmCloudService, private readonly motionData: MotionDataService, private firestore: AngularFirestore, private storage: AngularFireStorage) {

  }

  ngOnInit(): void {
    this.recording$ = this.route.paramMap.pipe(
      switchMap(params => {
        console.log("Params", params)
        this.cyclistID = params.get('cyclistID');
        this.recordingID = params.get('recordingID');

        // Set active cyclist and recording
        try {
          this.motionData.setActiveCyclist(this.cyclistID);
          this.motionData.setActiveRecording(this.recordingID);

          return this.motionData.getRecordingInfo(this.cyclistID, this.recordingID);
        }
        catch(err) {
          console.error("Error setting active cyclist/recording", err);
          return of(undefined);
        }
      })
    );

    // TEMP TEST
    this.recording$.subscribe((rec: any) => {
      console.log("subscribed recording next:", rec);

      if(this.cyclistID) {
        this.recordingData$ = this.motionData.getData(this.cyclistID, rec.recordingID);
        this.recordingData$.subscribe(data => {
          this.recordingData = data;

          console.log("Cyclist ID: " + this.cyclistID);
          console.log("Motion data: ", this.motionData);
          console.log("Recording data: ", this.recordingData);

          console.log("Recording data loaded. Recording duration: " + this.recordingData.duration + "s");

          // Do motion analysis
          this.motionAnalysis = new MotionAnalysis(rec, this.recordingData);

          const num = this.motionAnalysis.pedalCycles?.length;
          console.log("Cycling recording has " + num + " cycle(s)", this.motionAnalysis.pedalCycles);

          const angles = this.motionAnalysis.frameCrankAngles;
          console.log("Cycling recording has " + angles?.length + " crank angle(s)", angles);

          // Test angle metrics
          const leftKneeAngles = this.motionAnalysis.bodyAngleMetrics[TrackingPoint.LeftKnee];
          if(leftKneeAngles) {
            const min = leftKneeAngles.x.min;
            const max = leftKneeAngles.x.max;
            const range = leftKneeAngles.x.range;
            const avg = leftKneeAngles.x.avg;
            console.log(`Left knee angles: min=${min}, max=${max}, range=${range}, avg=${avg}`);
          }

          const rightKneeAngles = this.motionAnalysis.bodyAngleMetrics[TrackingPoint.RightKnee];
          if(rightKneeAngles) {
            const min = rightKneeAngles.x.min;
            const max = rightKneeAngles.x.max;
            const range = rightKneeAngles.x.range;
            const avg = rightKneeAngles.x.avg;
            console.log(`Right knee angles: min=${min}, max=${max}, range=${range}, avg=${avg}`);
          }
        });
      }
    })
  }

  ngAfterViewInit() {
    console.log("AfterViewInit: ", this.motion3D_right);
    // this.motion3D_right.setLeftView();
    //this.motion3D_back.setBackView();
  }
}
