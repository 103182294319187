import { OnInit, OnDestroy, AfterViewInit, Component, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, of, switchMap } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { MotionRecordingInfo } from '../services/motion-recording-info.interface';
import { MotionDataService } from '../services/motion-data.service';

@Component({
  selector: 'motion-recording-list',
  templateUrl: './motion-recording-list.component.html',
  styleUrls: ['./motion-recording-list.component.scss']
})


export class MotionRecordingListComponent implements OnInit, OnChanges, AfterViewChecked {
  // Emitted when the recordings have changed
  @Output() recordingsChanged = new EventEmitter<void>();
  // Emitted when a recording is selected
  @Output() recordingSelected = new EventEmitter<MotionRecordingInfo>();

  @Input() cyclistID?: string;

  // Filters
  private _showCyclingRecordings: boolean = true;
  get showCyclingRecordings() { return this._showCyclingRecordings; }
  set showCyclingRecordings(show: boolean) { this._showCyclingRecordings = show; this.setupRecordings(); }

  _showBikePictures: boolean = true;
  get showBikePictures() { return this._showBikePictures; }
  set showBikePictures(show: boolean) { this._showBikePictures = show; this.setupRecordings(); }

  recordings$: Observable<MotionRecordingInfo[]>;
  filteredRecordings: MotionRecordingInfo[] = [];

  selectedRecording: MotionRecordingInfo|null = null;
  _scrolledToSelectedRecording = false;

  dataSource: MatTableDataSource<MotionRecordingInfo>;
  filter: string = "";

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [ 'name', 'recordingTime', 'recordingType', 'actions' ];

  constructor(public readonly brmCloud: BrmCloudService, public readonly motionData: MotionDataService, private firestore: AngularFirestore, private storage: AngularFireStorage, private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    /*this.recordings$ = this.route.paramMap.pipe(
      switchMap(params => {
        console.log("Params", params)
        this.cyclistID = params.get('cyclistID');

        if(this.cyclistID) {
          const collection = this.recordingsCollectionRef(this.cyclistID);
          return collection.valueChanges({ idField: 'recordingID' });
        } else {
          return of([ ]);
        }
      })
    );*/

    // A cyclist ID in the URL has precedence over the input cyclistID
    const paramCyclistID = this.route.snapshot.paramMap.get('cyclistID');
    if(paramCyclistID) {
      console.log("Cyclist ID provided as URL parameter: " + paramCyclistID);
      this.cyclistID = paramCyclistID;
    }

    // Use current user if no cyclistID is set
    if(!this.cyclistID) {
      console.log("Subscribing to logged on user for cyclist ID");
      this.brmCloud.loggedOnUser$.subscribe(userDoc => {
        this.cyclistID = userDoc?.userID;
        console.log("Using logged on user for cyclist ID: " + this.cyclistID);

        // ngOnChanges is not called yet
        this.setupRecordings();
      });
    }

    // Select currently active recording
    const rec = this.motionData.activeRecording || this.motionData.prevActiveRecording;
    if(rec) {
      console.log("Setting selected recording to", rec);
      // We don't emit the selected signal to prevent the recording details to be opened as a result
      this.selectRecording(rec, false);
  }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changes: ", changes);

    if(changes.cyclistID.currentValue != changes.cyclistID.previousValue) {
      console.log("Provided recording ID changed from " + changes.cyclistID.previousValue + " to " + changes.cyclistID.currentValue);
      this.setupRecordings();
    }
  }

  ngAfterViewChecked(): void {
    // Scroll to selected recording if it was not ready before
    if(!this._scrolledToSelectedRecording && this.selectedRecording) {
      if(this.scrollToRecording(this.selectedRecording)) {
        this._scrolledToSelectedRecording = true;
      }
    }
  }

  setupRecordings() {
    if(this.cyclistID) {
      this.recordings$ = this.motionData.getRecordings(this.cyclistID);
    } else {
      this.recordings$ = of([]);
    }

    // Set data source from recordings
    this.recordings$.subscribe(recs => {
      this.filteredRecordings = recs.filter(rec => { return this.checkFilter(rec, ""); });

      // Fill in section titles from dates
      let lastDateStr = "";
      this.filteredRecordings.forEach(rec => {
        const dateStr = formatDate(rec.recordingDate, 'yyyy-MM-dd', 'en');
        if(dateStr != lastDateStr) {
          //console.log("New section at recording " + rec.name, dateStr);
          rec.sectionTitle = dateStr;
          lastDateStr = dateStr;
        }
      });

      this.dataSource = new MatTableDataSource(this.filteredRecordings);

      // Custom filter
      this.dataSource.filterPredicate = (data, filter) => {
        return this.checkFilter(data, filter);
      };

      // Apply current filter
      this.dataSource.filter = this.filter;

      // Scroll to selected recording
      this._scrolledToSelectedRecording = false;
      if(this.selectedRecording) {
        this.scrollToRecording(this.selectedRecording);
      }

      this.recordingsChanged.emit();
    });
  }

  selectRecording(recording: MotionRecordingInfo, emitSignal: boolean = true) {
    console.log("Recording selected with name " + recording.name);
    this.selectedRecording = recording;

    // Scroll to selected row
    this.scrollToRecording(recording);

    if(emitSignal) {
      this.recordingSelected.emit(recording);

      // TEMP Always show details
      if(this.cyclistID) {
        this.showDetails(this.cyclistID, recording.recordingID, recording.recordingType);
      }
    }
  }

  scrollToRecording(recording: MotionRecordingInfo) {
    // Scroll to selected row
    const elName = `row-${recording.recordingID}`;
    const el = document.getElementById(elName);
    //console.log("Scrolling to", elName, el);
    el?.scrollIntoView({ block: 'center', });

    return (el != null);
  }

  applyTextFilter(event?: Event) {
    if(event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.filter = filterValue.trim().toLowerCase();
      console.log("Filtering recordings:", filterValue);
      this.dataSource.filter = this.filter;
    } else {
      // Force refresh
      this.recordingsChanged.emit();
    }
  }

  applyTypeFilter(event?: Event) {
    console.log("Filtering recordings on type:", this.showBikePictures, this.showCyclingRecordings);
    this.setupRecordings();
  }

  checkFilter(rec: MotionRecordingInfo, filter: string) {
    // We also filter on type here
    if(!this.showCyclingRecordings && rec.recordingType === "cycling") {
      return false;
    }
    if(!this.showBikePictures && rec.recordingType === "bike") {
      return false;
    }

    // Check recording name
    if(filter?.length) {
      const checkName = rec.name.toLowerCase();
      if(checkName.indexOf(filter) >= 0) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  }

  isSectionHeader(index: number, rec: MotionRecordingInfo): boolean {
    return rec.sectionTitle ? rec.sectionTitle.length > 0 : false;
  }

  showDetails(cyclistID: string, recordingID: string, recType: string) {
    // TODO Put generating a URL in a service
    this.router.navigateByUrl(`/cyclist/${cyclistID}/motion-recording/${recordingID}/details-${recType}`);
  }

  showAnalysis(cyclistID: string, recordingID: string) {
    // TODO Put generating a URL in a service
    this.router.navigateByUrl(`/cyclist/${cyclistID}/motion-recording/${recordingID}/cycling-analysis`);
  }

  ///// Utils /////

  isBikeRecording(rec?: MotionRecordingInfo) { return rec?.recordingType == 'bike'; }
  isCyclingRecording(rec?: MotionRecordingInfo) { return rec?.recordingType == 'cycling'; }

  isRoadBike(rec?: MotionRecordingInfo) { return rec?.equipment.bicycleType == 'roadBike' || rec?.equipment.bicycleType == 'racing' || (!this.isTTBike(rec) && !this.isMountainBike(rec)) }
  isTTBike(rec?: MotionRecordingInfo) { return rec?.equipment.bicycleType == 'ttBike'; }
  isMountainBike(rec?: MotionRecordingInfo) { return rec?.equipment.bicycleType == 'mountainBike'; }

  fullRecordingType(rec?: MotionRecordingInfo) {
    if(!rec) {
      return "/";
    }

    if(this.isBikeRecording(rec)) {
      return this.bikeType(rec);
    } else {
      return "Cycling";
    }
  }

  bikeType(rec?: MotionRecordingInfo) {
    if(!rec) {
      return "/";
    }

    if(this.isRoadBike(rec)) {
      return "Road Bike";
    } else if(this.isTTBike(rec)) {
      return "TT Bike";
    } else if(this.isMountainBike(rec)) {
      return "MTB";
    } else {
      return rec.equipment.bicycleType + " Bike";
    }
  }
}
