<brm-card *ngIf="bikeAnalysis" title="Bike Setup">
  <!-- Image dependent on bicycle type -->
  <img id="bikeImage" *ngIf="bikeAnalysis.isRoadBike" src="assets/bike-report/BikeFitResult-XY.svg" alt="Bike" />
  <!-- TODO Generate TT image -->
  <img id="bikeImage" *ngIf="bikeAnalysis.isTTBike" src="assets/bike-report/TT Bike Report - Saddle.svg" alt="Bike" />
  <!-- TODO Generate MTB image -->
  <img id="bikeImage" *ngIf="bikeAnalysis.isMountainBike" src="assets/bike-report/BikeFitResult-XY.svg" alt="Bike" />

  <div class="content">
    <div>
      <p class="xy">Saddle X (mm): </p>
      <p class="xy">Saddle Y (mm): </p>
      <p *ngIf="bikeAnalysis.isTTBike">Saddle front setback (mm): </p>
      <p>Seat height (mm): </p>
      <p>Effective seat angle (°): </p>
      <br />
      <p class="xy">Handlebar X (mm): </p>
      <p class="xy">Handlebar Y (mm): </p>
      <p>Handlebar reach (mm): </p>
      <p>Handlebar drop (mm): </p>
    </div>
    <div class="values">
      <p>{{lengthMM(bikeAnalysis.seatX)}}</p>
      <p>{{lengthMM(bikeAnalysis.seatY)}}</p>
      <p *ngIf="bikeAnalysis.isTTBike">{{lengthMM(bikeAnalysis.saddleFrontSetback)}}</p>
      <p>{{lengthMM(bikeAnalysis.seatHeight)}}</p>
      <p>{{displayAngle(bikeAnalysis.effectiveSeatAngle)}}</p>
      <br />
      <p>{{lengthMM(bikeAnalysis.handlebarX)}}</p>
      <p>{{lengthMM(bikeAnalysis.handlebarY)}}</p>
      <p>{{lengthMM(bikeAnalysis.handlebarReach)}}</p>
      <p>{{lengthMM(bikeAnalysis.handlebarDrop)}}</p>
    </div>
    <mat-divider vertical="true"></mat-divider>
    <div>
      <p>Frame stack (mm): </p>
      <p>Frame reach (mm): </p>
    </div>
    <div class="values">
      <p>{{lengthMM(bikeAnalysis.frameStack)}}</p>
      <p>{{lengthMM(bikeAnalysis.frameReach)}}</p>
    </div>
  </div>
</brm-card>
