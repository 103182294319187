<p *ngIf="(brmCloud.auth.user | async)?.uid">
  <button mat-stroked-button [matMenuTriggerFor]="menu">
    {{ brmCloud.fullName(brmCloud.loggedOnUser$ | async) }}
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="brmCloud.logout()">Log out</button>
  </mat-menu>
</p>

<p *ngIf="!(brmCloud.auth.user | async)?.uid">
  <button mat-stroked-button (click)="brmCloud.openLoginDialog()">Log in</button>
</p>
