import { Component, OnInit, Input } from '@angular/core';
import { BrmCloudService } from '@shared/brm-cloud.service';

@Component({
  selector: 'brm-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  @Input() title : string = "Bioracer Motion"
  @Input() logo : string = "assets/motion-logo-rounded.png";

  constructor(public brmCloud: BrmCloudService) { }

  ngOnInit(): void {
  }

}
