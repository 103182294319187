<div id="root" class="column" *ngIf="recording$ | async as recording">

  <div id="toolbar">
    <motion-view-selection #viewSelection [add3DView]="true" (viewClicked)="handleViewClicked($event)"></motion-view-selection>
    <mat-slide-toggle [(ngModel)]="slowMotion" [ngModelOptions]="{standalone: true}">Slow Motion</mat-slide-toggle>
    <motion-point-selection #pointSelection></motion-point-selection>
  </div>

  <div class="row">
    <brm-motion3d *ngIf="cyclistID && recordingID" [cyclistID]="cyclistID" [recordingID]="recordingID" [slowMotion]="slowMotion"></brm-motion3d>
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ pointSelection.selectedPointID ]" [projectionAxis]="viewSelection.projectionAxis"></brm-point-trace>
  </div>

</div>
