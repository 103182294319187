<brm-card *ngIf="motionAnalysis" title="Knees">
  <brm-point-trace
    class="image"
    [pointAnalysis]="motionAnalysis.pointAnalysis"
    [pointTypes]="[ TrackingPoint.LeftKnee, TrackingPoint.RightKnee ]"
    [projectionAxis]="2"
    [drawMidpoint]="true"
    [drawExtendedLine]="true"
  ></brm-point-trace>

  <div class="content">
    <div class="titles">
      <p>Left flexion angle (°): </p>
      <p>Right flexion angle (°): </p>
      <p>Left lateral movement (mm)</p>
      <p>Right lateral movement (mm)</p>
    </div>
    <div class="values">
      <p>{{leftKneeAngleMetrics?.min?.toFixed() || '/'}} - {{leftKneeAngleMetrics?.max?.toFixed() || '/'}} ({{leftKneeAngleMetrics?.range?.toFixed() || '/'}})</p>
      <p>{{rightKneeAngleMetrics?.min?.toFixed() || '/'}} - {{rightKneeAngleMetrics?.max?.toFixed() || '/'}} ({{rightKneeAngleMetrics?.range?.toFixed() || '/'}})</p>
      <p>{{leftKneePointMetrics ? (leftKneePointMetrics.width * 1000).toFixed() : '/'}}</p>
      <p>{{rightKneePointMetrics ? (rightKneePointMetrics.width * 1000).toFixed() : '/'}}</p>
    </div>
  </div>
</brm-card>
