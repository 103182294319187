import { MotionRecordingInfo } from '../services/motion-recording-info.interface';
import { MotionData, TrackingPoint } from '../services/motion-data';
import { MotionAnalysis } from '../services/motion-analysis';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartConfiguration, ChartOptions, ChartType } from "chart.js";
import annotationPlugin, { AnnotationOptions, LineAnnotationOptions } from 'chartjs-plugin-annotation';

@Component({
  selector: 'brm-cycle-extrema-graph',
  templateUrl: './cycle-extrema-graph.component.html',
  styleUrls: ['./cycle-extrema-graph.component.scss']
})
export class CycleExtremaGraphComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() motionAnalysis: MotionAnalysis | null = null;
  @Input() pointType: number | null = null;

  @Input() beginAtZero: boolean = true;
  @Input() maxY?: number = 30;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  annotationsDefault = {
    value: 0,
    scaleID: 'y',
    borderColor: 'rgb(100, 100, 200)',
    borderWidth: 1,
    label: {
      display: true,
      position: 'start',
      backgroundColor: 'rgba(100,149,237)'
    }
  };
  annotations = {
    min: { ...this.annotationsDefault, label: { ...this.annotationsDefault.label, content: ()=> this.minLabel }, value: ()=> this.minValue },
    max: { ...this.annotationsDefault, label: { ...this.annotationsDefault.label, content: ()=> this.maxLabel }, value: ()=> this.maxValue },
    avg: { ...this.annotationsDefault, label: { ...this.annotationsDefault.label, content: ()=> this.avgLabel }, value: ()=> this.avgValue }
  }

  // In millimeters
  minValue = 0
  maxValue = 0
  avgValue = 0

  get minLabel() { return "min: " + this.minValue.toFixed(0); }
  get maxLabel() { return "max: " + this.maxValue.toFixed(0); }
  get avgLabel() { return "avg: " + this.avgValue.toFixed(0); }

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Cyclic Extrema',
        tension: 0.3,
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)'
      }
    ]
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false,
    scales: {
      y: {
        beginAtZero: this.beginAtZero,
        suggestedMax: this.maxY
      }
    },
    plugins: {
      annotation: {
        annotations: this.annotations
      }
    }
  };
  public lineChartLegend = true;
  public lineChartType = 'line';

  /////

  refreshData() {
    console.log("Refreshing chart data");

    const dataset = this.lineChartData.datasets[0];
    const chartData = this.lineChartData;

    if(this.pointType) {
      // TEMP Hardcoded parameter (width) and unit (mm)
      dataset.label = TrackingPoint.pointName(this.pointType) + " movement width (mm)";
    } else {
      dataset.label = "No point set";
    }
    dataset.data = [];
    chartData.labels = [];

    const cycles = this.motionAnalysis?.pedalCycles;
    if(this.pointType && cycles) {
      const cm = this.motionAnalysis?.calcCyclicExtrema(this.pointType, cycles);
      //console.log(TrackingPoint.pointName(this.pointType) + " cycle extrema for cycles", cycles, cm);
      if(cm) {
        for(let i = 0; i < cm.length; ++i) {
          // TEMP Hardcoded parameter (width) and unit (mm)
          dataset.data.push(cm[i].width * 1000); // m to mm
          chartData.labels.push(i + 1);
        }

        // Calculate metrics over all cycles
        let min = 0.0, max = 0.0, avg = 0.0;
        for(let i = 0; i < cm.length; ++i) {
          const cycleWidth = cm[i].width;
          if(i == 0 || cycleWidth < min) { min = cycleWidth; }
          if(i == 0 || cycleWidth > max) { max = cycleWidth; }
          avg += cycleWidth;
        }
        if(cm.length > 0) {
          avg /= cm.length;
        }

        // Metrics over all pedal cycles
        this.minValue = min * 1000; // m to mm
        this.maxValue = max * 1000; // m to mm
        this.avgValue = avg * 1000; // m to mm
      }
    }

    console.log("New cyclic extrema line chart data:", this.lineChartData);
    this.chart?.update();
  }

  /////

  constructor() {
    Chart.register(annotationPlugin);
  }

  ngOnInit(): void {
    this.refreshData();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.debug("[CycleExtremaGraphComponent] changes: ", changes);

    let needsReload = false;

    if(changes.motionAnalysis.currentValue != changes.motionAnalysis.previousValue) {
      console.debug("[CycleExtremaGraphComponent] Provided motion analysis changed from " + changes.motionAnalysis.previousValue + " to " + changes.motionAnalysis.currentValue);
      needsReload = true;
    }

    if(needsReload) {
      this.refreshData();
    }
  }

  ngAfterViewInit() {
  }

  /////

}
