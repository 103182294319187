import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PointAnalysis } from '../services/point-analysis';
import { TrackingPoint } from '../services/motion-data';

interface AvailablePoint {
  pointID: number;
  name: string
};

@Component({
  selector: 'motion-point-trace-selection',
  templateUrl: './point-trace-selection.component.html',
  styleUrls: ['./point-trace-selection.component.scss']
})
export class PointTraceSelectionComponent implements OnInit {
  @Input() pointAnalysis?: PointAnalysis;

  availablePoints: Array<AvailablePoint> = [];

  get selectedPoints() {
    const p1 = this.settings.pointID1;
    const p2 = this.settings.pointID2;
    if(p1 >= 0 && p2 >= 0) {
      return [ p1, p2 ];
    } else if(p1 >= 0) {
      return [ p1 ];
    } else if(p2 >= 0) {
      return [ p2 ];
    } else {
      return [];
    }
  }

  // Add TrackingPoint class so we can use in our html
  TrackingPoint = TrackingPoint;

  settings = {
    pointID1: -1,
    pointID2: -1,
    projectionAxis: 0,
    drawMidpoint: true
  }

  /////

  constructor() {

  }

  ngOnInit(): void {
    this.availablePoints = this.generateAvailablePoints();
  }

  /////

  private generateAvailablePoints(): Array<AvailablePoint> {
    let result = [];
    for(let point of TrackingPoint.leftBodyPoints) {
      result.push({ pointID: point, name: TrackingPoint.pointName(point) });
    }

    for(let point of TrackingPoint.rightBodyPoints) {
      result.push({ pointID: point, name: TrackingPoint.pointName(point) });
    }

    return result;
  }
}
