<!-- Not for TT bikes -->
<brm-card *ngIf="bikeAnalysis && !bikeAnalysis.isTTBike" title="Bicycle Frame">
  <!-- Image dependent on bicycle type -->
  <img *ngIf="bikeAnalysis.isRoadBike" src="assets/bike-report/Road Bike Report - Frame.svg" alt="Bike" />
  <img *ngIf="bikeAnalysis.isMountainBike" src="assets/bike-report/Mountain Bike Report - Frame.svg" alt="Bike" />

  <div class="content">
    <div class="titles">
      <p>Frame stack (mm): </p>
      <p>Frame reach (mm): </p>
    </div>
    <div class="values">
      <p>{{lengthMM(bikeAnalysis.frameStack)}}</p>
      <p>{{lengthMM(bikeAnalysis.frameReach)}}</p>
    </div>
  </div>
</brm-card>
