<!-- Only for TT bikes -->
<brm-card *ngIf="bikeAnalysis && bikeAnalysis.isTTBike" title="UCI Rules">
  <img id="bikeImage" src="assets/bike-report/TT Bike Report - UCI Rules.svg" alt="Bike" />

  <div fxLayout="column">

    <div class="content">
      <div class="titles">
        <p>Saddle Front Setback (mm): </p>
        <p>Shifters horizontal reach (mm): </p>
        <p>Shifters - Pads Drop (mm): </p>
      </div>
      <div class="values">
        <p [class]="saddleFrontSetbackValidityClass">{{lengthMM(bikeAnalysis.saddleFrontSetback)}}</p>
        <p [class]="shiftersHorizontalReachValidityClass">{{lengthMM(bikeAnalysis.shiftersHorizontalReach)}}</p>
        <p [class]="shiftersPadDropValidityClass">{{lengthMM(bikeAnalysis.shiftersPadsDrop)}}</p>
      </div>
      <div class="rules">
        <p>(min {{lengthMM(minSaddleFrontSetback)}})</p>
        <p>(max {{lengthMM(maxShiftersHorizontalReach)}})</p>
        <p>(max {{lengthMM(maxShiftersPadsDrop)}})</p>
      </div>
    </div>

  </div>

<br />

<div>
  <table>
    <thead>
      <th>Category</th>
      <th>Body Length</th>
      <th>Saddle Front Setback (min)</th>
      <th>Shifters Reach (max)</th>
      <th>Pads Drop (max)</th>
    </thead>
    <tr [ngClass]="category === 1 || !category ? 'active' : ''">
      <td>1</td>
      <td>&lt; 180cm</td>
      <td>{{lengthMM(minSaddleFrontSetback)}}mm</td>
      <td>{{lengthMM(maxShiftersHorizontalReachForCategory(1))}}mm</td>
      <td>{{lengthMM(maxShiftersPadsDropForCategory(1))}}mm</td>
    </tr>
    <tr [ngClass]="category === 2 ? 'active' : ''">
      <td>2</td>
      <td>180-190cm</td>
      <td>{{lengthMM(minSaddleFrontSetback)}}mm</td>
      <td>{{lengthMM(maxShiftersHorizontalReachForCategory(2))}}mm</td>
      <td>{{lengthMM(maxShiftersPadsDropForCategory(2))}}mm</td>
    </tr>
    <tr [ngClass]="category === 3 ? 'active' : ''">
      <td>3</td>
      <td>&gt; 190cm</td>
      <td>{{lengthMM(minSaddleFrontSetback)}}mm</td>
      <td>{{lengthMM(maxShiftersHorizontalReachForCategory(3))}}mm</td>
      <td>{{lengthMM(maxShiftersPadsDropForCategory(3))}}mm</td>
    </tr>
  </table>
</div>

</brm-card>
