<brm-card *ngIf="motionAnalysis" title="Shoulders">
  <div class="column" style="max-width: 33%">
    <h3>Back view</h3>
    <brm-point-trace class="image"
      [pointAnalysis]="motionAnalysis.pointAnalysis"
      [pointTypes]="[ TrackingPoint.LeftShoulder, TrackingPoint.RightShoulder ]"
      [projectionAxis]="2"
      [drawMidpoint]="true"
      [drawExtendedLine]="true"
      [marginRelative]="0.5"
    ></brm-point-trace>

    <h3>Top view</h3>
    <brm-point-trace class="image"
      [pointAnalysis]="motionAnalysis.pointAnalysis"
      [pointTypes]="[ TrackingPoint.LeftShoulder, TrackingPoint.RightShoulder ]"
      [projectionAxis]="1"
      [drawMidpoint]="true"
      [drawExtendedLine]="true"
      [marginRelative]="0.5"
    ></brm-point-trace>
  </div>

  <div class="content">
    <div class="titles">
      <p>Lateral movement (mm): </p>
      <p>Vertical movement (mm): </p>
      <p>Forward-backward movement (mm):</p>
    </div>
    <div class="values">
      <p>{{movementMM.x.toFixed()}}</p>
      <p>{{movementMM.y.toFixed()}}</p>
      <p>{{movementMM.z.toFixed()}}</p>
    </div>
  </div>
</brm-card>
