const littleEndian = true;
const fps = 120;

// Wired markers are thinner, so we need different compensation
export const wiredMarkers: boolean = false;
export const markerThicknessWireless: number = 0.016;
export const markerThicknessWired: number = 0.010;
export const markerThickness: number = wiredMarkers ? markerThicknessWired : markerThicknessWireless;
export const markerRadius: number = 0.015;

/////

export class MotionData {
  loadingMotionData: boolean = false;

  version: number;
  duration: number;
  startTime: number;
  endTime: number = 0;
  recordingName: string;
  recordingTimestamp: number;
  recordingDate: Date;
  comments: string;

  data: RecordingData;

  loadFromData(data: ArrayBufferLike, force: boolean = false) {
    // Already loading?
    if (this.loadingMotionData) {
      console.warn("Cannot load motion data: loading already in progress");
      return;
    }

    this.loadingMotionData = true;
    console.log("Loading motion data");

    console.log("Data size: " + data.byteLength + " bytes");
    //console.log(data);

    let dv = new DataView(data);
    //console.log(dv.byteLength);

    this.parseData(dv);
    //console.log(loadedData);

    // Set duration from number of point frames
    this.duration = this.numPointFrames() / fps;
    // Set end time to duration if not set
    if (this.endTime <= 0) {
      console.log("No end time set. Setting to duration");
      this.endTime = this.duration;
    }

    console.log("Loaded motion recording duration: ", this.duration);

    this.loadingMotionData = false;
  }

  /////

  parseData(dataView: DataView) {
    let dataPos = 0;

    const version = dataView.getUint32(dataPos, littleEndian);
    dataPos += 4;
    console.log("Motion data version " + version);
    this.version = version;

    if(version != 6) {
        console.warn("We only support parsing Motion data files with version 6");
        return false;
    }

    // Recording name
    const recName = readString(dataView, dataPos);
    dataPos += recName.length + 4;
    console.log("Recording name: " + recName);
    this.recordingName = recName;

    //console.log("dataPos after reading string: " + dataPos);

    // Recording time
    const recTimestamp = dataView.getUint32(dataPos, littleEndian);
    dataPos += 4;
    const recTime = new Date(recTimestamp * 1000);
    console.log("Recording time: " + recTime);
    this.recordingTimestamp = recTimestamp;
    this.recordingDate = recTime;

    // Comments
    const comments = readString(dataView, dataPos);
    dataPos += comments.length + 4;
    console.log("Recording comments: " + comments);
    this.comments = comments;

    // Start time
    const startTime = dataView.getFloat32(dataPos, littleEndian);
    dataPos += 4;
    console.log("Start time: " + startTime);
    this.startTime = startTime;

    // End time
    const endTime = dataView.getFloat32(dataPos, littleEndian);
    dataPos += 4;
    console.log("End time: " + endTime);
    this.endTime = endTime;

    //
    // Data
    //

    const numDataTypes = dataView.getUint32(dataPos, littleEndian);
    dataPos += 4;
    console.log("# data types: " + numDataTypes);
    this.data = {};

    for(let i = 0; i < numDataTypes; ++i) {
        const dataType = readString(dataView, dataPos);
        dataPos += dataType.length + 4;
        console.log("-> data type: " + dataType);

        this.data[dataType] = {};

        if(dataType == "Point") {
            [ dataPos, this.data[dataType] ] = parsePointData(dataView, dataPos);
        } else {
            console.warn("Error: unknown data type " + dataType);
            return false;
        }
    }

    return true;
}

  numPointFrames() {
    const frameData = this.data['Point'].frameData;
    return frameData.length;
  }

  numPoints(frameIdx: number) {
    const frameData = this.data['Point'].frameData;
    const frame = frameData[frameIdx];
    return frame.points.length;
  }

}

export interface RecordingData {
  [type: string]: any;
}

export class TrackingPoint
{
    static InvalidPoint = -1;

    static ReferencePoint = 0;
    static FrameFront = 1; static FrameBack = 2;

    static LeftFootFront = 100; static RightFootFront = 101;
    static LeftFootBack = 102; static RightFootBack = 103;
    static LeftAnkle = 104; static RightAnkle = 105;
    static LeftKnee = 106; static RightKnee = 107;
    static LeftHip = 108; static RightHip = 109;

    static LeftPelvis1 = 110; static RightPelvis1 = 111;
    static LeftPelvis2 = 112; static RightPelvis2 = 113;
    static LeftShoulder = 114; static RightShoulder = 115;
    static LeftElbow = 116; static RightElbow = 117;
    static LeftWrist = 118; static RightWrist = 119;

    static LeftPedalAxis = 120; static RightPedalAxis = 121;

    static CrankLeft = 122; static CrankRight = 123;
    static SitPosition = 124;
    // Specific for road bike
    static HandleBar = 125;
    static HeadTube = 126;
    static LeftBrakeLeverHood = 127; static RightBrakeLeverHood = 128;
    // Specific for TT bike
    static LeftElbowPad = 129; static RightElbowPad = 130;
    static LeftShifter = 131; static RightShifter = 132;

    static UnidentifiedPointStart = 1000;

    // Point lists

    static leftBodyPoints = [ this.LeftFootFront, this.LeftFootBack, this.LeftAnkle, this.LeftKnee, this.LeftHip, this.LeftPelvis1, this.LeftPelvis2, this.LeftShoulder, this.LeftElbow, this.LeftWrist ];
    static rightBodyPoints = [ this.RightFootFront, this.RightFootBack, this.RightAnkle, this.RightKnee, this.RightHip, this.RightPelvis1, this.RightPelvis2, this.RightShoulder, this.RightElbow, this.RightWrist ];

    static prevBodyPoint(point: number) {
      const leftIdx = this.leftBodyPoints.indexOf(point);
      if(leftIdx !== undefined && leftIdx >= 0) {
        if(leftIdx == 0 || leftIdx >= this.leftBodyPoints.length) {
          return null;
        } else {
          return this.leftBodyPoints[leftIdx - 1];
        }
      }

      const rightIdx = this.rightBodyPoints.indexOf(point);
      if(rightIdx !== undefined && rightIdx >= 0) {
        if(rightIdx == 0 || rightIdx >= this.rightBodyPoints.length) {
          return null;
        } else {
          return this.rightBodyPoints[rightIdx - 1];
        }
      }

      return null;
    }

    static nextBodyPoint(point: number) {
      const leftIdx = this.leftBodyPoints.indexOf(point);
      if(leftIdx !== undefined && leftIdx >= 0) {
        if(leftIdx >= (this.leftBodyPoints.length - 1)) {
          return null;
        } else {
          return this.leftBodyPoints[leftIdx + 1];
        }
      }

      const rightIdx = this.rightBodyPoints.indexOf(point);
      if(rightIdx !== undefined && rightIdx >= 0) {
        if(rightIdx >= (this.rightBodyPoints.length - 1)) {
          return null;
        } else {
          return this.rightBodyPoints[rightIdx + 1];
        }
      }

      return null;
    }

    static pointName(point: number) {
      switch(point)
      {
        case this.InvalidPoint: return "invalid";

        case this.FrameFront: return "frame front";
        case this.FrameBack: return "frame back";

        case this.LeftFootFront: return "left foot front";
        case this.RightFootFront: return "right foot front";
        case this.LeftFootBack: return "left foot back";
        case this.RightFootBack: return "right foot back";
        case this.LeftAnkle: return "left ankle";
        case this.RightAnkle: return "right ankle";
        case this.LeftKnee: return "left knee";
        case this.RightKnee: return "right knee";
        case this.LeftHip: return "left hip";
        case this.RightHip: return "right hip";

        case this.LeftPelvis1: return "left pelvis 1";
        case this.RightPelvis1: return "right pelvis 1";
        case this.LeftPelvis2: return "left pelvis 2";
        case this.RightPelvis2: return "right pelvis 2";
        case this.LeftShoulder: return "left shoulder";
        case this.RightShoulder: return "right shoulder";
        case this.LeftElbow: return "left elbow";
        case this.RightElbow: return "right elbow";
        case this.LeftWrist: return "left wrist";
        case this.RightWrist: return "right wrist";

        case this.LeftPedalAxis: return "left pedal axis";
        case this.RightPedalAxis: return "right pedal axis";

        case this.CrankLeft: return "left crank axle";
        case this.CrankRight: return "right crank axle";
        case this.SitPosition: return "sit position";

        // Specific for road bike
        case this.HandleBar: return "handle bar";
        case this.HeadTube: return "head tube";
        case this.LeftBrakeLeverHood: return "left brake lever hood";
        case this.RightBrakeLeverHood: return "right brake lever hood";

        // Specific for TT bike
        case this.LeftElbowPad: return "left elbow pad";
        case this.RightElbowPad: return "right elbow pad";
        case this.LeftShifter: return "left shifter";
        case this.RightShifter: return "right shifter";

        default:
          return "point " + point;
      }
    }
}

// TODO All parse functions should return an array of results,
// the first sould be the new dataPos (data offset)

export interface Vector3 {
  x: number;
  y: number;
  z: number;
}

export interface Quaternion {
  scalar: number;
  x: number;
  y: number;
  z: number;
}
export interface PointFitting {
  pointTypeToID: { [type: number]: number };
  pointIDToType: { [id: number]: number };
  numPoints: number;
}
export interface RecordingPointData {
  staticTranslation: Vector3;
  staticRotation: Quaternion;

  pedalOffsetToFootFrontLeft: number;
  pedalAngleToFootLeft: number;
  pedalOffsetToFootFrontRight: number;
  pedalAngleToFootRight: number;
  transformMode: number;
  wantedNumPoints: number;
  pointFitting: PointFitting;
  frameData: RecordingPointFrameData[];
}

export interface RecordingPointFrameData {
  frameTime: number;
  points: {
    pointID: number;
    timestamp: number;
    position: Vector3;
  }[];
}

export interface Vector2 {
  x: number;
  y: number;
}


function parsePointData(dataView: DataView, offset: number): [number, RecordingPointData] {
  let result: RecordingPointData = {
    staticTranslation: {x: 0, y: 0, z: 0},
    staticRotation: {scalar: 1, x: 0, y: 0, z: 0},
    pedalOffsetToFootFrontLeft: 0,
    pedalAngleToFootLeft: 0,
    pedalOffsetToFootFrontRight: 0,
    pedalAngleToFootRight: 0,
    transformMode: 0,
    wantedNumPoints: 0,
    pointFitting: { numPoints: 0, pointTypeToID: {}, pointIDToType: {} },
    frameData: []
  };
  let dataPos = offset;

  const version = dataView.getUint32(dataPos, littleEndian);
  dataPos += 4;
  console.log("Point data version " + version);

  if(version != 6) {
      console.warn("We only support parsing Point data with version 6");
      return [ dataPos, result ];
  }

  [dataPos, result.staticTranslation] = readVector3(dataView, dataPos);
  //dataPos += 12;

  [dataPos, result.staticRotation] = readQuaternion(dataView, dataPos);
  //dataPos += 16;

  result.pedalOffsetToFootFrontLeft = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;
  console.log("pedalOffsetToFootFrontLeft = " + result.pedalOffsetToFootFrontLeft);

  result.pedalAngleToFootLeft = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;
  console.log("pedalAngleToFootLeft = " + result.pedalAngleToFootLeft);

  result.pedalOffsetToFootFrontRight = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;
  console.log("pedalOffsetToFootFrontRight = " + result.pedalOffsetToFootFrontRight);

  result.pedalAngleToFootRight = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;
  console.log("pedalAngleToFootRight = " + result.pedalAngleToFootRight);

  result.transformMode = dataView.getUint32(dataPos, littleEndian);
  dataPos += 4;
  console.log("transformMode = " + result.transformMode);

  result.wantedNumPoints = dataView.getUint32(dataPos, littleEndian);
  dataPos += 4;
  console.log("wantedNumPoints = " + result.wantedNumPoints);

  // Point type - ID mapping
  [dataPos, result.pointFitting] = parsePointFitting(dataView, dataPos);

  const numFrames = dataView.getUint32(dataPos, littleEndian);
  dataPos += 4;
  console.log("# frames: " + numFrames);
  result.frameData = [];

  for(let i = 0; i < numFrames; ++i) {
      const frameTime = dataView.getFloat32(dataPos, littleEndian);
      dataPos += 4;
      //console.log("-> frame time: " + frameTime + " s");

      const numFramePoints = dataView.getUint32(dataPos, littleEndian);
      dataPos += 4;
      //console.log("-> # points: " + numFramePoints);

      const points = [];

      for(let j = 0; j < numFramePoints; ++j) {
          const pointID = dataView.getUint32(dataPos, littleEndian);
          dataPos += 4;
          //console.log("  -> point ID: " + pointID);

          const timestamp = dataView.getFloat32(dataPos, littleEndian);
          dataPos += 4;
          //console.log("  -> timestamp: " + timestamp + " s");

          let position;
          [dataPos, position] = readVector3(dataView, dataPos);
          //dataPos += 12;

          points.push({ pointID: pointID, timestamp: timestamp, position: position });
      }

      result.frameData.push({ frameTime: frameTime, points: points });
  }

  return [ dataPos, result ];
}

function parsePointFitting(dataView: DataView, offset: number): [number, PointFitting] {
  let dataPos = offset;
  const result: PointFitting = { pointTypeToID: {}, pointIDToType: {}, numPoints: 0 };

  result.numPoints = dataView.getUint32(dataPos, littleEndian);
  dataPos += 4;
  console.log("# point fitting points: " + result.numPoints);

  for(let i = 0; i < result.numPoints; ++i) {
      const pointType = dataView.getInt32(dataPos, littleEndian);
      dataPos += 4;

      const pointID = dataView.getInt32(dataPos, littleEndian);
      dataPos += 4;

      console.log("-> point type: " + pointType + " is ID " + pointID);
      result.pointTypeToID[pointType] = pointID;
      result.pointIDToType[pointID] = pointType;
  }

  return [dataPos, result];
}

/////

// Returns new offset and the parsed string
function readString(dataView: DataView, offset: number) {
  let dataPos = offset;

  const length = dataView.getUint32(dataPos, littleEndian);
  dataPos += 4;
  //console.log("Reading string of length " + length);

  let result = "";
  for(let i = 0; i < length; ++i) {
      const b = dataView.getUint8(dataPos++);
      result += String.fromCharCode(b);
  }

  //console.log("New data pos should be " + dataPos);
  return result;
}

// Returns new offset and the parsed vector3
function readVector3(dataView: DataView, offset: number): [number, Vector3] {
  let dataPos = offset;

  const x = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;

  const y = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;

  const z = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;

  //console.log("(" + x + ", " + y + ", " + z + ")");
  const pos = {x: x, y: y, z: z};
  return [dataPos, pos];
}

// Returns new offset and the parsed quaternion
function readQuaternion(dataView: DataView, offset: number): [number, Quaternion] {
  let dataPos = offset;

  const w = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;

  const x = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;

  const y = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;

  const z = dataView.getFloat32(dataPos, littleEndian);
  dataPos += 4;

  //console.log("(" + w + "; " + x + ", " + y + ", " + z + ")");
  const quat = {scalar: w, x: x, y: y, z: z};
  return [dataPos, quat];
}
