import { Component, Input, OnInit } from '@angular/core';
import { BikeAnalysis } from '../services/bike-analysis';
import { MotionRecordingInfo } from '../services/motion-recording-info.interface';

@Component({
  selector: 'motion-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit {

  @Input() recording: MotionRecordingInfo | null = null;
  @Input() bikeAnalysis: BikeAnalysis | null = null;

  // Display a length value in millimeters
  public lengthMM(value?: number) {
    if(value) {
      return (value * 1000).toFixed();
    } else {
      return "/";
    }
  }

  public displayAngle(angle?: number) {
    if(angle) {
      return (angle).toFixed(1);
    } else {
      return "/";
    }
  }

  public brandModel(brand? : string, model? : string) {
    if(brand && model) {
      return brand + " / " + model;
    }

    return brand || model || "?";
  }

  /////

  constructor() {

  }

  ngOnInit(): void {
  }
}
