import { TrackingPoint, Vector2, Vector3 } from '../services/motion-data';
import { PointAnalysis, PointMetrics } from '../services/point-analysis';
import { AngleMetrics, MotionAnalysis } from '../services/motion-analysis';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'brm-cycling-ankle-info',
  templateUrl: './cycling-ankle-info.component.html',
  styleUrls: ['./cycling-ankle-info.component.scss']
})
export class CyclingAnkleInfoComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() motionAnalysis: MotionAnalysis | null = null;

  leftAnklePointMetrics: PointMetrics | null = null;
  rightAnklePointMetrics: PointMetrics | null = null;

  leftAnkleAngleMetrics: AngleMetrics | null = null;
  rightAnkleAngleMetrics: AngleMetrics | null = null;

  // Add TrackingPoint class so we can use in our html
  TrackingPoint = TrackingPoint;

  /////

  constructor() {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.debug("[CyclingAnkleInfoComponent] changes: ", changes);

    let needsReload = false;

    if(changes.motionAnalysis) {
      needsReload = true;
    }

    if(needsReload) {
      this.refreshData();
    }
  }

  /////

  refreshData() {
    console.log("Refreshing ankle info data");

    if(this.motionAnalysis) {
      this.leftAnklePointMetrics = this.motionAnalysis.pointAnalysis.metrics.pointMetrics[TrackingPoint.LeftAnkle];
      this.rightAnklePointMetrics = this.motionAnalysis.pointAnalysis.metrics.pointMetrics[TrackingPoint.RightAnkle];

      // Note: angles from side view
      this.leftAnkleAngleMetrics = this.motionAnalysis.bodyAngleMetrics[TrackingPoint.LeftAnkle].x;
      this.rightAnkleAngleMetrics = this.motionAnalysis.bodyAngleMetrics[TrackingPoint.RightAnkle].x;
    } else {
      this.leftAnklePointMetrics = null;
      this.rightAnklePointMetrics = null;

      this.leftAnkleAngleMetrics = null;
      this.rightAnkleAngleMetrics = null;
    }
  }

}
