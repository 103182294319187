import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, map } from 'rxjs';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { MotionDataService } from '../services/motion-data.service';
import { MotionData } from '../services/motion-data';
import { BikeAnalysis } from '../services/bike-analysis';
import { BikeSetupComponent } from '../bike-setup/bike-setup.component';
import { MotionRecordingInfo } from '../services/motion-recording-info.interface';

@Component({
  selector: 'motion-recording-details-bike',
  templateUrl: './recording-details-bike.component.html',
  styleUrls: ['./recording-details-bike.component.scss']
})
export class RecordingDetailsBikeComponent implements OnInit {

  cyclistID: string|null = null;
  recordingID: string|null = null;

  recording$: Observable<MotionRecordingInfo | undefined>;

  recordingData$: Observable<MotionData>;
  recordingData: MotionData|null = null;

  bikeAnalysis: BikeAnalysis|null = null;

  // Display a length value in millimeters
  public lengthMM(value?: number) {
    if(value) {
      return (value * 1000).toFixed() + " mm";
    } else {
      return "/";
    }
  }

  /////

  constructor(private route: ActivatedRoute, public readonly brmCloud: BrmCloudService, private readonly motionData: MotionDataService, private firestore: AngularFirestore, private storage: AngularFireStorage) {

  }

  ngOnInit(): void {
    this.recording$ = this.route.paramMap.pipe(
      switchMap(params => {
        console.log("Params", params)
        this.cyclistID = params.get('cyclistID');
        this.recordingID = params.get('recordingID');
        return this.motionData.getRecordingInfo(this.cyclistID, this.recordingID);
      })
    );

    // TEMP TEST
    this.recording$.subscribe(rec => {
      console.log("subscribed recording next:", rec);

      if(rec && this.cyclistID) {
        this.recordingData$ = this.motionData.getData(this.cyclistID, rec.recordingID);
        this.recordingData$.subscribe(data => {
          this.recordingData = data;

          console.log("Cyclist ID: " + this.cyclistID);
          console.log("Motion data: ", this.motionData);
          console.log("Recording data: ", this.recordingData);

          console.log("Recording data loaded. Recording duration: " + this.recordingData.duration + "s");

          // Do bike analysis
          this.bikeAnalysis = new BikeAnalysis(rec, this.recordingData);
        });
      }
    })
  }
}
