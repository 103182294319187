import { Vector2, Vector3 } from '../services/motion-data';
import { PointAnalysis, PointMetrics } from '../services/point-analysis';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'brm-point-trace',
  templateUrl: './point-trace.component.html',
  styleUrls: ['./point-trace.component.scss']
})
export class MotionPointTraceComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() pointAnalysis?: PointAnalysis;
  @Input() pointTypes: Array<number>;

  // The 2D projection axis to use
  @Input() projectionAxis: number = 0; // 0=X, 1=Y, 2=Z
  // Draw the trace of the midpoint of the given points
  @Input() drawMidpoint: boolean = false;
  // Draw an extended line through the average position of two points
  // Only possible when using two points
  @Input() drawExtendedLine: boolean = false;

  // TODO Better margin settings
  @Input() marginRelative: number = 0.05;

  @ViewChild('canvas') private canvasRef: ElementRef;
  private get canvas(): HTMLCanvasElement {
    return this.canvasRef?.nativeElement;
  }

  get aspectRatio() {
    if(!this.canvas) {
      return 1;
    }

    return this.canvas.clientWidth / this.canvas.clientHeight;
  }

  paths: Array<Path2D>;
  midpointPath: Path2D | undefined;
  extendedLinePath: Path2D | undefined;

  constructor() {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.createScene();
    this.startRenderingLoop();

    //this.canvas.addEventListener("resize", this.createScene);
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.debug("[MotionPointTraceComponent] changes: ", changes);

    let needsReload = false;

    if(changes.pointAnalysis?.currentValue !== changes.pointAnalysis?.previousValue) {
      console.debug("[MotionPointTraceComponent] Provided point analysis changed from " + changes.pointAnalysis?.previousValue + " to " + changes.pointAnalysis?.currentValue);
      needsReload = true;
    }

    if(!this.compareArrays(changes.pointTypes?.currentValue, changes.pointTypes?.previousValue)) {
      console.debug("[MotionPointTraceComponent] Provided point types changed from " + changes.pointTypes?.previousValue + " to " + changes.pointTypes?.currentValue);
      needsReload = true;
    }

    if(changes.projectionAxis?.currentValue !== changes.projectionAxis?.previousValue) {
      console.debug("[MotionPointTraceComponent] Provided projection axis changed from " + changes.projectionAxis?.previousValue + " to " + changes.projectionAxis?.currentValue);
      needsReload = true;
    }

    if(changes.drawMidpoint?.currentValue !== changes.drawMidpoint?.previousValue) {
      console.debug("[MotionPointTraceComponent] Provided draw midpoint changed from " + changes.drawMidpoint?.previousValue + " to " + changes.drawMidpoint?.currentValue);
      needsReload = true;
    }

    if(changes.drawExtendedLine?.currentValue !== changes.drawExtendedLine?.previousValue) {
      console.debug("[MotionPointTraceComponent] Provided draw extended line changed from " + changes.drawExtendedLine?.previousValue + " to " + changes.drawExtendedLine?.currentValue);
      needsReload = true;
    }

    if(changes.marginRelative?.currentValue !== changes.marginRelative?.previousValue) {
      console.debug("[MotionPointTraceComponent] Provided margin relative changed from " + changes.marginRelative?.previousValue + " to " + changes.marginRelative?.currentValue);
      needsReload = true;
    }

    if(needsReload) {
      this.createScene();
    }
  }

  /////

  onResize() {
    //this.setCanvasSize();
  }

  private setCanvasSize() {
    if(!this.canvas) {
      return;
    }

    const canvasWidth = this.canvas.width;
    const canvasHeight = this.canvas.height;
    console.log("Current canvas size:", canvasWidth, canvasHeight);

    const computedStyles = getComputedStyle(this.canvas);
    const computedWidth = computedStyles.width;
    const computedHeight = computedStyles.height;
    console.log("Computed size:", computedWidth, computedHeight);

    // Resize canvas to match element
    const dpr = window.devicePixelRatio || 1;
    this.canvas.width = Number.parseInt(computedWidth) * dpr;
    this.canvas.height = Number.parseInt(computedHeight) * dpr;

    console.log("Current canvas size:", this.canvas.width, this.canvas.height);
  }

  private createScene() {
    if(!this.canvas) {
      console.warn("Cannot create scene: no canvas element");
      return;
    }

    // Resize canvas to match parent
    //this.setCanvasSize();

    this.paths = [];
    this.midpointPath = undefined;
    this.extendedLinePath = undefined;

    console.debug("Creating point trace for points", this.pointTypes);

    if(!this.pointAnalysis) {
      console.warn("Cannot generate point trace: no point analysis set");
      return;
    }

    // Same transform for all points
    const transform = this.calcTransform(this.pointTypes);

    for(let pointType of this.pointTypes) {
      const path = this.generatePath(pointType, transform.scale.x, transform.scale.y, transform.offset.x, transform.offset.y);
      if(path) {
        this.paths.push(path);
      }
    }

    if(this.pointTypes.length > 1 && this.drawMidpoint) {
      this.midpointPath = this.generateMidpointPath(this.pointTypes, transform.scale.x, transform.scale.y, transform.offset.x, transform.offset.y);
    }

    // Extended line is only supported for exact two points
    if(this.pointTypes.length == 2 && this.drawExtendedLine) {
      this.extendedLinePath = this.generateExtendedLinePath(this.pointTypes, transform.scale.x, transform.scale.y, transform.offset.x, transform.offset.y);
    }
  }

  calcBounds(pointTypes: Array<number>) {
    const bounds = {
      min: {x: 0, y: 0},
      max: {x: 0, y: 0}
    };

    let first = true;
    for(let pointType of this.pointTypes) {
      const metrics = this.pointAnalysis?.metrics.pointMetrics[pointType];
      if(!metrics) {
        console.warn(`No metrics found for point ${pointType}`);
        continue;
      }

      const min = this.projectedPos(metrics.boundsMin);
      const max = this.projectedPos(metrics.boundsMax);
      if(!min || !max) {
        console.warn(`No projected bounds for point ${pointType}`);
        continue;
      }

      // Normalize bounds
      if(min.x > max.x) {
        const temp = min.x;
        min.x = max.x;
        max.x = temp;
      }
      if(min.y > max.y) {
        const temp = min.y;
        min.y = max.y;
        max.y = temp;
      }

      if(first) {
        bounds.min = min;
        bounds.max = max;
      } else {
        bounds.min.x = Math.min(bounds.min.x, min.x);
        bounds.min.y = Math.min(bounds.min.y, min.y);
        bounds.max.x = Math.max(bounds.max.x, max.x);
        bounds.max.y = Math.max(bounds.max.y, max.y);
      }

      first = false;
    }

    return bounds;
  }

  calcTransform(pointTypes: Array<number>) {
    const transform = {
      scale: {x: 1, y: 1},
      offset: {x: 0, y: 0}
    };

    // We need an active canvas
    if(!this.canvas) {
      return transform;
    }

    const bounds = this.calcBounds(pointTypes);
    const min = bounds.min;
    const max = bounds.max;
    const left = min.x;
    const right = max.x;
    const bottom = min.y;
    const top = max.y;

    const width = right - left;
    const height = top - bottom;
    const aspectTrace = width / height;
    const aspectCanvas = this.aspectRatio;

    const multX = this.canvas.width / width;
    const multY = this.canvas.height / height;
    const useX = aspectTrace > aspectCanvas;
    transform.scale.x = useX ? multX : multY / aspectCanvas;
    transform.scale.y = useX ? multX / aspectCanvas : multY;
    //transform.scale = aspectTrace > aspectCanvas ? multX : multY;
    //transform.scale = Math.min(multX, multY);

    // TODO Better margin settings
    transform.scale.x *= (1 - this.marginRelative);
    transform.scale.y *= (1 - this.marginRelative);

    // console.log(bounds);
    // console.log("Canvas size:", this.canvas.width, this.canvas.height);
    // console.log("multX:", multX);
    // console.log("multY:", multY);
    // console.log("scale:", transform.scale);

    // We center the trace in the canvas
    transform.offset.x = this.canvas.width / 2 - transform.scale.x * (min.x + max.x) / 2;
    transform.offset.y = this.canvas.height / 2 - transform.scale.y * (min.y + max.y) / 2;
    // console.log("offset:", transform.offset);

    return transform;
  }

  generatePath(pointType : number, multX: number, multY: number, offsetX: number, offsetY: number) {
    let path = new Path2D();

    let first = true;
    this.pointAnalysis?.iterateAllTransformedPositions(pointType, (frameIdx: number, pos: Vector3)=> {
      const projPos = this.projectedPos(pos) || {x: 0, y: 0};
      const x = multX * projPos.x + offsetX;
      const y = this.canvas.height - (multY * projPos.y + offsetY); // Y-up to y-down

      if(first) {
        path.moveTo(x, y);
      } else {
        path.lineTo(x, y);
      }

      first = false;

      // Continue with next point
      return true;
    });

    return path;
  }

  generateMidpointPath(pointTypes : Array<number>, multX: number, multY: number, offsetX: number, offsetY: number) {
    const positions = [];

    const startFrameIdx = 0;
    const endFrameIdx = this.pointAnalysis?.endFrameIndex;
    if(!this.pointAnalysis || endFrameIdx == undefined) {
      return undefined;
    }

    for(let frameIdx = startFrameIdx; frameIdx < endFrameIdx; ++frameIdx) {
      const midpoint = this.pointAnalysis.calcTransformedMidpoint(pointTypes, frameIdx);
      const projPos = midpoint ? this.projectedPos(midpoint) : undefined;
      positions.push(projPos);
    }

    // Generate the path to draw
    let path = new Path2D();

    let lastValidFrameIdx = -1;
    let first = true;

    for(let frameIdx = 0; frameIdx < positions.length; ++frameIdx) {
      const pos = positions[frameIdx];
      if(!pos) {
        continue;
      }

      // TODO Don't draw line if too old
      lastValidFrameIdx = frameIdx;
      const drawLine = !first;

      const projPos = pos;
      const x = multX * projPos.x + offsetX;
      const y = this.canvas.height - (multY * projPos.y + offsetY); // Y-up to y-down

      if(drawLine) {
        path.lineTo(x, y);
      } else {
        path.moveTo(x, y);
      }

      first = false;
    };

    return path;
  }

  generateExtendedLinePath(pointTypes : Array<number>, multX: number, multY: number, offsetX: number, offsetY: number) {
    const positions = [];

    const startFrameIdx = 0;
    const endFrameIdx = this.pointAnalysis?.endFrameIndex;
    if(!this.pointAnalysis || endFrameIdx == undefined) {
      return undefined;
    }

    if(pointTypes.length != 2) {
      console.warn("Cannot generate extended line path: only two points are supported");
      return undefined;
    }

    const avgPos1 = this.pointAnalysis.metrics.pointMetrics[pointTypes[0]].avg;
    const avgPos2 = this.pointAnalysis.metrics.pointMetrics[pointTypes[1]].avg;

    const avgPos1Proj = this.projectedPos(avgPos1);
    const avgPos2Proj = this.projectedPos(avgPos2);

    if(!avgPos1Proj || !avgPos2Proj) {
      console.warn("Cannot generate extended line path: no projected average positions");
      return undefined;
    }

    // Generate the path to draw
    let path = new Path2D();

    const x1 = multX * avgPos1Proj.x + offsetX;
    const y1 = this.canvas.height - (multY * avgPos1Proj.y + offsetY); // Y-up to y-down

    const x2 = multX * avgPos2Proj.x + offsetX;
    const y2 = this.canvas.height - (multY * avgPos2Proj.y + offsetY); // Y-up to y-down

    // Extend line to edge of canvas
    const w = this.canvas.width;
    const h = this.canvas.height;

    // Calculate line equation
    const dx = x2 - x1;
    const dy = y2 - y1;
    const slope = dy / dx;
    const yIntercept = y1 - slope * x1;

    // Calculate intersection with canvas edges
    let x1Canvas = 0;
    let y1Canvas = 0;
    let x2Canvas = 0;
    let y2Canvas = 0;

    if(dx != 0) {
      // Intersection with left side
      x1Canvas = 0;
      y1Canvas = slope * x1Canvas + yIntercept;

      // Intersection with right side
      x2Canvas = w;
      y2Canvas = slope * x2Canvas + yIntercept;
    } else {
      // Vertical line
      x1Canvas = x1;
      x2Canvas = x1;
      y1Canvas = 0;
      y2Canvas = h;
    }

    // Draw line
    path.moveTo(x1Canvas, y1Canvas);
    path.lineTo(x2Canvas, y2Canvas);

    return path;
  }

  // TODO When not animating: only render once when the data becomes available
  private startRenderingLoop() {
    const t = this;
    function render(time: DOMHighResTimeStamp) {
      t.renderScene(time);

      requestAnimationFrame(render);
    };
    requestAnimationFrame(render);
  }

  private _prevWidth = 0;
  private _prevHeight = 0;
  private renderScene(time: DOMHighResTimeStamp) {
    this.tick(time);

    const ctx = this.canvas.getContext('2d');
    if(!ctx) {
      return;
    }

    // We need anti-aliasing
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";

    if(this._prevWidth != this.canvas.width || this._prevHeight != this.canvas.height) {
      this.createScene();
      this._prevWidth = this.canvas.width;
      this._prevHeight = this.canvas.height;
    }

    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Default line width
    ctx.lineWidth = 1;

    // Point paths
    let first = true;
    for(let path of this.paths) {
      ctx.strokeStyle = first ? "black" : "blue";
      ctx.stroke(path);

      first = false;
    }

    // Midpoint path
    if(this.drawMidpoint && this.midpointPath) {
      ctx.strokeStyle = "green";
      ctx.stroke(this.midpointPath);
    }

    // Extended line path
    if(this.drawExtendedLine && this.extendedLinePath) {
      ctx.strokeStyle = "orange";
      ctx.stroke(this.extendedLinePath);
    }

    // TEMP TEST
    /*const m = 10;
    const w = this.canvas.width - 2 * m;
    const h = this.canvas.height - 2 * m;
    ctx.beginPath();
    ctx.moveTo(m,m);
    ctx.lineTo(w, h);
    ctx.closePath();
    ctx.stroke();*/
  }

  private tick(time: DOMHighResTimeStamp) {
    const timeS = time * 0.001; // ms to s
  }

  ///// Utilities /////

  private projectedPos(pos : Vector3) : Vector2 | undefined {
    if(this.projectionAxis === 0) { // Right side view
      return { x: pos.z, y: pos.y };
    } else if(this.projectionAxis === 1) { // Note: bottom view, not top (along Y)
      //return { x: pos.x, y: pos.z };
      return { x: pos.x, y: -pos.z };
    } else if(this.projectionAxis === 2) { // Note: front view, not back (along Z)
      //return { x: -pos.x, y: pos.y };
      return { x: pos.x, y: pos.y };
    }

    return undefined;
  }

  private compareArrays(a: Array<number>, b: Array<number>) {
    if(a == undefined && b == undefined) {
      return true;
    }
    if(a == undefined || b == undefined) {
      return false;
    }

    return a.length === b.length &&
    a.every((element, index) => element === b[index]);
  }

}
