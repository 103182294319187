<brm-card *ngIf="recording && recording.bodyMeasurements" title="Body Measurements">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <!--<div class="content" fxLayout="column" fxLayoutGap="1em">-->
      <div class="measurement" *ngIf="recording.bodyMeasurements.inseamLength" fxLayout="row">
        <img src="assets/inseam_man.png" style="height: 10em" />
        <span>Inseam length:<br />{{recording.bodyMeasurements.inseamLength}} mm</span>
      </div>

      <div class="measurement" *ngIf="recording.bodyMeasurements.shoulderWidth" fxLayout="row">
        <img src="assets/shoulder_width.png" style="height: 10em" />
        <span>Shoulder width:<br />{{recording.bodyMeasurements.shoulderWidth}} mm</span>
      </div>
    <!--</div>
    <mat-divider vertical="true"></mat-divider>
    <div class="content" fxLayout="column" fxLayoutGap="1em">-->
      <div class="measurement" *ngIf="recording.bodyMeasurements.armLengthLeft" fxLayout="row">
        <img src="assets/armlength_man.png" style="height: 10em" />
        <span>Arm length:<br />{{recording.bodyMeasurements.armLengthLeft}} mm</span>
      </div>

      <div class="measurement" *ngIf="recording.bodyMeasurements.torsoLength" fxLayout="row">
        <img src="assets/torso_height_man.png" style="height: 10em" />
        <span>Torso height:<br />{{recording.bodyMeasurements.torsoLength}} mm</span>
      </div>
    <!--</div>-->
  </div>

  <div *ngIf="!hasData()">
    <i>No body measurements provided.</i>
  </div>
</brm-card>
