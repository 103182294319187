<!-- Page Header-->
<brm-header [title]="title" class="no-print">
  <button
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="drawer.toggle()"
    *ngIf="isHandset$ | async">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
</brm-header>

<mat-sidenav-container class="sidenav-container">

  <!-- Navigation Menu-->
  <!-- For small screens, the sidenav is collabsable -->
  <mat-sidenav #drawer class="sidenav" fixedInViewport="false" fixedTopGap=""
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'push' : 'side'"
      [opened]="(isHandset$ | async) === false"
      class="no-print">
    <mat-nav-list>
      <!-- Navigation Items -->
      <ng-container *ngFor="let module of navItems">
        <a mat-list-item
          *ngIf="shouldShow(module)"
          [disabled]="shouldDisable(module)"
          href="#" [routerLink]="module.route.path"
          routerLinkActive="active"
          (click)="drawer.mode === 'side' || drawer.close()">
            {{ module.route.title }}
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content fxLayout="column">
    <!-- Child content -->
    <ng-content select="[content-header]"></ng-content>

    <!-- Breadcrumb menu -->
    <!--<mat-breadcrumb symbol=" > " class="no-print"></mat-breadcrumb>-->

    <!-- Page Content -->
    <div class="content" role="main" fxFlex>

      <!-- Content from router -->
      <router-outlet></router-outlet>

      <!-- Child content -->
      <ng-content select="[content-footer]"></ng-content>

    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
