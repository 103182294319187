<brm-card *ngIf="recording">
  <div fxLayout="row" fxLayoutGap="1em">
    <img id="logo" src="assets/BRM-square-rounded.svg" alt="Bioracer Motion" />
    <div>
      <h1 *ngIf="isBikeRecording && isRoadBike">Road Bike Report</h1>
      <h1 *ngIf="isBikeRecording && isTTBike">Time Trial Bike Report</h1>
      <h1 *ngIf="isBikeRecording && isMountainBike">Mountain Bike Report</h1>
      <h1 *ngIf="!isBikeRecording">Cycling Report</h1>

      <div fxLayout="row" fxLayoutGap="1em">
        <div class="titles">
          <p>Cyclist</p>
          <p>Operator</p>
          <p>Recording</p>
          <p>Date</p>
        </div>
        <div class="values">
          <p>{{brmCloud.fullName(cyclistInfo) || '?'}}</p>
          <p>{{brmCloud.fullName(operatorInfo) || '?'}}</p>
          <p>{{recording.name || '?'}}</p>
          <p>{{recording.recordingDate | date:'fullDate'}}</p>
        </div>
      </div>
    </div>
  </div>
</brm-card>
