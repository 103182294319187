import { TrackingPoint, Vector2, Vector3 } from '../services/motion-data';
import { PointAnalysis, PointMetrics } from '../services/point-analysis';
import { AngleMetrics, MotionAnalysis } from '../services/motion-analysis';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'brm-cycling-pelvis-info',
  templateUrl: './cycling-pelvis-info.component.html',
  styleUrls: ['./cycling-pelvis-info.component.scss']
})
export class CyclingPelvisInfoComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() motionAnalysis: MotionAnalysis | null = null;

  leftPelvisPointMetrics: PointMetrics | null = null;
  rightPelvisPointMetrics: PointMetrics | null = null;

  // Instability in millimeters
  instabilityMM: Vector3 = { x: 0, y: 0, z: 0 };

  // Add TrackingPoint class so we can use in our html
  TrackingPoint = TrackingPoint;

  /////

  constructor() {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.debug("[CyclingPelvisInfoComponent] changes: ", changes);

    let needsReload = false;

    if(changes.motionAnalysis) {
      needsReload = true;
    }

    if(needsReload) {
      this.refreshData();
    }
  }

  /////

  refreshData() {
    console.log("Refreshing pelvis info data");

    if(this.motionAnalysis) {
      this.leftPelvisPointMetrics = this.motionAnalysis.pointAnalysis.metrics.pointMetrics[TrackingPoint.LeftPelvis1];
      this.rightPelvisPointMetrics = this.motionAnalysis.pointAnalysis.metrics.pointMetrics[TrackingPoint.RightPelvis1];

      // We use the average movement as instability
      this.instabilityMM.x = 1000.0 * (this.leftPelvisPointMetrics.width + this.rightPelvisPointMetrics.width) / 2;
      this.instabilityMM.y = 1000.0 * (this.leftPelvisPointMetrics.height + this.rightPelvisPointMetrics.height) / 2;
      this.instabilityMM.z = 1000.0 * (this.leftPelvisPointMetrics.depth + this.rightPelvisPointMetrics.depth) / 2;
    } else {
      this.leftPelvisPointMetrics = null;
      this.rightPelvisPointMetrics = null;

      this.instabilityMM = { x: 0, y: 0, z: 0 };
    }
  }

}
