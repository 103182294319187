<div *ngIf="recording$ | async as recording" fxLayout="column" fxLayoutGap="1em">

  <motion-recording-report-header [recording]="recording" fxFlexFill></motion-recording-report-header>

  <!-- Recording Data-->
  <brm-card *ngIf="recordingData" title="Recording Data">
    <div fxLayout="row" fxLayout.lt-md="column">
      <div>
        <p *ngIf="recordingData.loadingMotionData">Loading data...</p>

        <p>Version: {{recordingData.version}}</p>
        <p>Duration: {{recordingData.duration}}</p>
        <p>Start time: {{recordingData.startTime}}</p>
        <p>End time: {{recordingData.endTime}}</p>
        <p>Recording name: {{recordingData.recordingName}}</p>
        <p>Recording timestamp: {{recordingData.recordingTimestamp}}</p>
        <p>Recording date: {{recordingData.recordingDate}}</p>
        <p>Comments: {{recordingData.comments}}</p>

        <h2>Data Types</h2>

        <div *ngFor="let dataType of recordingData.data | keyvalue">
          <h3>{{dataType.key}}</h3>
          Wanted # points: {{ dataType.value.wantedNumPoints}}
        </div>
      </div>
      <brm-motion3d *ngIf="cyclistID && recordingID" [cyclistID]="cyclistID" [recordingID]="recordingID" fxFlex></brm-motion3d>
    </div>
  </brm-card>

  <div fxLayout="row">
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.LeftKnee ]" [projectionAxis]="0" fxFlex></brm-point-trace>
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.LeftKnee ]" [projectionAxis]="1" fxFlex></brm-point-trace>
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.LeftKnee ]" [projectionAxis]="2" fxFlex></brm-point-trace>
  </div>

  <div fxLayout="row">
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.RightKnee ]" [projectionAxis]="0" fxFlex></brm-point-trace>
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.RightKnee ]" [projectionAxis]="1" fxFlex></brm-point-trace>
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.RightKnee ]" [projectionAxis]="2" fxFlex></brm-point-trace>
  </div>

  <motion-body-angle-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftKnee ]" [axis]="0"></motion-body-angle-graph>
  <motion-body-angle-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightKnee ]" [axis]="0"></motion-body-angle-graph>

  <motion-body-angle-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftAnkle ]" [axis]="0"></motion-body-angle-graph>
  <motion-body-angle-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightAnkle ]" [axis]="0"></motion-body-angle-graph>

  <motion-body-angle-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftShoulder ]" [axis]="0"></motion-body-angle-graph>
  <motion-body-angle-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightShoulder ]" [axis]="0"></motion-body-angle-graph>

  <brm-pedalcycles-graph [motionAnalysis]="motionAnalysis"></brm-pedalcycles-graph>

<!--
  <div fxLayout="row">
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftWrist" fxFlex></brm-cycle-extrema-graph>
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightWrist" fxFlex></brm-cycle-extrema-graph>
  </div>

  <div fxLayout="row">
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftElbow" fxFlex></brm-cycle-extrema-graph>
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightElbow" fxFlex></brm-cycle-extrema-graph>
  </div>

  <div fxLayout="row">
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftShoulder" fxFlex></brm-cycle-extrema-graph>
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightShoulder" fxFlex></brm-cycle-extrema-graph>
  </div>

  <div fxLayout="row">
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftPelvis2" fxFlex></brm-cycle-extrema-graph>
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightPelvis2" fxFlex></brm-cycle-extrema-graph>
  </div>

  <div fxLayout="row">
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftPelvis1" fxFlex></brm-cycle-extrema-graph>
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightPelvis1" fxFlex></brm-cycle-extrema-graph>
  </div>
-->

  <div fxLayout="row">
    <div fxLayout="column" fxFlex><h2>Left Hip</h2><brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftHip ]"></brm-cycle-extrema-graph></div>
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightHip ]" fxFlex></brm-cycle-extrema-graph>
  </div>

  <div fxLayout="row">
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftKnee ]" fxFlex></brm-cycle-extrema-graph>
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightKnee ]" fxFlex></brm-cycle-extrema-graph>
  </div>

  <div fxLayout="row">
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftAnkle ]" fxFlex></brm-cycle-extrema-graph>
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightAnkle ]" fxFlex></brm-cycle-extrema-graph>
  </div>

  <div fxLayout="row">
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftFootBack ]" fxFlex></brm-cycle-extrema-graph>
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightFootBack ]" fxFlex></brm-cycle-extrema-graph>
  </div>

  <div fxLayout="row">
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.LeftFootFront ]" fxFlex></brm-cycle-extrema-graph>
    <brm-cycle-extrema-graph [motionAnalysis]="motionAnalysis" [pointTypes]="[ TrackingPoint.RightFootFront ]" fxFlex></brm-cycle-extrema-graph>
  </div>

  <!-- Point Traces -->

  <div fxLayout="row">
    <div fxLayout="column" fxFlex><h2>Left Knee - Right View</h2><brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.LeftKnee ]" [projectionAxis]="0"></brm-point-trace></div>
    <div fxLayout="column" fxFlex><h2>Right Knee - Right View</h2><brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.RightKnee ]" [projectionAxis]="0"></brm-point-trace></div>
  </div>

  <div fxLayout="row">
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.LeftHip ]" [projectionAxis]="0" fxFlex></brm-point-trace>
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.RightHip ]" [projectionAxis]="0" fxFlex></brm-point-trace>
  </div>

  <div fxLayout="row">
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.LeftAnkle, TrackingPoint.RightAnkle ]" [projectionAxis]="0" fxFlex></brm-point-trace>
    <brm-point-trace [pointAnalysis]="motionAnalysis?.pointAnalysis" [pointTypes]="[ TrackingPoint.RightAnkle ]" [projectionAxis]="0" fxFlex></brm-point-trace>
  </div>

  <brm-card title="Point Trace">
    <motion-point-trace-selection [pointAnalysis]="motionAnalysis?.pointAnalysis"></motion-point-trace-selection>
  </brm-card>

  <motion-body-measurements [recording]="recording" *ngIf="recording.bodyMeasurements"></motion-body-measurements>
  <motion-equipment [recording]="recording" *ngIf="recording.equipment"></motion-equipment>

  <brm-card *ngIf="recording.comments" title="Comments">
    <p>{{recording.comments}}</p>
  </brm-card>

  <br />
  Raw Info:
  <pre>{{recording | json}}</pre>

</div>
