<!--<h2 mat-dialog-title>Register User on to the Bioracer Motion cloud</h2>-->
<h2>Register User on the Bioracer Motion cloud</h2>

<div mat-dialog-content class="mat-typography">
  <form>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput [(ngModel)]="formData.email" [ngModelOptions]="{standalone: true}" type="email" placeHolder="Email address" size="50" required>
      <mat-icon matSuffix>email</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input matInput [(ngModel)]="formData.firstName" [ngModelOptions]="{standalone: true}" type="text" placeHolder="First Name" size="50" required>
      <mat-icon matSuffix>badge</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input matInput [(ngModel)]="formData.lastName" [ngModelOptions]="{standalone: true}" type="text" placeHolder="Last Name" size="50" required>
      <mat-icon matSuffix>badge</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Language</mat-label>
      <mat-select [(value)]="formData.langCode">
          <mat-option value="en">English</mat-option>
          <mat-option value="nl">Nederlands</mat-option>
          <!-- TODO Support French -->
          <mat-option value="fr" disabled>Français</mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <brm-card id="error" *ngIf="error" [warning]="true">{{error}}</brm-card>
</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="registerUser()" [disabled]="registering" cdkFocusInitial>{{ registering ? 'Registering user...' : 'Register User'}}</button>
</mat-dialog-actions>
