import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { NgChartsModule, NgChartsConfiguration } from 'ng2-charts';

import { AppComponent } from './app.component';
import { WelcomeComponent } from '../welcome/welcome.component';
import { DevTestComponent } from '../dev-test/dev-test.component';
import { Motion3DComponent } from '../motion3d/motion3d.component';
import { MotionRecordingListComponent } from '../motion-recording-list/motion-recording-list.component';
import { RecordingInfoComponent } from '../recording-info/recording-info.component';
import { RecordingDetailsBikeComponent } from '../recording-details-bike/recording-details-bike.component';
import { RecordingDetailsCyclingComponent } from '../recording-details-cycling/recording-details-cycling.component';
import { MotionOperatorViewComponent } from '../motion-operator-view/motion-operator-view.component';
import { BikeSetupComponent } from '../bike-setup/bike-setup.component';
import { RecordingReportHeaderComponent } from '../recording-report-header/recording-report-header.component';
import { BikeFrameComponent } from '../bike-frame/bike-frame.component';
import { BikeMarkersComponent } from '../bike-markers/bike-markers.component';
import { BodyMeasurementComponent } from '../body-measurements/body-measurements.component';
import { BikeHoodsComponent } from '../bike-hoods/bike-hoods.component';
import { BikePadsComponent } from '../bike-pads/bike-pads.component';
import { BikeShiftersComponent } from '../bike-shifters/bike-shifters.component';
import { EquipmentComponent } from '../equipment/equipment.component';
//import { Test3DComponent } from '../test-3d/test-3d.component';
import { TestLayoutComponent } from '../test-layout/test-layout.component';
import { BikeUCIRulesComponent } from '../bike-uci-rules/bike-uci-rules.component';

import { PedalCyclesGraphComponent } from '../pedalcycles-graph/pedalcycles-graph.component';
import { CycleExtremaGraphComponent } from '../cycle-extrema-graph/cycle-extrema-graph.component';
import { MotionPointTraceComponent } from '../motion-point-trace/point-trace.component';
import { RecordingDetailsCyclingAdvancedComponent } from '../recording-details-cycling-advanced/recording-details-cycling-advanced.component';
import { BodyAngleGraphComponent } from '../body-angle-graph/body-angle-graph.component';
import { PointTraceSelectionComponent } from '../point-trace-selection/point-trace-selection.component';
import { CardComponent } from '@shared/components/card/card.component';
import { OperatorCyclistsComponent } from '../operator-cyclists/operator-cyclists.component';
import { RecordingsViewComponent } from '../recordings-view/recordings-view.component';
import { MotionBreadcrumbComponent } from '../motion-breadcrumb/motion-breadcrumb.component';
import { BikeCardComponent } from '../bike-card/bike-card.component';
import { CyclingAnalysisComponent } from '../cycling-analysis/cycling-analysis.component';
import { ViewSelectionComponent } from '../view-selection/view-selection.component';
import { PointSelectionComponent } from '../point-selection/point-selection.component';
import { CyclingKneeInfoComponent } from '../cycling-knee-info/cycling-knee-info.component';
import { CyclingAnkleInfoComponent } from '../cycling-ankle-info/cycling-ankle-info.component';
import { CyclingPelvisInfoComponent } from '../cycling-hips-info/cycling-pelvis-info.component';
import { CyclingShouldersInfoComponent } from '../cycling-shoulders-info/cycling-shoulders-info.component';


@NgModule({
  declarations: [
    AppComponent,
    MotionBreadcrumbComponent,
    WelcomeComponent,
    DevTestComponent,
    //Test3DComponent,
    TestLayoutComponent,
    Motion3DComponent,
    MotionRecordingListComponent,
    RecordingInfoComponent,
    RecordingDetailsBikeComponent,
    RecordingDetailsCyclingComponent,
    RecordingDetailsCyclingAdvancedComponent,
    MotionOperatorViewComponent,
    OperatorCyclistsComponent,
    RecordingsViewComponent,
    BikeCardComponent,
    BikeSetupComponent,
    BikeFrameComponent,
    BikeMarkersComponent,
    RecordingReportHeaderComponent,
    BodyMeasurementComponent,
    BikeHoodsComponent,
    BikePadsComponent,
    BikeShiftersComponent,
    BikeUCIRulesComponent,
    EquipmentComponent,

    PedalCyclesGraphComponent,
    CycleExtremaGraphComponent,
    MotionPointTraceComponent,
    PointTraceSelectionComponent,
    BodyAngleGraphComponent,
    CyclingAnalysisComponent,
    ViewSelectionComponent,
    PointSelectionComponent,
    CyclingKneeInfoComponent,
    CyclingAnkleInfoComponent,
    CyclingPelvisInfoComponent,
    CyclingShouldersInfoComponent
  ],
  imports: [
    SharedModule,
    NgChartsModule,
    CardComponent
  ],
  providers: [
    //{ provide: NgChartsConfiguration, useValue: {  }}
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
