import { MotionRecordingInfo } from '../services/motion-recording-info.interface';
import { MotionData } from '../services/motion-data';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { ChartConfiguration, ChartOptions, ChartType } from "chart.js";
import { BaseChartDirective } from 'ng2-charts';
import { MotionAnalysis } from '../services/motion-analysis';

@Component({
  selector: 'brm-pedalcycles-graph',
  templateUrl: './pedalcycles-graph.component.html',
  styleUrls: ['./pedalcycles-graph.component.scss']
})
export class PedalCyclesGraphComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() motionAnalysis: MotionAnalysis | null = null;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [0,1,2],
    datasets: [
      {
        data: this.motionAnalysis?.pedalCycles || [0,1,2],
        label: 'Pedal Cycles per minute',
        tension: 0.3,
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)'
      }
    ]
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false
  };
  public lineChartLegend = true;
  public lineChartType = 'line';

  /////

  refreshData() {
    console.log("Refreshing chart data");

    const dataset = this.lineChartData.datasets[0];
    const chartData = this.lineChartData;

    dataset.data = [];
    chartData.labels = [];

    const cycles = this.motionAnalysis?.pedalCycleDurations;
    if(cycles) {
      //dataset.data = cycles;
      for(let i = 0; i < cycles.length; ++i) {
        dataset.data.push(60.0 / cycles[i]);
        chartData.labels.push(i + 1);
      }
    }

    console.log("New line char data:", this.lineChartData);
    this.chart?.update();
  }

  /////

  constructor() {

  }

  ngOnInit(): void {
    this.refreshData();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.debug("[PedalCyclesGraphComponent] changes: ", changes);

    let needsReload = false;

    if(changes.motionAnalysis.currentValue != changes.motionAnalysis.previousValue) {
      console.debug("[PedalCyclesGraphComponent] Provided motion analysis changed from " + changes.motionAnalysis.previousValue + " to " + changes.motionAnalysis.currentValue);
      needsReload = true;
    }

    if(needsReload) {
      this.refreshData();
    }
  }

  ngAfterViewInit() {
  }

  /////

}
