import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'motion-test-layout',
  templateUrl: './test-layout.component.html',
  styleUrls: ['./test-layout.component.scss']
})
export class TestLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
