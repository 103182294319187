import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrmCloudService } from '@shared/lib/brm-cloud.service';

@Component({
  selector: 'brm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() title : string = "Bioracer Motion"
  @Input() logo : string = "assets/motion-logo-rounded.png";

  constructor(protected brmCloud: BrmCloudService, private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.paramMap.subscribe(params => {
      console.log("HeaderComponent: route changed: ", params);
    });
  }

  get motionToolTip() { return this.accessText("motion"); }

  get aeroToolTip() { return this.accessText("aero"); }

  get gridToolTip() { return this.accessText("staticfit"); }

  get adminToolTip() { return "You are an administrator"; }

  accessText(type: string) {
    const access = this.brmCloud.userAccess[type];
    if(access) {
      let text = "You have access to " + this.brmCloud.accessName(type);

      /*if(access.type?.length > 0) {
        text += " (" + access.type + ")";
      }

      if(access.secondsLeft < 0) {
        text += " forever";
      } else {
        text += " for " + access.secondsLeft + " seconds";
      }*/

      return text;
    } else {
      return "You don't have access to " + this.brmCloud.accessName(type);
    }
  }
}
