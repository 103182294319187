import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'motion-bike-card',
  templateUrl: './bike-card.component.html',
  styleUrls: ['./bike-card.component.scss']
})
export class BikeCardComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
  }
}
