import { MotionData, RecordingPointData, TrackingPoint, markerThickness, Vector2, Vector3, wiredMarkers } from "./motion-data";
import { MotionRecordingInfo } from "./motion-recording-info.interface";
import { PointAnalysis, getLeftProjectedPos, getDistance, radToDeg } from "./point-analysis";


// Angles are in degrees around the X,Y or Z axis
// The axes are separate projected angles. They don't represent one 3D rotation
export interface AngleMetrics {
  min: number;
  minFrame: number;
  max: number;
  maxFrame: number;

  range: number;
  avg: number;

}
export interface BodyAngleMetrics {
  [pointType: number]: {
    x: AngleMetrics;
    y: AngleMetrics;
    z: AngleMetrics;
  }
}

export class MotionAnalysis {
  pointAnalysis: PointAnalysis;
  bodyAngleMetrics: BodyAngleMetrics;

  ///// Pedal Cycles /////

  // Start frames of cycles
  pedalCycles : number[] | undefined;
  // Duration of cycles, in seconds
  pedalCycleDurations : number[] | undefined;

  // Crank angle for each point data frame
  frameCrankAngles : number[] | undefined;

  calculatePedalCycles(checkPoint : number) {
    const cycles = this.calcCycles(checkPoint);
    return cycles?.top;
  }

  /////

  constructor(public readonly recording: MotionRecordingInfo, public readonly data: MotionData) {
    this.runAnalysis();

  }

  runAnalysis() {
    this.pointAnalysis = new PointAnalysis(this.data);

    // Calculate the pedal cycles
    this.pedalCycles = this.calculatePedalCycles(TrackingPoint.LeftFootFront);
    this.pedalCycleDurations = this.calcCycleDurations(this.pedalCycles);
    this.frameCrankAngles = this.calcCrankAngles(this.pedalCycles);

    // Body Angle Metrics
    const pointData = this.data.data['Point'] as RecordingPointData;
    const points = pointData.pointFitting.pointTypeToID;

    this.bodyAngleMetrics = {};

    for(const pointType in points) {
      const m = this.calcAngleMetrics(Number.parseInt(pointType));
      if(m) {
        this.bodyAngleMetrics[pointType] = m;
      }
    }

    // TEMP Test cyclic extrema
    const extrema = this.calcCyclicExtrema(TrackingPoint.LeftKnee, this.pedalCycles);
    console.log("Left knee cycle extrema for cycles", this.pedalCycles, extrema);
  }

  getAvgPos(pointType: number, offset: Vector3) {
    var result = undefined;

    const pointName = TrackingPoint.pointName(pointType);
    const point = this.pointAnalysis.metrics.pointMetrics[pointType];
    if(point) {
      result = point.avg;
      console.log(pointName + " pos: " + JSON.stringify(result));
    } else {
      console.warn(pointName + " not found!");
    }

    return result;
  }

  calcCycles(pointType: number) {
    const pointData = this.data.data['Point'] as RecordingPointData;
    const pointID = pointData.pointFitting.pointTypeToID[pointType];
    console.log("Calculating cycles for " + pointType + " - ID " + pointID);

    // We only support TransformUsingStaticBicycleFrame
    if(pointData.transformMode != 1) {
      console.warn("Error: we don't support other transformation modes than TransformUsingStaticBicycleFrame");
      return undefined;
    }

    let cycles = {
      top: new Array(),
      bottom: new Array(),
      front: new Array(),
      back: new Array()
    };

    let first = true;
    let frameIdx = 0;

    let goingUp = false;
    let goingDown = false;
    let goingFront = false;
    let goingBack = false;

    let prevPos = null;
    let cycle = 0;

    // We keep the center Y position and only label as going down when above the average
    // Note: this is the center of the Y range, not the average Y position
    let minY = 0.0, maxY = 0.0;

    for(const frame of pointData.frameData) {
      for(const p of frame.points) {
        if(p.pointID != pointID) {
          continue;
        }

        const pos = PointAnalysis.transformedPosition(p.position, pointData.staticRotation, pointData.staticTranslation);
        const timestamp = p.timestamp;

        // Y bounding box calculations
        if(frameIdx == 0 || pos.y < minY) minY = pos.y;
        if(frameIdx == 0 || pos.y > maxY) maxY = pos.y;

        // We need a first direction (calculated using the first two frames)
        if(frameIdx == 1 && prevPos) {
          goingUp = (pos.y > prevPos.y);
          goingDown = (pos.y < prevPos.y);
          goingFront = (pos.z < prevPos.z);
          goingBack = (pos.z > prevPos.z);
        } else if(frameIdx > 1 && prevPos) {
            // We only switch direction when the position is within a certain part of the (vertical) bounds,
            // to cope with trembling.
            //float minSwitchY = (maxY + minY) / 2;
            const switchThreshold = 0.75; // Need to be in top 25% / bottom 25% to switch direction
            const minSwitchY = minY + (maxY - minY) * switchThreshold;
            const maxSwitchY = maxY - (maxY - minY) * switchThreshold;

            // New cycle when the front of the foot is passed the top of the cycle
            if(goingUp && (pos.y < prevPos.y) && pos.y >= minSwitchY) {
              // The pedal cycle actually starts the previous frame
              const cycleStart = frameIdx - 1;

              cycles.top.push(cycleStart);

              ++cycle;
              goingUp = false;
			      }

            // Halfway?
            else if(!goingUp && (pos.y > prevPos.y) && pos.y <= maxSwitchY) {
              goingUp = true;
            }
        }

        prevPos = pos;
        break;
      }

      ++frameIdx;
    }

    return cycles;
  }

  // Calculate the crank angles for all point data frames
  calcCrankAngles(cycles?: number[]) {
    const pointData = this.data.data['Point'] as RecordingPointData;
    const frames = pointData.frameData;

    // Check for validity
    if(!cycles || !this.validCycles(cycles)) {
      return [];
    }

    let result = new Array(frames.length);

    // Fill frames before first cycle
    for(let frameIdx = 0; frameIdx < cycles[0]; ++frameIdx) {
      result[frameIdx] = -1;
    }

    // We don't use the frames before first and after last cycle
    let prevCycleFrameIdx = -1;
    for(let cycleFrameIdx of cycles) {
      if(prevCycleFrameIdx >= 0) {
        //const numCycleFrames = (cycleFrameIdx - prevCycleFrameIdx);
        //const anglePerFrame = 360.0 / numCycleFrames;

        // We use the frame timestamps for a more accurate calculation
        const prevCycleTimestamp =  frames[prevCycleFrameIdx].frameTime;
        const cycleTimestamp = frames[cycleFrameIdx].frameTime;
        const cycleDuration = (cycleTimestamp - prevCycleTimestamp);
        const anglePerSecond = 360.0 / cycleDuration;
        //console.log("Cycle duration:", cycleDuration);

        for(let frameIdx = prevCycleFrameIdx; frameIdx <= cycleFrameIdx; ++frameIdx) {
          //const crankAngle = (frameIdx - prevCycleFrameIdx) * anglePerFrame;

          const timestamp = frames[frameIdx].frameTime;
          const crankAngle = (timestamp - prevCycleTimestamp) * anglePerSecond;
          result[frameIdx] = crankAngle;
        }
      }

      prevCycleFrameIdx = cycleFrameIdx;
    }

    // Fill frames before after last cycle
    for(let frameIdx = cycles[cycles.length - 1] + 1; frameIdx < frames.length; ++frameIdx) {
      result[frameIdx] = -1;
    }

    return result;
  }

  validCycles(cycles?: number[]) {
    if(!cycles || cycles.length == 0) {
      console.warn("Cycles invalid: no cycle frames");
      return false;
    }

    const pointData = this.data.data['Point'] as RecordingPointData;
    if(!pointData?.frameData) {
      console.warn("Cycles invalid: no frame data");
      return false;
    }

    const frames = pointData.frameData;
    let prevCycleFrameIdx = -1;
    for(let cycleFrameIdx of cycles) {
      if(cycleFrameIdx < 0 || cycleFrameIdx >= frames.length) {
        console.warn("Cycles invalid: invalid cycle frame index: " + cycleFrameIdx);
        return false;
      }

      if(prevCycleFrameIdx >= 0 && cycleFrameIdx <= prevCycleFrameIdx) {
        console.warn("Cycles invalid: cycle frame index not higher than previous: " + cycleFrameIdx + " <= " + prevCycleFrameIdx);
        return false;
      }
    }

    return true;
  }

  calcCycleDurations(cycles?: number[]) {
    const pointData = this.data.data['Point'] as RecordingPointData;
    const frames = pointData.frameData;

    // Check for validity
    if(!cycles || !this.validCycles(cycles)) {
      return [];
    }

    let result = [];

    let prevCycleFrameIdx = -1;
    for(let cycleFrameIdx of cycles) {
      if(prevCycleFrameIdx >= 0) {
        const prevCycleTimestamp =  frames[prevCycleFrameIdx].frameTime;
        const cycleTimestamp = frames[cycleFrameIdx].frameTime;
        const cycleDuration = (cycleTimestamp - prevCycleTimestamp);

        result.push(cycleDuration);
      }

      prevCycleFrameIdx = cycleFrameIdx;
    }

    return result;
  }

  calcCyclicExtrema(pointType: number, cycles?: number[]) {

    const pointData = this.data.data['Point'] as RecordingPointData;
    const frames = pointData.frameData;

    // Check for validity
    if(!cycles || !this.validCycles(cycles)) {
      return [];
    }

    //console.log("calcCyclicExtrema cycles:", cycles);

    let result = [];

    let prevCycleFrameIdx = -1;
    for(let cycleFrameIdx of cycles) {
      if(prevCycleFrameIdx >= 0) {
        //console.log(`calcMetrics(${pointType}, ${prevCycleFrameIdx}, ${cycleFrameIdx})`);
        const m = this.pointAnalysis.calcMetrics(pointType, prevCycleFrameIdx, cycleFrameIdx);
        if(m) {
          result.push(m);
        }
      }

      prevCycleFrameIdx = cycleFrameIdx;
    }

    return result;
  }

  calcAngleMetrics(pointType: number, startFrameIdx?: number, endFrameIdx?: number) {
    const m = {
      x: { min: 0, minFrame: 0, max: 0, maxFrame: 0, range: 0, avg: 0 },
      y: { min: 0, minFrame: 0, max: 0, maxFrame: 0, range: 0, avg: 0 },
      z: { min: 0, minFrame: 0, max: 0, maxFrame: 0, range: 0, avg: 0 }
    };

    let first = true;
    let numPoints: number = 0;

    this.pointAnalysis.iterateTransformedPositions(pointType, startFrameIdx, endFrameIdx, (frameIdx: number, pos: Vector3) => {
      let angles = null;

      const prevPoint = TrackingPoint.prevBodyPoint(pointType);
      const nextPoint = TrackingPoint.nextBodyPoint(pointType);
      if(prevPoint && nextPoint) {
        const prevPos = this.pointAnalysis.calcTransformedPosition(prevPoint, frameIdx) || undefined;
        const nextPos = this.pointAnalysis.calcTransformedPosition(nextPoint, frameIdx) || undefined;
        angles = this.calcAngles(prevPos, pos, nextPos) || { x: 0, y: 0, z: 0};

        // DEBUG TEMP
        if(pointType == TrackingPoint.LeftKnee && (frameIdx % 100 === 0)) {
          console.log("Calculated angle of left knee");
          console.log(`point: ${TrackingPoint.pointName(pointType)} - (${pos?.x}; ${pos?.y}; ${pos?.z})`);
          console.log(`prev point: ${TrackingPoint.pointName(prevPoint)} - (${prevPos?.x}; ${prevPos?.y}; ${prevPos?.z})`);
          console.log(`next point: ${TrackingPoint.pointName(nextPoint)} - (${nextPos?.x}; ${nextPos?.y}; ${nextPos?.z})`);
          console.log(`angles: (${angles?.x}; ${angles?.y}; ${angles?.z})`)
        }
      }

      if(angles) {
        if(first || angles.x < m.x.min) {
          m.x.min = angles.x;
          m.x.minFrame = frameIdx;
        }
        if(first || angles.y < m.y.min) {
          m.y.min = angles.y;
          m.y.minFrame = frameIdx;
        }
        if(first || angles.z < m.z.min) {
          m.z.min = angles.z;
          m.z.minFrame = frameIdx;
        }

        if(first || angles.x > m.x.max) {
          m.x.max = angles.x;
          m.x.maxFrame = frameIdx;
        }
        if(first || angles.y > m.y.max) {
          m.y.max = angles.y;
          m.y.maxFrame = frameIdx;
        }
        if(first || angles.z > m.z.max) {
          m.z.max = angles.z;
          m.z.maxFrame = frameIdx;
        }

        m.x.avg += angles.x;
        m.y.avg += angles.y;
        m.z.avg += angles.z;

        ++numPoints;
        first = false;
      }

      // Continue with next point
      return true;
    });

    m.x.range = Math.abs(m.x.max - m.x.min);
    m.y.range = Math.abs(m.y.max - m.y.min);
    m.z.range = Math.abs(m.z.max - m.z.min);

    if(numPoints > 0) {
      m.x.avg /= numPoints;
      m.y.avg /= numPoints;
      m.z.avg /= numPoints;
    }

    //console.log(`avg pos of ${TrackingPoint.pointName(pointType)}: ${m.avg} (${numPoints} point(s))`);

    return m;
  }

  iterateAllAngles(pointType: number, onAngle: Function) {
    const pointData = this.data.data['Point'] as RecordingPointData;
    if(!pointData) {
      return;
    }

    const startFrameIdx = 0;
    const endFrameIdx = pointData.frameData.length;
    this.iterateAngles(pointType, startFrameIdx, endFrameIdx, onAngle)
  }

  iterateAngles(pointType: number, startFrameIdx: number|undefined, endFrameIdx: number|undefined, onAngle: Function) {
    this.pointAnalysis.iterateTransformedPositions(pointType, startFrameIdx, endFrameIdx, (frameIdx: number, pos: Vector3) => {
      const prevPoint = TrackingPoint.prevBodyPoint(pointType);
      const nextPoint = TrackingPoint.nextBodyPoint(pointType);
      if(prevPoint && nextPoint) {
        const prevPos = this.pointAnalysis.calcTransformedPosition(prevPoint, frameIdx) || undefined;
        const nextPos = this.pointAnalysis.calcTransformedPosition(nextPoint, frameIdx) || undefined;
        const angles = this.calcAngles(prevPos, pos, nextPos) || { x: 0, y: 0, z: 0};

        onAngle(frameIdx, angles);
      }

      // Continue with next point
      return true;
    });
  }

  ///// Utils /////

  getCenter(p1? : Vector3, p2? : Vector3): Vector3|undefined {
    const x = (p1 && p2) ? (p1.x + p2.x) / 2 : p1 ? p1.x : p2 ? p2.x : undefined;
    const y = (p1 && p2) ? (p1.y + p2.y) / 2 : p1 ? p1.y : p2 ? p2.y : undefined;
    const z = (p1 && p2) ? (p1.z + p2.z) / 2 : p1 ? p1.z : p2 ? p2.z : undefined;
    return (x && y && z) ? { x: x, y: y, z: z } : undefined;
  }

  // Note: from p1 to p2
  getHeightDifference(p1? : Vector3, p2? : Vector3): number|undefined {
    // We need both points
    if(!p1 || !p2) {
      return undefined
    }

    return p2.y - p1.y;
  }

  // Angles around the three axes
  calcAngles(p1? : Vector3, p2? : Vector3, p3? : Vector3): Vector3|undefined {
    // We need all points
    if(!p1 || !p2 || !p3) {
      return undefined
    }

    let angles : Vector3 = { x: 0, y: 0, z: 0};

    const fromX = { x: p1.z - p2.z, y: p1.y - p2.y };
    const toX = { x: p3.z - p2.z, y: p3.y - p2.y };
    angles.x = this.getRotationTo(fromX, toX) || 0;

    const fromY = { x: p1.x - p2.x, y: p1.z - p2.z };
    const toY = { x: p3.x - p2.x, y: p3.z - p2.z };
    angles.y = this.getRotationTo(fromY, toY) || 0;

    const fromZ = { x: p1.x - p2.x, y: p1.y - p2.y };
    const toZ = { x: p3.x - p2.x, y: p3.y - p2.y };
    angles.z = this.getRotationTo(fromZ, toZ) || 0;

    return angles;
  }

  // Angle between 0 and 360 degrees
  getRotationTo(p1? : Vector2, p2? : Vector2): number|undefined {
    // We need all points
    if(!p1 || !p2) {
      return undefined
    }

    /*const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    let angle = Math.atan2(dy, dx) * 180 / Math.PI;*/

    const lengthP1 = Math.sqrt(p1.x*p1.x + p1.y*p1.y);
    const lengthP2 = Math.sqrt(p2.x*p2.x + p2.y*p2.y);
    let angle = Math.acos((p1.x * p2.x + p1.y * p2.y) / (lengthP1 * lengthP2));
    angle *= 180 / Math.PI; // Radians to degrees

    // [-180;180] to [0;360]
    if(angle < 0) {
      angle += 360;
    }

    return angle;
  }
}
