<div id="root" *ngIf="brmCloud.loggedOnUser$ | async; else notLoggedOn" fxLayout="column" fxFlexFill>
  <div fxLayout="column" fxFlex>
    <h1>Cyclists</h1>
    <div class="cyclists" fxFlex>
      <motion-operator-cyclists-list #cyclists (cyclistSelected)="handleCyclistSelected($event)"></motion-operator-cyclists-list>
    </div>
  </div>
</div>

<ng-template #notLoggedOn>
  <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> to see your data.
</ng-template>
