import { MotionRecordingInfo } from '../services/motion-recording-info.interface';
import { MotionData, TrackingPoint, Vector3 } from '../services/motion-data';
import { MotionAnalysis } from '../services/motion-analysis';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartConfiguration, ChartOptions, ChartType } from "chart.js";
import annotationPlugin, { AnnotationOptions, LineAnnotationOptions } from 'chartjs-plugin-annotation';

@Component({
  selector: 'motion-body-angle-graph',
  templateUrl: './body-angle-graph.component.html',
  styleUrls: ['./body-angle-graph.component.scss']
})
export class BodyAngleGraphComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() motionAnalysis: MotionAnalysis | null = null;
  @Input() pointType: number | null = null;
  @Input() axis: number = 0; // 0,1,2: X,Y,Z

  @Input() beginAtZero: boolean = false;
  @Input() maxY?: number = undefined;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  annotationsDefault = {
    value: 0,
    scaleID: 'y',
    borderColor: 'rgb(100, 100, 200)',
    borderWidth: 1,
    label: {
      display: true,
      position: 'start',
      backgroundColor: 'rgba(100,149,237)'
    }
  };
  annotations = {
    min: { ...this.annotationsDefault, label: { ...this.annotationsDefault.label, content: ()=> this.minLabel }, value: ()=> this.minValue },
    max: { ...this.annotationsDefault, label: { ...this.annotationsDefault.label, content: ()=> this.maxLabel }, value: ()=> this.maxValue },
    avg: { ...this.annotationsDefault, label: { ...this.annotationsDefault.label, content: ()=> this.avgLabel }, value: ()=> this.avgValue }
  }

  // In degrees
  minValue = 0
  maxValue = 0
  avgValue = 0

  get minLabel() { return "min: " + this.minValue.toFixed(1); }
  get maxLabel() { return "max: " + this.maxValue.toFixed(1); }
  get avgLabel() { return "avg: " + this.avgValue.toFixed(1); }

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Body Angle',
        tension: 0.3,
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)',
        pointRadius: 0
      }
    ]
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false,
    scales: {
      y: {
        beginAtZero: this.beginAtZero,
        suggestedMax: this.maxY
      }
    },
    plugins: {
      annotation: {
        annotations: this.annotations
      }
    }
  };
  public lineChartLegend = true;
  public lineChartType = 'line';

  /////

  refreshData() {
    console.log("Refreshing chart data");

    const dataset = this.lineChartData.datasets[0];
    const chartData = this.lineChartData;

    if(this.pointType) {
      // TEMP Hardcoded parameter (angle) and unit (degrees)
      dataset.label = TrackingPoint.pointName(this.pointType) + " angle (degrees)";
    } else {
      dataset.label = "No point set";
    }
    dataset.data = [];
    chartData.labels = [];

    if(this.pointType) {
      this.motionAnalysis?.iterateAllAngles(this.pointType, (frameIdx: number, angles: Vector3) => {
        const angle = this.axisValue(angles);
        dataset.data.push(angle); // degrees
        chartData.labels?.push(frameIdx);
      });

      // Metrics
      const metrics = this.motionAnalysis?.bodyAngleMetrics[this.pointType];
      if(metrics) {
        const m = this.axis === 0 ? metrics.x : this.axis === 1 ? metrics.y : metrics.z;
        this.minValue = m.min;
        this.maxValue = m.max;
        this.avgValue = m.avg;
      }
    }

    console.log("New body angles line chart data:", this.lineChartData);
    this.chart?.update();
  }

  /////

  axisValue(values : Vector3) {
    if(this.axis === 0) {
      return values.x;
    } else if(this.axis === 1) {
      return values.y;
    } else if(this.axis === 2) {
      return values.z;
    }

    return 0;
  }

  /////

  constructor() {
    Chart.register(annotationPlugin);
  }

  ngOnInit(): void {
    this.refreshData();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.debug("[BodyAngleGraphComponent] changes: ", changes);

    let needsReload = false;

    if(changes.motionAnalysis.currentValue != changes.motionAnalysis.previousValue) {
      console.debug("[BodyAngleGraphComponent] Provided motion analysis changed from " + changes.motionAnalysis.previousValue + " to " + changes.motionAnalysis.currentValue);
      needsReload = true;
    }

    if(needsReload) {
      this.refreshData();
    }
  }

  ngAfterViewInit() {
  }

  /////

}
