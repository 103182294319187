import { Component, Input, OnInit } from '@angular/core';
import { BikeAnalysis } from '../services/bike-analysis';
import { MotionRecordingInfo } from '../services/motion-recording-info.interface';

// Note: UCI rules as of 2023-01-01

@Component({
  selector: 'motion-bike-uci-rules',
  templateUrl: './bike-uci-rules.component.html',
  styleUrls: ['./bike-uci-rules.component.scss']
})
export class BikeUCIRulesComponent implements OnInit {

  @Input() recording: MotionRecordingInfo | null = null;
  @Input() bikeAnalysis: BikeAnalysis | null = null;

  // Properties for the active cyclist and bike recording
  public get bodyLength() {
    return this.recording?.bodyMeasurements?.bodyLength;
  }

  public get category() {
    return this.calcCategory(this.recording?.bodyMeasurements?.bodyLength);
  }

  // Category 1: < 180cm
  // Category 2: >= 180cm; <= 190cm
  // Category 3: > 190cm
  public calcCategory(bodyHeight: number|undefined) {
    // We default to category 0
    if(bodyHeight == undefined) {
      return 0;
    }

    if(bodyHeight < 1.8) {
      return 1;
    } else if(bodyHeight > 1.9) {
      return 3;
    } else {
      return 2;
    }
  }

  /// Saddle Front Setback ///

  public get validSaddleFrontSetback() {
    const horDistance = this.bikeAnalysis?.saddleFrontSetback;
    return this.calcValidSaddleFrontSetback(horDistance);
  }

  public get saddleFrontSetbackValidityClass() {
    return this.calcValidityClass(this.validSaddleFrontSetback);
  }

  // Horizontal distance from crank axle to front of saddle
  // (independent of category)
  public calcValidSaddleFrontSetback(horizontalDistance: number|undefined) {
    if(horizontalDistance == undefined) {
      return undefined;
    }

    return horizontalDistance >= this.minSaddleFrontSetback;
  }

  public get minSaddleFrontSetback() {
    return 0.05;
  }

  /// Shifters Reach ///

  public get validShiftersHorizontalReach() {
    const bodyLength = this.recording?.bodyMeasurements?.bodyLength;
    const shiftersX = this.bikeAnalysis?.shiftersX;
    return this.calcValidShiftersHorizontalReach(bodyLength, shiftersX);
  }

  public get shiftersHorizontalReachValidityClass() {
    return this.calcValidityClass(this.validShiftersHorizontalReach);
  }

  // Horizontal distance from crank axle to shifters
  public calcValidShiftersHorizontalReach(bodyHeight: number|undefined, reach: number|undefined) {
    if(reach == undefined) {
      return undefined;
    }

    const cat = this.calcCategory(bodyHeight);
    return reach <= this.maxShiftersHorizontalReachForCategory(cat);
  }

  public get maxShiftersHorizontalReach() {
    return this.maxShiftersHorizontalReachForCategory(this.category);
  }

  public maxShiftersHorizontalReachForCategory(category: number) {
    switch(category)
    {
      default:
      case 1: return 0.8;
      case 2: return 0.83;
      case 3: return 0.85;
    }
  }

  /// Pads Drop ///

  public get validShiftersPadsDrop() {
    const bodyLength = this.recording?.bodyMeasurements?.bodyLength;
    const drop = this.bikeAnalysis?.shiftersPadsDrop;
    return this.calcValidShiftersPadsDrop(bodyLength, drop);
  }

  public get shiftersPadDropValidityClass() {
    return this.calcValidityClass(this.validShiftersPadsDrop);
  }

  // Vertical distance from shifters to elbow pads
  public calcValidShiftersPadsDrop(bodyHeight: number|undefined, drop: number|undefined) {
    if(drop == undefined) {
      return undefined;
    }

    const cat = this.calcCategory(bodyHeight);
    return drop <= this.maxShiftersPadsDropForCategory(cat);
  }

  public get maxShiftersPadsDrop() {
    return this.maxShiftersPadsDropForCategory(this.category);
  }

  public maxShiftersPadsDropForCategory(category: number) {
    switch(category)
    {
      default:
      case 1: return 0.1;
      case 2: return 0.12;
      case 3: return 0.14;
    }
  }

  /////

  // CSS class to use
  public calcValidityClass(valid: boolean|undefined) {
    if(valid == undefined) {
      return 'valid'; // Note: we asssume valid when unknown
    } else if(valid) {
      return 'valid';
    } else {
      return 'invalid';
    }
  }

  /////

  // Display a length value in millimeters
  public lengthMM(value?: number) {
    if(value) {
      return (value * 1000).toFixed();
    } else {
      return "/";
    }
  }

  public diffMM(value?: number) {
    if(value) {
      return (value * 1000).toFixed() + " (L" + (value >= 0 ? "↗" : "↘") + "R)";
    } else {
      return "/";
    }
  }

  public displayAngle(angle?: number) {
    if(angle) {
      return (angle).toFixed(1);
    } else {
      return "/";
    }
  }

  /////

  constructor() {

  }

  ngOnInit(): void {
  }
}
