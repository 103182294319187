import { Component, ViewChild, Inject } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialogRef } from '@angular/material/dialog';

import { BrmCloudService } from '../../lib/brm-cloud.service';
import { BRMUser } from '../../lib/BRMUser';
import { FirebaseError } from 'firebase/app';

@Component({
  selector: 'brm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  constructor(public readonly brmCloud: BrmCloudService) { }

}

///// Login Dialog /////

@Component({
  selector: 'brm-login-dialog',
  templateUrl: 'login-dialog.html',
  styleUrls: ['login-dialog.scss']
})
export class LoginDialog {
  hidePassword = true;
  error = "";
  get loggingOn() { return this.brmCloud.loggingOn; }

  formData = {
    email: '',
    password: ''
  };

  constructor(public dialogRef: MatDialogRef<LoginDialog>, private brmCloud: BrmCloudService) {}

  login() {
    console.log("Logging in with email", this.formData.email);
    this.brmCloud.login(this.formData.email, this.formData.password)
    .then(() => {
      console.log("Login successful");
      this.error = "";
      this.dialogRef.close(true);
    }).catch((error: FirebaseError) => {
      console.log("Login failed: ", error);
      this.error = error.message;
    });
  }

  resetPassword() {
    this.brmCloud.resetPassword(this.formData.email, true);
  }
}
