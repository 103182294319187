import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrackingPoint } from '../services/motion-data';

interface AvailablePoint {
  pointID: number;
  name: string
};

@Component({
  selector: 'motion-point-selection',
  templateUrl: './point-selection.component.html',
  styleUrls: ['./point-selection.component.scss']
})
export class PointSelectionComponent implements OnInit {

  // Expose enum to html
  TrackingPoint = TrackingPoint;

  // Title
  @Input() title: string = 'Point';

  selectedPointID: number = -1;
  availablePoints: Array<AvailablePoint> = [];

  /////

  constructor() {
  }

  ngOnInit(): void {
    this.availablePoints = this.generateAvailablePoints();
  }

  /////

  private generateAvailablePoints(): Array<AvailablePoint> {
    let result = [];
    for(let point of TrackingPoint.leftBodyPoints) {
      result.push({ pointID: point, name: TrackingPoint.pointName(point) });
    }

    for(let point of TrackingPoint.rightBodyPoints) {
      result.push({ pointID: point, name: TrackingPoint.pointName(point) });
    }

    return result;
  }
}
