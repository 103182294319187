<!-- Only for TT bikes -->
<brm-card *ngIf="bikeAnalysis && bikeAnalysis.elbowPadsPos" title="Elbow Pads">
  <img id="bikeImage" src="assets/bike-report/TT Bike Report - Elbow Pads.svg" alt="Bike" />

  <div class="content">
    <div class="titles">
      <p class="xy">Pads X (mm): </p>
      <p class="xy">Pads Y (mm): </p>
      <p>Pads reach (mm): </p>
      <p>Pads drop (mm): </p>
      <p>Pads width (mm): </p>
      <p>Height difference (mm): </p>
    </div>
    <div class="values">
      <p>{{lengthMM(bikeAnalysis.elbowPadsX)}}</p>
      <p>{{lengthMM(bikeAnalysis.elbowPadsY)}}</p>
      <p>{{lengthMM(bikeAnalysis.elbowPadsReach)}}</p>
      <p>{{lengthMM(bikeAnalysis.elbowPadsDrop)}}</p>
      <p>{{lengthMM(bikeAnalysis.elbowPadsWidth)}}</p>
      <p>{{diffMM(bikeAnalysis.elbowPadsHeightDifference)}}</p>
    </div>
  </div>
</brm-card>
