<div *ngIf="brmCloud.loggedOnUser$ | async; else notLoggedOn">
  <h1>Welcome {{ brmCloud.userFullName }}</h1>

  This is your personal Bioracer Motion home.<br />
  <br />
  <p *ngIf="brmCloud.isMotionOperator">
    You can view your own Motion recordings,
    and also the recordings with you as an operator.
  </p>
  <p *ngIf="!brmCloud.isMotionOperator">
    You can view the recordings of sessions you did
    with a Motion operator.
  </p>
</div>

<ng-template #notLoggedOn>
<div>

  <h1>Welcome to My Bioracer Motion</h1>

  <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> with your Bioracer Motion account to see all your info and data.<br />

  <br />

  An account will be created the first time you get one of our service treatments.<br />

  You can also <a href="javascript:void(0);" role="button" (click)="brmCloud.openRegisterUserDialog()">create an account yourself</a> to enter the Bioracer Motion universe.

</div>
</ng-template>
