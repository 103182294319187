import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { Observable, of, map, switchMap } from 'rxjs';

import { MotionData } from './motion-data';
import { MotionRecordingInfo } from './motion-recording-info.interface';
import { UserDocument } from '@shared/lib/user-document.interface';
import { ActivatedRouteSnapshot, GuardsCheckStart, NavigationEnd, Router, RouterEvent, RouterStateSnapshot } from '@angular/router';
import { BrmCloudService } from '@shared/lib/brm-cloud.service';
import { MatBreadcrumbService } from '@shared/mat-breadcrumb/mat-breadcrumb.service';


@Injectable({
  providedIn: 'root'
})
export class MotionDataService {
  // The active cyclist
  activeCyclist$ : Observable<UserDocument | undefined>;
  activeCyclist? : UserDocument;
  prevActiveCyclist?: UserDocument;

  // The active recording
  activeRecording$ : Observable<MotionRecordingInfo | undefined>;
  activeRecording? : MotionRecordingInfo;
  prevActiveRecording?: MotionRecordingInfo;

  @Output() activeCyclistChanged = new EventEmitter<UserDocument>();
  @Output() activeRecordingChanged = new EventEmitter<MotionRecordingInfo>();

  setActiveCyclist(cyclistID: string|null) {
    try {
        // Only when changed
      if(this.activeCyclist?.userID === cyclistID) {
        return;
      }

      this.prevActiveCyclist = this.activeCyclist;

      if(cyclistID) {
        this.activeCyclist$ = this.brmCloud.userDoc(cyclistID);
        this.activeCyclist$.subscribe({
          next: cyclist => {
            this.activeCyclist = cyclist;
            this.activeCyclistChanged.emit(cyclist);
          },
          error: err => {
            throw new Error("Error getting active cyclist: " + err.message);
          }
        });
      } else {
        this.activeCyclist$ = of(undefined);
        this.activeCyclist = undefined;
        this.activeCyclistChanged.emit(undefined);
      }
    }
    catch(err) {
      console.error("Error setting active cyclist", err);
      this.activeCyclist$ = of(undefined);
      this.activeCyclist = undefined;
      this.activeCyclistChanged.emit(undefined);
    }
  }

  // The active cyclist has to be set before calling this
  setActiveRecording(recordingID: string|null) {
    try {
      if(recordingID && !this.activeCyclist) {
        throw new Error("Active cyclist not set");
      }

      // Only when changed
      if(this.activeRecording?.recordingID === recordingID) {
        return;
      }

      this.prevActiveRecording = this.activeRecording;

      if(this.activeCyclist && recordingID) {
        const cyclistID = this.activeCyclist.userID;
        this.activeRecording$ = this.getRecordingInfo(cyclistID, recordingID);
        this.activeRecording$.subscribe({
          next: recording => {
            this.activeRecording = recording;
            this.activeRecordingChanged.emit(recording);
          },
          error: err => {
            throw new Error("Error getting active recording: " + err.message);
          }
        });
      } else {
        this.activeRecording$ = of(undefined);
        this.activeRecording = undefined;
        this.activeRecordingChanged.emit(undefined);
      }
    }
    catch(err) {
      console.error("Error setting active recording", err);
      this.activeRecording$ = of(undefined);
      this.activeRecording = undefined;
      this.activeRecordingChanged.emit(undefined);
  }
  }

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly storage: AngularFireStorage,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly brmCloud: BrmCloudService,
    private readonly breadcrumb: MatBreadcrumbService) {
      // We capture the active cyclist and recording from URL
      this.router.events.subscribe(event => {
        const routerEvent = event as RouterEvent;
        //console.log("Router event: ", event);
        //console.log("Router event URL: ", routerEvent.url);

        if(event instanceof GuardsCheckStart) {
          /*console.log("Router event URL after redirect: ", event.urlAfterRedirects);
          const parsed = this.router.parseUrl(event.urlAfterRedirects);
          console.log(parsed);
          const cyclistID = parsed.queryParamMap.get('cyclistID');
          const recordingID = parsed.queryParamMap.get('recordingID');

          if(cyclistID) {
            console.log("Active cyclist ID from URL: " + cyclistID);
            this.setActiveCyclist(cyclistID);
          }

          if(recordingID) {
            console.log("Active recording ID from URL: " + recordingID);
            this.setActiveRecording(recordingID);
          }*/

          let cyclistID = null;
          let recordingID = null;

          // Check route tree for cyclist and recording ID
          let snapshot: ActivatedRouteSnapshot|null = event.state.root;
          while(snapshot && !(cyclistID && recordingID)) {
            console.log("Checking route snapshot: ", snapshot);

            if(snapshot.paramMap.has("cyclistID")) {
              cyclistID = snapshot.paramMap.get("cyclistID");
            }

            if(snapshot.paramMap.has("recordingID")) {
              recordingID = snapshot.paramMap.get("recordingID");
            }

            /*if(cyclistID && recordingID) {
              console.log("Route snapshot has cyclist and recording ID: ", cyclistID, recordingID);

              this.getRecordingInfo(cyclistID, recordingID).subscribe(info => {
                console.log("Recording info: ", info);
                this.activeRecording = info;
              });
            }*/

            snapshot = snapshot.firstChild;
          }

          if(cyclistID) {
            console.log("[MotionData] Setting active cyclist ID from URL: ", cyclistID)
            this.setActiveCyclist(cyclistID);
          } else {
            console.log("[MotionData] No active cyclist ID from URL");
            this.setActiveCyclist(null);
          }

          if(recordingID) {
            console.log("[MotionData] Setting active recording ID from URL: ", recordingID)
            this.setActiveRecording(recordingID);
          } else {
            console.log("[MotionData] No active recording ID from URL");
            this.setActiveRecording(null);
          }
      }
    });

      // When logged on, set the active cyclist by default
      /*this.brmCloud.loggedOnUser$.subscribe(user => {
        if(user && !this.activeCyclist) {
          this.activeCyclist = user;
        }
      });*/
  }

  getRecordingInfo(cyclistID: string | null, recordingID: string | null): Observable<MotionRecordingInfo | undefined> {
    if(cyclistID == null || recordingID == null) {
      return of(undefined);
    }

    const recordingDoc = this.firestore.doc<MotionRecordingInfo>(`motionUserData/${cyclistID}/recordings/${recordingID}`);

    return recordingDoc.valueChanges({ idField: 'recordingID' }).pipe(map(
      doc => this.processRecordingInfo(doc, cyclistID)
    ));
  }

  processRecordingInfo(recInfo: MotionRecordingInfo|undefined, cyclistID: string) {
    if(!recInfo) {
      return recInfo;
    }

    const result = recInfo;

    // Add cyclist ID
    result.cyclistID = cyclistID;

    // Convert timestamps to date objects
    const t = result.recordingTime;
    if(t) {
      /* Some recording times are still in milliseconds instead of a real Timestamp */
      if(typeof t === "number") {
        result.recordingDate = new Date(t);
      } else {
        result.recordingDate = t.toDate();
      }
    }

    return result;
  }

  /////

  getData(cyclistID: string, recordingID: string): Observable<MotionData> {
    const dataRef = this.recordingDataRef(cyclistID, recordingID);
    return dataRef.getDownloadURL().pipe(
      switchMap(url => {
        return this.http.get(url, {responseType: 'arraybuffer'}).pipe(
          switchMap(results => {
            // Load data
            const data = new MotionData();
            data.loadFromData(results);
            return of(data);
          })
        );
      })
    );
  }

  // Download recording data using the browser
  downloadRecordingData(cyclistID: string, recordingID: string) {
    const dataRef = this.recordingDataRef(cyclistID, recordingID);
    const url$ = dataRef.getDownloadURL();

    url$.subscribe(url => {
      const a = document.createElement('a')
      a.href = url
      a.download = `${recordingID}.brr.data`;
      a.click();
    });
  }

  recordingDataRef(cyclistID: string, recordingID: string) {
    return this.storage.ref(`/motion/userData/${cyclistID}/recordings/${recordingID}.brr.data`);
  }

  /////

  getRecordings(cyclistID: string) {
    const collection = this.recordingsCollectionRef(cyclistID);
    return collection.valueChanges({ idField: 'recordingID' }).pipe(map(recs => {
      for(const recInfo of recs) {
        this.processRecordingInfo(recInfo, cyclistID);
      }
      return recs;
    }));
  }

  recordingsCollectionRef(cyclistID: string) {
    return this.firestore.collection<MotionRecordingInfo>(
      `motionUserData/${cyclistID}/recordings`,
      ref => ref.where('hidden', '==', false).orderBy('recordingTime', 'desc')
    );
  }

}
