<brm-card *ngIf="motionAnalysis" title="Ankles">
  <brm-point-trace
    class="image"
    [pointAnalysis]="motionAnalysis.pointAnalysis"
    [pointTypes]="[ TrackingPoint.LeftAnkle, TrackingPoint.RightAnkle ]"
    [projectionAxis]="0"
    [drawMidpoint]="true"
  ></brm-point-trace>

  <div class="content">
    <div class="titles">
      <p>Left flexion angle (°): </p>
      <p>Right flexion angle (°): </p>
      <p>Left lateral movement (mm)</p>
      <p>Right lateral movement (mm)</p>
    </div>
    <div class="values">
      <p>{{leftAnkleAngleMetrics?.min?.toFixed() || '/'}} - {{leftAnkleAngleMetrics?.max?.toFixed() || '/'}} ({{leftAnkleAngleMetrics?.range?.toFixed() || '/'}})</p>
      <p>{{rightAnkleAngleMetrics?.min?.toFixed() || '/'}} - {{rightAnkleAngleMetrics?.max?.toFixed() || '/'}} ({{rightAnkleAngleMetrics?.range?.toFixed() || '/'}})</p>
      <p>{{leftAnklePointMetrics ? (leftAnklePointMetrics.width * 1000).toFixed() : '/'}}</p>
      <p>{{rightAnklePointMetrics ? (rightAnklePointMetrics.width * 1000).toFixed() : '/'}}</p>
    </div>
  </div>
</brm-card>
