import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, Router, Routes } from '@angular/router';

import { NavigationItem } from './NavigationItem.interface';
import { MatBreadcrumbService } from '@shared/mat-breadcrumb/mat-breadcrumb.service';
import { Breadcrumb } from '@shared/mat-breadcrumb/breadcrumb.model';
import { BrmCloudService } from '@shared/lib/brm-cloud.service';

@Component({
  selector: 'brm-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() title = 'My Motion';
  @Input() navItems: NavigationItem[] = [ ];
  @Input() otherRoutes: Routes = [];

  routes: Routes = [];

  // isHandset is true when we have a handset device
  // We show the menu differently in that case
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    public readonly brmCloud: BrmCloudService,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private breadcrumbService: MatBreadcrumbService) {
  }

  ngOnInit(): void {
    // Setup routes
    this.setupRoutes();
  }

  setupRoutes(): void {
    console.log("Setting up navigation routes from navigation items", this.navItems);

    this.routes = [];

    for(let item of this.navItems) {
      // Add breadcrumb data if not explicitly defined
      if(!item.route.data?.breadcrumb) {
        if(!item.route.data) {
          item.route.data =  {};
        }
        item.route.data.breadcrumb = [ { label: item.route.title, url: item.route.path || '' } ];
      }

      this.routes.push(item.route);
    }
    for(let route of this.otherRoutes) {
      this.routes.push(route);
    }

    // Fallback route
    this.routes.push({
      path: '',
      redirectTo: 'welcome',
      pathMatch: 'full'
    });

    this.router.resetConfig(this.routes);
    console.log("Available navigation routes:", this.routes);

    // DEBUG TEMP
    console.log("Route params observable:", this.route.paramMap);
    this.route.paramMap.subscribe(params => {
      console.log("Route params: ", params);

      const cyclistID = params.get("cyclistID");
      if(cyclistID) {
        console.log("Cyclist ID: ", cyclistID);
      }

      const recordingID = params.get("recordingID");
      if(recordingID) {
        console.log("Recording ID: ", recordingID);
      }
    });
  }

  // Information to determin whether to show and/or disable an item
  shouldShow(item: NavigationItem) {
    if(item.visible != undefined && !item.visible) {
      return false;
    }

    const hasAccess = !item.access || this.brmCloud.hasAccess(item.access);
    if(item.hideWithoutAccess != undefined && item.hideWithoutAccess && !hasAccess) {
      return false;
    }

    const hasHideAccess = item.hideWithAccess && this.brmCloud.hasAccess(item.hideWithAccess);
    if(hasHideAccess) {
      return false;
    }

    return true;
  }

  shouldDisable(item: NavigationItem) {
    const hasAccess = !item.access || item.access.length == 0 || this.brmCloud.hasAccess(item.access);
    return !hasAccess;
  }
}
