import { MotionRecordingInfo } from '../services/motion-recording-info.interface';
import { MotionData } from '../services/motion-data';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ActivatedRoute } from '@angular/router';
import { MotionDataService } from '../services/motion-data.service';
import { MotionBody3D } from './motion-body-3d';
import { PointAnalysis } from '../services/point-analysis';

@Component({
  selector: 'brm-motion3d',
  templateUrl: './motion3d.component.html',
  styleUrls: ['./motion3d.component.scss']
})
export class Motion3DComponent implements OnInit, AfterViewInit, OnChanges {

  // We can provide the cyclist and recording ID as properties or as URL parameters
  @Input() cyclistID?: string;
  @Input() recordingID?: string;

  @Input() title : string = "";
  @Input() groundPlane : string = "assets/motion-logo-rounded.png";

  @Input() slowMotion : boolean = false;
  @Input() slowMotionScale : number = 0.2;

  // Side margins in the body view
  @Input() margin : number = 0.1;

  recording$: Observable<MotionRecordingInfo|undefined>;

  recordingData$: Observable<MotionData>;
  recordingData: MotionData|null = null;

  pointAnalysis: PointAnalysis|null = null;

  body3D: MotionBody3D | null = null;
  animStartTime: number = -1;
  prevTime: number = -1;
  prevAnimTime: number = -1;
  useOrthoGraphicCamera: boolean = false;
  //frustumSize: number = 2;

  get bbox() { return this.body3D?.recordingBoundingBox; }
  get bboxCenter() { return this.bbox?.getCenter(new THREE.Vector3(0,0,0)); }
  get bboxWidth() { return this.bbox ? (this.bbox.max.x - this.bbox.min.x) : 0; }
  get bboxHeight() { return this.bbox ? (this.bbox.max.y - this.bbox.min.y) : 0; }
  get bboxDepth() { return this.bbox ? (this.bbox.max.z - this.bbox.min.z) : 0; }

  // Three
  private scene!: THREE.Scene;
  private cameraPersp!: THREE.PerspectiveCamera;
  private cameraOrtho!: THREE.OrthographicCamera;
  get camera() { return this.useOrthoGraphicCamera ? this.cameraOrtho : this.cameraPersp; }
  private renderer!: THREE.WebGLRenderer;
  private clearColor = new THREE.Color('#FFF');

  private cameraPosition: THREE.Vector3 = new THREE.Vector3(0, 1, 100);
  private cameraFocus: THREE.Vector3 = new THREE.Vector3(0, 1, 0);
  private fieldOfView: number = 1;
  private nearClippingPlane: number = 1;
  private farClippingPlane: number = 1000;

  private perspControls: OrbitControls;
  //private orthoControls: OrbitControls;

  @ViewChild('canvas') private canvasRef: ElementRef;
  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  get width() { return this.canvas.clientWidth; }
  get height() { return this.canvas.clientHeight; }
  get aspectRatio() { return this.width / this.height; }

  get center() { return this.pointAnalysis?.metrics?.metrics.avg ?? { x: 0, y: 0, z: 0 }; }
  get sceneWidth() { return this.pointAnalysis?.metrics?.metrics.width ?? 0; }
  get sceneHeight() { return this.pointAnalysis?.metrics?.metrics.height ?? 0; }
  get sceneDepth() { return this.pointAnalysis?.metrics?.metrics.depth ?? 0; }
  //get frustumSize() { return Math.max(this.sceneWidth, this.sceneHeight, this.sceneDepth); }

  constructor(private route: ActivatedRoute, private readonly motionData: MotionDataService) {

  }

  ngOnInit(): void {
    // URL parameters have precedence over the input properties
    const paramCyclistID = this.route.snapshot.paramMap.get('cyclistID');
    if(paramCyclistID) {
      console.log("[Motion3DComponent] Cyclist ID provided as URL parameter: " + paramCyclistID);
      this.cyclistID = paramCyclistID;
    }

    const paramRecordingID = this.route.snapshot.paramMap.get('recordingID');
    if(paramRecordingID) {
      console.log("[Motion3DComponent] Recording ID provided as URL parameter: " + paramRecordingID);
      this.recordingID = paramRecordingID;
    }

    if(this.cyclistID && this.recordingID) {
      this.setupRecording();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.debug("[Motion3DComponent] changes: ", changes);

    let needsReload = false;

    if(changes.cyclistID?.currentValue != changes.cyclistID?.previousValue) {
      console.debug("[Motion3DComponent] Provided cycling ID changed from " + changes.cyclistID.previousValue + " to " + changes.cyclistID.currentValue);
      needsReload = true;
    }

    if(changes.recordingID?.currentValue != changes.recordingID?.previousValue) {
      console.debug("[Motion3DComponent] Provided recording ID changed from " + changes.recordingID.previousValue + " to " + changes.recordingID.currentValue);
      needsReload = true;
    }

    if(needsReload) {
      this.setupRecording();
    }
  }

  ngAfterViewInit() {
    this.createScene();
    this.startRenderingLoop();
  }

  /////

  setLeftView() {
    const center = this.bboxCenter;
    const depth = this.bboxDepth;
    const height = this.bboxHeight;
    if(!center || height <= 0 || depth <= 0) {
      return;
    }

    this.useOrthoGraphicCamera = true;

    const cam = this.cameraOrtho;
    cam.position.set(center.x - 10, center.y, center.z);
    cam.lookAt(center.x, center.y, center.z);

    const frustumSize = Math.max(height, depth) + this.margin;
    if(frustumSize > 0) {
      cam.left = -frustumSize * this.aspectRatio / 2;
      cam.right = frustumSize * this.aspectRatio / 2;
      cam.top = frustumSize / 2;
      cam.bottom = -frustumSize / 2;
    }

    cam.updateProjectionMatrix();

    this.perspControls.enabled = false;
    //this.orthoControls.enabled = false; // TEMP We always disable the ortho controls for now
    //this.orthoControls.update();
}

  setTopView() {
    const center = this.bboxCenter;
    const width = this.bboxWidth;
    const depth = this.bboxDepth;
    if(!center || width <= 0 || depth <= 0) {
      return;
    }

    this.useOrthoGraphicCamera = true;

    const cam = this.cameraOrtho;
    cam.position.set(center.x, center.y + 10, center.z);
    cam.lookAt(center.x, center.y, center.z);

    const frustumSize = Math.max(width, depth) + this.margin;
    if(frustumSize > 0) {
      cam.left = -frustumSize * this.aspectRatio / 2;
      cam.right = frustumSize * this.aspectRatio / 2;
      cam.top = frustumSize / 2;
      cam.bottom = -frustumSize / 2;
    }

    cam.updateProjectionMatrix();

    this.perspControls.enabled = false;
    //this.orthoControls.enabled = false; // TEMP We always disable the ortho controls for now
    //this.orthoControls.update();
  }

  setBackView() {
    const center = this.bboxCenter;
    const width = this.bboxWidth;
    const height = this.bboxHeight;
    if(!center || width <= 0 || height <= 0) {
      return;
    }

    this.useOrthoGraphicCamera = true;

    const cam = this.cameraOrtho;
    cam.position.set(center.x, center.y, center.z + 10);
    cam.lookAt(center.x, center.y, center.z);

    const frustumSize = Math.max(width, height) + this.margin;
    if(frustumSize > 0) {
      cam.left = -frustumSize * this.aspectRatio / 2;
      cam.right = frustumSize * this.aspectRatio / 2;
      cam.top = frustumSize / 2;
      cam.bottom = -frustumSize / 2;
    }

    cam.updateProjectionMatrix();

    this.perspControls.enabled = false;
    //this.orthoControls.enabled = false;
    //this.orthoControls.update();
  }

  setPerspectiveView() {
    const center = this.bboxCenter;
    if(!center) {
      return;
    }

    this.useOrthoGraphicCamera = false;

    const cam = this.cameraPersp;
    cam.position.set(75, 75, 75);
    cam.lookAt(center.x, center.y, center.z);
    cam.updateProjectionMatrix();

    this.perspControls.enabled = true;
    //this.orthoControls.enabled = false;
    //this.perspControls.update();
  }

  /////

  private createScene() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(this.clearColor);

    // Camera
    this.cameraPersp = new THREE.PerspectiveCamera(
      this.fieldOfView,
      this.aspectRatio,
      this.nearClippingPlane,
      this.farClippingPlane
    );
    this.cameraPersp.position.copy(this.cameraPosition);

    this.cameraOrtho = new THREE.OrthographicCamera();

    /*window.addEventListener('resize', () => {
      this.handleResize();
    });*/
  }

  private startRenderingLoop() {
    //* Renderer
    // Use canvas element in template
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, alpha: false });
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    // 3D body (line rendering needs resolution)
    this.body3D?.setResolution(this.canvas.clientWidth, this.canvas.clientHeight);

    // Orbit controls
    this.perspControls = new OrbitControls(this.cameraPersp, this.renderer.domElement);
    this.perspControls.target.copy(this.cameraFocus);
    this.perspControls.enableDamping = true;
    this.perspControls.minDistance = 10;
    this.perspControls.update();

    // Drag controls
    /*this.orthoControls = new OrbitControls(this.cameraOrtho, this.renderer.domElement);
    this.orthoControls.target.copy(this.cameraFocus);
    this.orthoControls.enableRotate = false;
    this.orthoControls.minZoom = 0.5;
    this.orthoControls.maxZoom = 5;
    this.orthoControls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN
    };
    this.orthoControls.touches = {
      ONE: THREE.TOUCH.PAN
    };
    this.orthoControls.update();*/

    //this.handleResize();

    const t = this;
    function render(time: DOMHighResTimeStamp) {
      t.renderScene(time);
      requestAnimationFrame(render);
    };
    requestAnimationFrame(render);
  }

  private renderScene(time: DOMHighResTimeStamp) {
    this.tick(time);

    // We use animated movement in the controls
    this.perspControls.update();
    //this.orthoControls.update();

    this.renderer.setClearColor(this.clearColor, 0);
    this.renderer.clear(true, true);
    this.renderer.render(this.scene, this.camera);
  }

  private tick(time: DOMHighResTimeStamp) {
    const timeS = time * 0.001; // ms to s
    if(!this.body3D || !this.recordingData || this.recordingData.duration <= 0) {
      return;
    }

    // Animation reset?
    if(this.animStartTime < 0) {
        this.animStartTime = timeS;
        this.prevTime = timeS;
        console.log("Animation start time set to ", timeS);
    }

    const startTime = this.recordingData.startTime;
    if(this.prevAnimTime < 0) {
      this.prevAnimTime = startTime;
    }

    const deltaTime = timeS - this.prevTime;
    const scale = this.slowMotion ? this.slowMotionScale : 1;
    const newAnimTime = this.prevAnimTime + scale * deltaTime;

    this.body3D.setAnimationTime(newAnimTime);

    this.prevTime = timeS;
    this.prevAnimTime = newAnimTime;
  }

  private handleResize() {
    console.log("Handling resize");

    this.renderer.setSize(this.width, this.height);
    this.body3D?.setResolution(this.width, this.height);

    this.cameraPersp.aspect = 0.5 * this.aspectRatio;
    this.cameraPersp.updateProjectionMatrix();

    const frustumSize = this.sceneHeight;
    this.cameraOrtho.left = - 0.5 * frustumSize * this.aspectRatio / 2;
    this.cameraOrtho.right = 0.5 * frustumSize * this.aspectRatio / 2;
    this.cameraOrtho.top = frustumSize / 2;
    this.cameraOrtho.bottom = - frustumSize / 2;
    this.cameraOrtho.updateProjectionMatrix();
  }

  /////

  private _prevCyclistID: string|null = null;
  private _prevRecordingID: string|null = null;

  private setupRecording() {
    if(this.cyclistID && this.recordingID) {
      const sameRecording = (this.cyclistID == this._prevCyclistID && this.recordingID == this._prevRecordingID);
      if(sameRecording) {
        console.debug("Setting same recording. Ignoring.")
        return;
      }

      this.recording$ = this.motionData.getRecordingInfo(this.cyclistID, this.recordingID);
      this.recordingData$ = this.motionData.getData(this.cyclistID, this.recordingID);
      this.recordingData$.subscribe(data => {
        this.recordingData = data;
        this.pointAnalysis = new PointAnalysis(this.recordingData);

        console.log("[Motion3DComponent] Recording data loaded. Recording duration: " + this.recordingData.duration + "s");

        // Remove old body
        if(this.body3D) {
          this.scene.remove(this.body3D);
        }

        this.body3D = new MotionBody3D(this.pointAnalysis);
        // We need resolution to draw fat lines with a width in pixels
        this.body3D.setResolution(this.canvas.clientWidth, this.canvas.clientHeight);
        this.scene.add(this.body3D);
      });

      this._prevCyclistID = this.cyclistID;
      this._prevRecordingID = this.recordingID;
    } else {
      this.recording$ = of(undefined);

      // Remove old body
      if(this.body3D) {
        this.scene.remove(this.body3D);
      }

      this.body3D = null;
      this._prevCyclistID = null;
      this._prevRecordingID = null;
    }
  }
}
