import { Component, Input, OnInit } from '@angular/core';
import { BikeAnalysis } from '../services/bike-analysis';

@Component({
  selector: 'motion-bike-pads',
  templateUrl: './bike-pads.component.html',
  styleUrls: ['./bike-pads.component.scss']
})
export class BikePadsComponent implements OnInit {

  @Input() bikeAnalysis: BikeAnalysis | null = null;

  // Display a length value in millimeters
  public lengthMM(value?: number) {
    if(value) {
      return (value * 1000).toFixed();
    } else {
      return "/";
    }
  }

  public diffMM(value?: number) {
    if(value) {
      return (value * 1000).toFixed() + " (L" + (value >= 0 ? "↗" : "↘") + "R)";
    } else {
      return "/";
    }
  }

  public displayAngle(angle?: number) {
    if(angle) {
      return (angle).toFixed(1);
    } else {
      return "/";
    }
  }

  /////

  constructor() {

  }

  ngOnInit(): void {
  }
}
