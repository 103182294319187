import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, PRIMARY_OUTLET, Router, RouterModule } from '@angular/router';
import { BrmCloudService } from '@shared/lib/brm-cloud.service';
import { SharedModule } from '@shared/public-api';
import { filter, map, of } from 'rxjs';
import { MotionDataService } from '../services/motion-data.service';
import { MotionBreadcrumb } from './motion-breadcrumb.interface';

@Component({
  //standalone: true,
  //imports: [ CommonModule, MatListModule, RouterModule ],
  selector: 'motion-breadcrumb',
  templateUrl: 'motion-breadcrumb.component.html',
  styleUrls: ['motion-breadcrumb.component.css']
})
export class MotionBreadcrumbComponent implements OnInit {

  breadcrumbs: MotionBreadcrumb[] = [];
  activeRoute: ActivatedRouteSnapshot | undefined;

  @Input() bgColor = '#eee';
  @Input() fontSize = '18px';
  @Input() fontColor = '#0275d8';
  @Input() lastLinkColor = '#000';
  @Input() symbol = ' / ';

  constructor(private router: Router, private route: ActivatedRoute, private brmCloud: BrmCloudService, private motionData: MotionDataService) {
  }

  ngOnInit() {
    this.generateBreadcrumbs();

    // Detect changes
    this.motionData.activeCyclistChanged.subscribe((activeCyclist) => {
      console.log("[MotionBreadcrumb] Active cyclist changed: ", activeCyclist);
      this.generateBreadcrumbs();
    });

    this.motionData.activeRecordingChanged.subscribe((activeRecording) => {
      console.log("[MotionBreadcrumb] Active recording changed: ", activeRecording);
      this.generateBreadcrumbs();
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        this.activeRoute = route.snapshot;
        this.generateBreadcrumbs();
      });

    // TODO Detect changes
    // TEMP TEST
    //window.setInterval(this.generateBreadcrumbs, 1000);
  }

  generateBreadcrumbs() {
    // Clear the current breadcrumbs
    this.breadcrumbs = [];

    // Home
    this.breadcrumbs.push({
      "label": "🏠",
      "url": "/"
    });
    const hasActiveCyclist = (this.motionData?.activeCyclist != undefined);
    const hasActiveRecording = (this.motionData?.activeRecording != undefined);

    console.log("[MotionBreadcrumb generateBreadcrumbs] Motion data:", this.motionData);
    console.log("[MotionBreadcrumb generateBreadcrumbs] Active cyclist:", this.motionData?.activeCyclist);
    console.log("[MotionBreadcrumb generateBreadcrumbs] Active recording:", this.motionData?.activeRecording);

    // Active cyclist (only changeable for operators)
    if(hasActiveCyclist) {
      this.breadcrumbs.push({
        "label": "Cyclists",
        "url": "/cyclists"
      });

      let item: MotionBreadcrumb = {
        "label": this.brmCloud.fullName(this.motionData.activeCyclist),
        //"url": `/cyclist/${this.motionData.activeCyclist?.userID}`
        //"url": this.brmCloud.isOperator ? "/cyclists" : ""
        "url": `/cyclist/${this.motionData.activeCyclist?.userID}/motion-recordings`
      };

      // Add drop down for the last breadcrum item
      /*if(!hasActiveRecording) {
        item.dropdownItems = [
          { "label": "TEST 1", url: "/test1" },
          { "label": "TEST 2", url: "/test2" },
          { "label": "TEST 3", url: "/test3" }
        ];
      }*/

      this.breadcrumbs.push(item);
    }

    // Active recording
    if(hasActiveRecording) {
      let item: MotionBreadcrumb = {
        "label": this.motionData.activeRecording!.name,
        // "url": `/cyclist/${this.motionData.activeCyclist?.userID}/motion-recording/${this.motionData.activeRecording?.recordingID}`
        //"url": `/cyclist/${this.motionData.activeCyclist?.userID}/motion-recordings`
        "url": ""
      };

      // Add drop down for the last breadcrum item
      /*item.dropdownItems = [
        { "label": "RECTEST 1", url: "/rectest1" },
        { "label": "RECTEST 2", url: "/rectest2" },
        { "label": "RECTEST 3", url: "/rectest3" }
      ];*/

      this.breadcrumbs.push(item);
    }

    // Current Navigation item for all other cases
    if(!hasActiveCyclist && !hasActiveRecording && this.activeRoute) {
      this.breadcrumbs.push({
        "label": this.activeRoute.title || "",
        "url": this.activeRoute.url.join('')
        //url: ""
      });
    }

  }
}
