<div id="root" *ngIf="brmCloud.loggedOnUser$ | async; else notLoggedOn" fxLayout="column" fxFlexFill>
  <div fxLayout="column" fxFlex>
    <h1 *ngIf="!cyclist"><em>No cyclist selected</em></h1>
    <h1 *ngIf="cyclist">{{ brmCloud.fullName(cyclist) }}</h1>
    <div class="recordings" fxFlex>
      <motion-recording-list *ngIf="cyclistID" [cyclistID]="cyclistID" (recordingSelected)="handleRecordingSelected($event)"></motion-recording-list>
    </div>
  </div>
</div>

<ng-template #notLoggedOn>
  <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> to see your data.
</ng-template>
