import { TrackingPoint, Vector2, Vector3 } from '../services/motion-data';
import { PointAnalysis, PointMetrics } from '../services/point-analysis';
import { AngleMetrics, MotionAnalysis } from '../services/motion-analysis';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'brm-cycling-knee-info',
  templateUrl: './cycling-knee-info.component.html',
  styleUrls: ['./cycling-knee-info.component.scss']
})
export class CyclingKneeInfoComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() motionAnalysis: MotionAnalysis | null = null;

  leftKneePointMetrics: PointMetrics | null = null;
  rightKneePointMetrics: PointMetrics | null = null;

  leftKneeAngleMetrics: AngleMetrics | null = null;
  rightKneeAngleMetrics: AngleMetrics | null = null;

  // Add TrackingPoint class so we can use in our html
  TrackingPoint = TrackingPoint;

  /////

  constructor() {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.debug("[CyclingKneeInfoComponent] changes: ", changes);

    let needsReload = false;

    if(changes.motionAnalysis) {
      needsReload = true;
    }

    if(needsReload) {
      this.refreshData();
    }
  }

  /////

  refreshData() {
    console.log("Refreshing knee info data");

    if(this.motionAnalysis) {
      this.leftKneePointMetrics = this.motionAnalysis.pointAnalysis.metrics.pointMetrics[TrackingPoint.LeftKnee];
      this.rightKneePointMetrics = this.motionAnalysis.pointAnalysis.metrics.pointMetrics[TrackingPoint.RightKnee];

      // Note: angles from side view
      this.leftKneeAngleMetrics = this.motionAnalysis.bodyAngleMetrics[TrackingPoint.LeftKnee].x;
      this.rightKneeAngleMetrics = this.motionAnalysis.bodyAngleMetrics[TrackingPoint.RightKnee].x;
    } else {
      this.leftKneePointMetrics = null;
      this.rightKneePointMetrics = null;

      this.leftKneeAngleMetrics = null;
      this.rightKneeAngleMetrics = null;
    }
  }

}
