import { MotionData, Quaternion, RecordingPointData, TrackingPoint, Vector3, Vector2 } from "./motion-data";

export interface PointMetrics {
  boundsMin: Vector3;
  boundsMinFrame: Vector3;
  boundsMax: Vector3;
  boundsMaxFrame: Vector3;

  width: number;
  height: number;
  depth: number;
  avg: Vector3;

  startFrameIdx?: number;
  endFrameIdx?: number;
  numPoints: number;
}

export interface RecordingMetrics {
  pointMetrics: { [pointType: number]: PointMetrics };
  // Note: frame indices are not relevant in the total metrics
  metrics: PointMetrics;
}

export class PointAnalysis {
  metrics: RecordingMetrics;

  constructor(public readonly data: MotionData) {
    this.runAnalysis();
  }

  /////

  runAnalysis() {
    const pointData = this.data.data['Point'] as RecordingPointData;
    const points = pointData.pointFitting.pointTypeToID;

    console.log("Transforming with rotation " + JSON.stringify(pointData.staticRotation) + " and translation " + JSON.stringify(pointData.staticTranslation));

    this.metrics = {
      pointMetrics: {},
      metrics: {
        boundsMin: { x: 0, y: 0, z: 0 },
        boundsMinFrame: { x: 0, y: 0, z: 0 },
        boundsMax: { x: 0, y: 0, z: 0 },
        boundsMaxFrame: { x: 0, y: 0, z: 0 },
        width: 0,
        height: 0,
        depth: 0,
        avg: { x: 0, y: 0, z: 0 },

        startFrameIdx: 0,
        endFrameIdx: 0,
        numPoints: 0
      }
    };

    let first = true;
    let numPoints: number = 0;

    for(const pointType in points) {
      const m = this.calcMetrics(Number.parseInt(pointType));
      if(m) {
        this.metrics.pointMetrics[pointType] = m;

        if(first) {
          this.metrics.metrics.boundsMin = m.boundsMin;
          this.metrics.metrics.boundsMinFrame = m.boundsMinFrame;
          this.metrics.metrics.boundsMax = m.boundsMax;
          this.metrics.metrics.boundsMaxFrame = m.boundsMaxFrame;
        } else {
          this.metrics.metrics.boundsMin.x = Math.min(this.metrics.metrics.boundsMin.x, m.boundsMin.x);
          this.metrics.metrics.boundsMin.y = Math.min(this.metrics.metrics.boundsMin.y, m.boundsMin.y);
          this.metrics.metrics.boundsMin.z = Math.min(this.metrics.metrics.boundsMin.z, m.boundsMin.z);

          this.metrics.metrics.boundsMax.x = Math.max(this.metrics.metrics.boundsMax.x, m.boundsMax.x);
          this.metrics.metrics.boundsMax.y = Math.max(this.metrics.metrics.boundsMax.y, m.boundsMax.y);
          this.metrics.metrics.boundsMax.z = Math.max(this.metrics.metrics.boundsMax.z, m.boundsMax.z);
        }

        this.metrics.metrics.avg.x += m.avg.x;
        this.metrics.metrics.avg.y += m.avg.y;
        this.metrics.metrics.avg.z += m.avg.z;

        first = false;
        ++numPoints;
      }
    }

    this.metrics.metrics.width = Math.abs(this.metrics.metrics.boundsMax.x - this.metrics.metrics.boundsMin.x);
    this.metrics.metrics.height = Math.abs(this.metrics.metrics.boundsMax.y - this.metrics.metrics.boundsMin.y);
    this.metrics.metrics.depth = Math.abs(this.metrics.metrics.boundsMax.z - this.metrics.metrics.boundsMin.z);

    if(numPoints > 0) {
      this.metrics.metrics.avg.x /= numPoints;
      this.metrics.metrics.avg.y /= numPoints;
      this.metrics.metrics.avg.z /= numPoints;
    }
  }

  static transformedPosition(pos: Vector3, rotation: Quaternion, translation: Vector3): Vector3 {
    // Note: we copy the values, otherwise we would change pos
    let result: Vector3 = { x: pos.x, y: pos.y, z: pos.z };

    // Offset
    result.x += translation.x;
    result.y += translation.y;
    result.z += translation.z;

    // Rotation
    result = this.rotatedPosition(result, rotation);

    return result
  }

  static rotatedPosition(pos: Vector3, rotation: Quaternion): Vector3 {
    // Vector
    const x:number = pos.x;
    const y:number = pos.y;
    const z:number = pos.z;

    // Quaternion
    const qx:number = rotation.x;
    const qy:number = rotation.y;
    const qz:number = rotation.z;
    const qw:number = rotation.scalar;

    // Quaternion * Vector
    const ix:number =  qw * x + qy * z - qz * y;
    const iy:number =  qw * y + qz * x - qx * z;
    const iz:number =  qw * z + qx * y - qy * x;
    const iw:number = -qx * x - qy * y - qz * z;

    // Final Quaternion * Vector = Result
    var result: Vector3 = {x: 0, y: 0, z: 0};
    result.x = ix * qw + iw * -qx + iy * -qz - iz * -qy;
    result.y = iy * qw + iw * -qy + iz * -qx - ix * -qz;
    result.z = iz * qw + iw * -qz + ix * -qy - iy * -qx;
    return result;
  }

  get endFrameIndex() {
    const pointData = this.data.data['Point'] as RecordingPointData;
    if(!pointData) {
      return undefined;
    }

    return pointData.frameData.length;
  }

  iterateAllTransformedPositions(pointType: number, onPoint: Function) {
    const pointData = this.data.data['Point'] as RecordingPointData;
    if(!pointData) {
      return;
    }

    const startFrameIdx = 0;
    const endFrameIdx = pointData.frameData.length;
    this.iterateTransformedPositions(pointType, startFrameIdx, endFrameIdx, onPoint);
  }

  iterateTransformedPositions(pointType: number, startFrameIdx: number|undefined, endFrameIdx: number|undefined, onPoint: Function) {
    const pointData = this.data.data['Point'] as RecordingPointData;
    if(!pointData) {
      return;
    }

    const pointID = pointData.pointFitting.pointTypeToID[pointType];
    if(startFrameIdx == undefined) {
      startFrameIdx = 0;
    }
    if(endFrameIdx == undefined) {
      endFrameIdx = pointData.frameData.length;
    }

    //console.log("Iterating over transformed positions for " + pointType + " - ID " + pointID);
    //console.log("Frames " + startFrameIdx + " to " + endFrameIdx);

    if(startFrameIdx < 0 || endFrameIdx > pointData.frameData.length) {
      console.warn("Error: invalid start or end frame index");
      return;
    }

    // We only support TransformUsingStaticBicycleFrame
    if(pointData.transformMode != 1) {
      console.warn("Error: we don't support other transformation modes than TransformUsingStaticBicycleFrame");
      return;
    }

    for(let frameIdx = startFrameIdx; frameIdx < endFrameIdx; ++frameIdx) {
      const frame = pointData.frameData[frameIdx];
      for(const p of frame.points) {
        if(p.pointID == pointID) {
          const pos = PointAnalysis.transformedPosition(p.position, pointData.staticRotation, pointData.staticTranslation);
          //const pos = p.position;

          if(!onPoint(frameIdx, pos)) {
            console.log("Canceling iteration of transformed positions of point " + pointType);
            return;
          }

          break;
        }
      }
    }
  }

  calcAllTransformedPositions(frameIdx: number) {
    const pointData = this.data.data['Point'] as RecordingPointData;
    if(!pointData) {
      return [];
    }

    if(frameIdx < 0 || frameIdx > pointData.frameData.length) {
      console.warn("Error: invalid frame index");
      return [];
    }

    // We only support TransformUsingStaticBicycleFrame
    if(pointData.transformMode != 1) {
      console.warn("Error: we don't support other transformation modes than TransformUsingStaticBicycleFrame");
      return [];
    }

    const result: Vector3[] = [];

    const frame = pointData.frameData[frameIdx];
    for(const p of frame.points) {
      const pos = PointAnalysis.transformedPosition(p.position, pointData.staticRotation, pointData.staticTranslation);
      result.push(pos);
    }

    return result;
  }

  calcTransformedPositionWithID(pointID: number, frameIdx: number) {
    const pointData = this.data.data['Point'] as RecordingPointData;
    if(!pointData) {
      return null;
    }

    if(pointID < 0) {
      console.warn("Error: invalid point ID");
      return null;
    }

    if(frameIdx < 0 || frameIdx > pointData.frameData.length) {
      console.warn("Error: invalid frame index");
      return null;
    }

    // We only support TransformUsingStaticBicycleFrame
    if(pointData.transformMode != 1) {
      console.warn("Error: we don't support other transformation modes than TransformUsingStaticBicycleFrame");
      return null;
    }

    const frame = pointData.frameData[frameIdx];
    for(const p of frame.points) {
      if(p.pointID == pointID) {
        const pos = PointAnalysis.transformedPosition(p.position, pointData.staticRotation, pointData.staticTranslation);
        return pos;
      }
    }

    return null;
  }

  calcTransformedPosition(pointType: number, frameIdx: number) {
    const pointData = this.data.data['Point'] as RecordingPointData;
    if(!pointData) {
      return null;
    }

    const pointID = pointData.pointFitting.pointTypeToID[pointType];
    return this.calcTransformedPositionWithID(pointID, frameIdx);
  }

  calcTransformedMidpoint(pointTypes: Array<number>, frameIdx: number): Vector3|undefined {
    let midpoint = undefined;

    for(let pointType of pointTypes) {
      const pos = this.calcTransformedPosition(pointType, frameIdx);
      if(pos == undefined) {
        // We only calculate a midpoint when all points have a position
        midpoint = undefined;
        break;
      }

      if(midpoint == undefined) {
        midpoint = {x: pos.x, y: pos.y, z: pos.z};
      } else {
        midpoint.x += pos.x;
        midpoint.y += pos.y;
        midpoint.z += pos.z;
      }
    }

    if(midpoint != undefined) {
      midpoint.x /= pointTypes.length;
      midpoint.y /= pointTypes.length;
      midpoint.z /= pointTypes.length;
    }

    return midpoint;
  }

  calcMetrics(pointType: number, startFrameIdx?: number, endFrameIdx?: number) {
    const m = {
      boundsMin: {x: 0, y: 0, z: 0},
      boundsMinFrame: {x: 0, y: 0, z: 0},
      boundsMax: {x: 0, y: 0, z: 0},
      boundsMaxFrame: {x: 0, y: 0, z: 0},
      width: 0,
      height: 0,
      depth: 0,
      avg: {x: 0, y: 0, z: 0},

      startFrameIdx: startFrameIdx,
      endFrameIdx: endFrameIdx,
      numPoints: 0
    };

    let first = true;
    let numPoints: number = 0;

    this.iterateTransformedPositions(pointType, startFrameIdx, endFrameIdx, (frameIdx: number, pos: Vector3) => {
      ++numPoints;

      if(first || pos.x < m.boundsMin.x) {
        m.boundsMin.x = pos.x;
        m.boundsMinFrame.x = frameIdx;
      }
      if(first || pos.y < m.boundsMin.y) {
        m.boundsMin.y = pos.y;
        m.boundsMinFrame.y = frameIdx;
      }
      if(first || pos.z < m.boundsMin.z) {
        m.boundsMin.z = pos.z;
        m.boundsMinFrame.z = frameIdx;
      }

      if(first || pos.x > m.boundsMax.x) {
        m.boundsMax.x = pos.x;
        m.boundsMaxFrame.x = frameIdx;
      }
      if(first || pos.y > m.boundsMax.y) {
        m.boundsMax.y = pos.y;
        m.boundsMaxFrame.y = frameIdx;
      }
      if(first || pos.z > m.boundsMax.z) {
        m.boundsMax.z = pos.z;
        m.boundsMaxFrame.z = frameIdx;
      }

      m.avg.x += pos.x;
      m.avg.y += pos.y;
      m.avg.z += pos.z;

      first = false;

      // Continue with next point
      return true;
    });

    m.numPoints = numPoints;

    m.width = Math.abs(m.boundsMax.x - m.boundsMin.x);
    m.height = Math.abs(m.boundsMax.y - m.boundsMin.y);
    m.depth = Math.abs(m.boundsMax.z - m.boundsMin.z);

    if(numPoints > 0) {
      m.avg.x /= numPoints;
      m.avg.y /= numPoints;
      m.avg.z /= numPoints;
    }

    //console.log(`avg pos of ${TrackingPoint.pointName(pointType)}: ${m.avg} (${numPoints} point(s))`);

    return m;
  }

}

///// Utilities /////

export function getLeftProjectedPos(pos: Vector3) {
  return { x: pos.z, y: pos.y };
}

export function getDistance(pos1: Vector2, pos2: Vector2) {
  return Math.sqrt((pos2.x - pos1.x) * (pos2.x - pos1.x) + (pos2.y - pos1.y) * (pos2.y - pos1.y));
}

export function radToDeg(rad: number) {
  return rad * (180.0 / Math.PI);
}
