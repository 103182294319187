import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';


@Component({
  selector: 'brm-dev-test',
  templateUrl: './dev-test.component.html',
  styleUrls: ['./dev-test.component.scss']
})
export class DevTestComponent implements OnInit, OnDestroy {

  @Input() title : string = "Bioracer Motion"
  @Input() logo : string = "assets/motion-logo-rounded.png";

  items: Observable<any[]>;
  testRecordingURL: Observable<any>;

  claimsType: string = "admin";
  testClaimsResult: object|false;


  constructor( public readonly brmCloud: BrmCloudService, private firestore: AngularFirestore, private storage: AngularFireStorage ) {
    //console.log("firestore", firestore);
  }

  ngOnInit(): void {
    console.log("DevTestComponent - ngOnInit")

    this.brmCloud.loggedOnUser$.subscribe(info => {
      if(info) {
        this.items = this.firestore.collection(`motionUserData/${info.userID}/recordings`).valueChanges({ idField: 'recordingID' });

        // TEMP TEST Download the frst recording
        this.items.subscribe(recordings => {
          const rec = recordings[0];
          console.log("First recording: ", rec);

          const cyclistID = info.userID;
          const recID = rec.recordingID;
          const ref = this.storage.ref(`/motion/userData/${cyclistID}/recordings/${recID}.brr.data`);
          this.testRecordingURL = ref.getDownloadURL();
        });
      } else {
        this.items = of([ ]);
      }
    });
  }

  ngOnDestroy(): void {
    console.log("DevTestComponent - ngOnDestroy")
  }

  testClaims(rightsType: string) {
    this.testClaimsResult = this.brmCloud.hasAccess(rightsType);
    console.log("Test claims result: ", this.testClaimsResult);
  }

}
