<!--<h2 mat-dialog-title>Log on to the Bioracer Motion cloud</h2>-->
<h2>Log on to the Bioracer Motion cloud</h2>

<div mat-dialog-content class="mat-typography">
  <form>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput [(ngModel)]="formData.email" [ngModelOptions]="{standalone: true}" type="email" placeHolder="Email address" size="50" required>
      <mat-icon matSuffix>email</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput [(ngModel)]="formData.password" [ngModelOptions]="{standalone: true}" [type]="hidePassword ? 'password' : 'text'" size="50" required>
      <!-- We need to explicitly set the type to prevent the enter key pressing this button -->
      <button mat-icon-button matSuffix type="button" (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
  </form>

  <button mat-button (click)="resetPassword()" [disabled]="!formData.email.length">Reset password</button>
  <brm-card id="error" *ngIf="error" [warning]="true">{{error}}</brm-card>
</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="login()" [disabled]="loggingOn" cdkFocusInitial>{{ loggingOn ? 'Logging on...' : 'Log on'}}</button>
</mat-dialog-actions>
