import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, map } from 'rxjs';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { MotionDataService } from '../services/motion-data.service';
import { MotionData } from '../services/motion-data';
import { BikeAnalysis } from '../services/bike-analysis';
import { MotionRecordingInfo } from '../services/motion-recording-info.interface';
import { UserDocument } from '@shared/lib/user-document.interface';

@Component({
  selector: 'motion-recording-report-header',
  templateUrl: './recording-report-header.component.html',
  styleUrls: ['./recording-report-header.component.scss']
})
export class RecordingReportHeaderComponent implements OnInit {

  @Input() recording: MotionRecordingInfo | null = null;
  cyclistInfo?: UserDocument;
  operatorInfo?: UserDocument;

  get isBikeRecording() { return this.recording?.recordingType == 'bike'; }

  // Note: road bike is used when not specific type is set
  get isRoadBike() { return this.recording?.equipment.bicycleType == 'roadBike' || this.recording?.equipment.bicycleType == 'racing' || (!this.isTTBike && !this.isMountainBike) }
  get isTTBike() { return this.recording?.equipment.bicycleType == 'ttBike'; }
  get isMountainBike() { return this.recording?.equipment.bicycleType == 'mountainBike'; }

  // Display a length value in millimeters
  public lengthMM(value?: number) {
    if(value) {
      return (value * 1000).toFixed();
    } else {
      return "/";
    }
  }

  public displayAngle(angle?: number) {
    if(angle) {
      return (angle).toFixed(1);
    } else {
      return "/";
    }
  }

  /////

  constructor(public readonly brmCloud: BrmCloudService) {

  }

  ngOnInit(): void {
    if(this.recording) {
      const cyclistDoc$ = this.brmCloud.userDoc(this.recording.cyclistID);
      cyclistDoc$.subscribe(doc => { this.cyclistInfo = doc; });

      const operatorDoc$ = this.brmCloud.userDoc(this.recording.operatorId);
      operatorDoc$.subscribe(doc => { this.operatorInfo = doc; });
    }
  }
}
