import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'brm-card',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() title: string = "";
  @Input() warning: boolean = false;

  smallScreen: boolean = false;

  constructor(private responsive: BreakpointObserver) {

  }

  ngOnInit(): void {
    this.responsive.observe(Breakpoints.Small)
      .subscribe(result => {

        console.log("Layout breakpoint changed:", result);

        if (result.matches) {
          console.log("screens matches Small");
        }

        this.smallScreen = result.matches;
  });
  }
}
