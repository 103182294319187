<div *ngIf="brmCloud.loggedOnUser$ | async; else notLoggedOn">
<div id="report" *ngIf="recording$ | async as recording; else noAccess">

  <motion-recording-report-header [recording]="recording" fxFlexFill></motion-recording-report-header>

  <brm-card *ngIf="!bikeAnalysis" [warning]="true">
    Could not load recording data.
  </brm-card>

  <motion-bike-markers [bikeAnalysis]="bikeAnalysis" *ngIf="bikeAnalysis"></motion-bike-markers>
  <motion-bike-frame [bikeAnalysis]="bikeAnalysis" *ngIf="bikeAnalysis"></motion-bike-frame>
  <motion-bike-setup [bikeAnalysis]="bikeAnalysis" *ngIf="bikeAnalysis"></motion-bike-setup>
  <motion-bike-hoods [bikeAnalysis]="bikeAnalysis" *ngIf="bikeAnalysis"></motion-bike-hoods>
  <motion-bike-pads [bikeAnalysis]="bikeAnalysis" *ngIf="bikeAnalysis"></motion-bike-pads>
  <motion-bike-shifters [bikeAnalysis]="bikeAnalysis" *ngIf="bikeAnalysis"></motion-bike-shifters>
  <motion-bike-uci-rules [bikeAnalysis]="bikeAnalysis" *ngIf="bikeAnalysis"></motion-bike-uci-rules>
  <motion-body-measurements [recording]="recording" *ngIf="recording.bodyMeasurements"></motion-body-measurements>
  <motion-equipment [recording]="recording" [bikeAnalysis]="bikeAnalysis" *ngIf="recording.equipment"></motion-equipment>

  <brm-card *ngIf="recording.comments" title="Comments">
    <p>{{recording.comments}}</p>
  </brm-card>

</div>
</div>

<ng-template #notLoggedOn>
  <div>

    <h1>Not Logged On</h1>

    <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> with your Bioracer Motion account to see data.<br />

  </div>
</ng-template>

<ng-template #noAccess>
<div>

  <h1>No Access</h1>

  You don't have access to this data.<br />
  <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> with a Bioracer Motion account with the correct permissions to see the data.<br />

</div>
</ng-template>
