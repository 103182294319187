<brm-card *ngIf="bikeAnalysis" [title]="bikeAnalysis.wiredHarness ? 'Wired Markers' : 'Wireless Markers'">
    <!-- Image dependent on bicycle type -->
    <img *ngIf="bikeAnalysis.isRoadBike" src="assets/bike-report/Road Bike Report - Markers.svg" alt="Bike" />
    <img *ngIf="bikeAnalysis.isTTBike" src="assets/bike-report/TT Bike Report - Markers.svg" alt="Bike" />
    <img *ngIf="bikeAnalysis.isMountainBike" src="assets/bike-report/Mountain Bike Report - Markers.svg" alt="Bike" />

    <div class="markers">
      <p><b>1</b>: <span>Bottom bracket (center)</span></p>
      <p><b>2</b>: <span>Sit position (12cm from back of saddle)</span></p>

      <p *ngIf="!bikeAnalysis.isTTBike"><b>3</b>: <span>Handlebar grip</span></p>
      <p *ngIf="!bikeAnalysis.isTTBike"><b>4</b>: <span [class.not-used]="!hasHeadTube">Head tube (center)</span></p>
      <p *ngIf="!bikeAnalysis.isTTBike"><b>5</b>: <span [class.not-used]="!hasHoods">Brake lever hoods <span [class.not-used]="!hasBothHoods">(both sides)</span></span></p>

      <p *ngIf="bikeAnalysis.isTTBike"><b>3</b>: <span [class.not-used]="!hasPads">Elbow pads <span [class.not-used]="!hasBothPads">(both sides)</span></span></p>
      <p *ngIf="bikeAnalysis.isTTBike"><b>4</b>: <span [class.not-used]="!hasShifters">Shifters <span [class.not-used]="!hasBothShifters">(both sides)</span></span></p>

      <br />
      <span class="remark">Note: the markers have a thickness of {{ bikeAnalysis.markerThicknessMM }} mm to the center of their LED. We automatically compensate for this in our calculations, where appropriate.</span>
    </div>
</brm-card>
