<h1>Dev Test</h1>

<brm-motion3d></brm-motion3d>

<!-- Claims Test -->
<input matInput type="text" [(ngModel)]="claimsType" />
&nbsp;
<button mat-raised-button color="primary" (click)="testClaims(claimsType)">Test Claims</button>
<p *ngIf="testClaimsResult !== undefined">
  <span>Test result:</span>
  <code>{{testClaimsResult | json}}</code>
</p>

This is your info:<br />
<pre>{{ brmCloud.userInfo | json}}</pre>
