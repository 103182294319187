<div *ngIf="brmCloud.loggedOnUser$ | async; else notLoggedOn">
  <form>

    <!-- Operator Profile, only for operators -->
    <div id="operatorProfile" *ngIf="brmCloud.isOperator">
      <h1>Service</h1>

      <mat-form-field>
        <mat-label>Company name</mat-label>
        <input matInput [(ngModel)]="company.name" [ngModelOptions]="{standalone: true}" type="text" placeHolder="Company name" size="50">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Address</mat-label>
        <input matInput [(ngModel)]="company.address" [ngModelOptions]="{standalone: true}" type="text" placeHolder="Address" size="50">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Telephone number</mat-label>
        <input matInput [(ngModel)]="company.phone" [ngModelOptions]="{standalone: true}" type="tel" placeHolder="Telephone number" size="50">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="company.email" [ngModelOptions]="{standalone: true}" type="email" placeHolder="Email" size="50">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Website</mat-label>
        <input matInput [(ngModel)]="company.website" [ngModelOptions]="{standalone: true}" type="url" placeHolder="Website URL" size="50">
      </mat-form-field>

      <mat-card class="company-logo" *ngIf="brmCloud.isOperator">
        <mat-card-title>Company Logo</mat-card-title>

        <img *ngIf="company.logoURL"
          [src]="company.logoURL"
          alt="Company Logo"
        />
        <label *ngIf="!company.logoURL" class="no-logo">No logo selected</label>
        <input #fileInput type="file" accept="image/*" (change)="onFileSelected($event)" hidden />
        <div>
          <button mat-icon-button matSuffix color="primary" (click)="fileInput.click()">
            <mat-icon>add_a_photo</mat-icon>
          </button>
          <button *ngIf="company.logoURL" mat-icon-button matSuffix color="primary" (click)="removeCompanyLogo()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-card>

      <h2>Service Location</h2>

        Do you want us to include your service location and info in our
        <em>find a fitter</em> system?<br />

        <mat-slide-toggle [(ngModel)]="serviceLocation!.showLocation" [ngModelOptions]="{standalone: true}">Show my service location in <em>find a fitter</em></mat-slide-toggle>

        <!--
        <div *ngIf="serviceLocation.showLocation">
          <div class="row">
            <mat-form-field>
              <mat-label>Latitude</mat-label>
              <input matInput [(ngModel)]="serviceLocation.latitude" [ngModelOptions]="{standalone: true}" type="number" placeHolder="Latitude" min="-90" max="90" step="0.0001">
            </mat-form-field>

            <mat-form-field>
              <mat-label>Longitude</mat-label>
              <input matInput [(ngModel)]="serviceLocation.longitude" [ngModelOptions]="{standalone: true}" type="number" placeHolder="Longitude" min="-180" max="180" step="0.0001">
            </mat-form-field>

            <button mat-raised-button (click)="getLocation()">Use current location</button>
          </div>

          <p *ngIf="serviceLocation"><a [href]="serviceLocationUrl" target="_blank">Location on Google Maps</a></p>
        </div>
        -->
    </div>

    <h1 *ngIf="brmCloud.isOperator">Operator</h1>
    <h1 *ngIf="!brmCloud.isOperator">User Profile</h1>

    <div class="row">
      <mat-form-field>
        <mat-label>First name</mat-label>
        <input matInput [(ngModel)]="profile.firstName" [ngModelOptions]="{standalone: true}" type="text" placeHolder="First name" size="50" required>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last name</mat-label>
        <input matInput [(ngModel)]="profile.lastName" [ngModelOptions]="{standalone: true}" type="text" placeHolder="Last name" size="50" required>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>Body Height (cm)</mat-label>
        <input matInput [(ngModel)]="profile.bodyHeight" [ngModelOptions]="{standalone: true}" type="number" placeHolder="Body Height (cm)" min="100" max="250" step="0.1">
        <!--<mat-hint align="start">Your body height is used is some calculations, the UCI rules for a time trial bike setup for example.</mat-hint>-->
      </mat-form-field>

      <mat-form-field>
        <mat-label>Body Weight (kg)</mat-label>
        <input matInput [(ngModel)]="profile.bodyWeight" [ngModelOptions]="{standalone: true}" type="number" placeHolder="Body Weight (kg)" min="20" max="200" step="0.1">
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>Gender</mat-label>
        <mat-select [(ngModel)]="profile.gender" [ngModelOptions]="{standalone: true}">
          <mat-option value="male">Male</mat-option>
          <mat-option value="female">Female</mat-option>
          <mat-option value="other">Other</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="birthday" appearance="outline">
        <mat-label>Birthday:</mat-label>
        <input matInput [matDatepicker]="birthdayPicker" [(ngModel)]="profile.birthdayDate" [ngModelOptions]="{standalone: true}">
        <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
        <mat-datepicker #birthdayPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>

    <button mat-raised-button color="primary" (click)="saveProfile()" [disabled]="savingProfile" cdkFocusInitial>{{savingProfile ? "Saving user profile..." : "Save User Profile"}}</button>

  </form>
</div>

<ng-template #notLoggedOn>
  <h1>User Profile</h1>
  <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> to edit your user profile.
</ng-template>
