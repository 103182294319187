import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, map } from 'rxjs';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { MotionDataService } from '../services/motion-data.service';
import { MotionData, TrackingPoint } from '../services/motion-data';
import { MotionAnalysis } from '../services/motion-analysis';
import { MotionRecordingInfo } from '../services/motion-recording-info.interface';
import { MotionBody3D } from '../motion3d/motion-body-3d';
import { View, ViewSelectionComponent } from '../view-selection/view-selection.component';
import { Motion3DComponent } from '../motion3d/motion3d.component';
import { MotionPointTraceComponent } from '../motion-point-trace/point-trace.component';

import * as THREE from "three";

@Component({
  selector: 'motion-cycling-analysis',
  templateUrl: './cycling-analysis.component.html',
  styleUrls: ['./cycling-analysis.component.scss']
})
export class CyclingAnalysisComponent implements OnInit {

  @ViewChild(ViewSelectionComponent) viewSelection!: ViewSelectionComponent;
  @ViewChild(Motion3DComponent) motion3D!: Motion3DComponent;
  @ViewChild(MotionPointTraceComponent) pointTrace!: MotionPointTraceComponent;

  // Add TrackingPoint class so we can use in our html
  TrackingPoint = TrackingPoint;

  cyclistID: string|null = null;
  recordingID: string|null = null;

  recording$: Observable<MotionRecordingInfo|undefined>;

  recordingData$: Observable<MotionData>;
  recordingData: MotionData|null = null;

  motionAnalysis: MotionAnalysis|null = null;

  slowMotion: boolean = false;

  constructor(private route: ActivatedRoute, public readonly brmCloud: BrmCloudService, private readonly motionData: MotionDataService, private firestore: AngularFirestore, private storage: AngularFireStorage) {

  }

  ngOnInit(): void {
    this.recording$ = this.route.paramMap.pipe(
      switchMap(params => {
        console.log("Params", params)
        this.cyclistID = params.get('cyclistID');
        this.recordingID = params.get('recordingID');

        return this.motionData.getRecordingInfo(this.cyclistID, this.recordingID);
      })
    );

    this.recording$.subscribe((rec: any) => {
      console.log("subscribed recording next:", rec);

      if(this.cyclistID) {
        this.recordingData$ = this.motionData.getData(this.cyclistID, rec.recordingID);
        this.recordingData$.subscribe(data => {
          this.recordingData = data;

          console.log("Cyclist ID: " + this.cyclistID);
          console.log("Motion data: ", this.motionData);
          console.log("Recording data: ", this.recordingData);

          console.log("Recording data loaded. Recording duration: " + this.recordingData.duration + "s");

          // Do motion analysis
          this.motionAnalysis = new MotionAnalysis(rec, this.recordingData);

          const num = this.motionAnalysis.pedalCycles?.length;
          console.log("Cycling recording has " + num + " cycle(s)", this.motionAnalysis.pedalCycles);

          const angles = this.motionAnalysis.frameCrankAngles;
          console.log("Cycling recording has " + angles?.length + " crank angle(s)", angles);

          // Apply current view
          this.applyView(this.viewSelection.projectionAxis);
        });
      }
    });
  }

  /////

  handleViewClicked(view: View) {
    console.log("View clicked: ", view);

    this.applyView(view);
  }

  applyView(view: View) {
    console.log("Applying view: ", view);

    switch(view) {
      case View.Left:
        this.motion3D.setLeftView();
        break;

      case View.Top:
        this.motion3D.setTopView();
        break;

      case View.Back:
        this.motion3D.setBackView();
        break;

      case View.Perspective:
        this.motion3D.setPerspectiveView();
        break;
    }
  }
}
