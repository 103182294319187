<brm-card *ngIf="recording?.equipment as eq" title="Equipment">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2em">
    <div class="content" fxLayout="row" fxLayoutGap="1em">
      <div class="titles">
        <p>Crank length (mm): </p>
        <p>Wheel diameter (mm): </p>
        <p>Seat post angle (°): </p>

        <p>Back of saddle to sit position (mm): </p>
        <p *ngIf="bikeAnalysis?.isTTBike">Saddle sit position to front (mm): </p>
        <p>Saddle compression (mm): </p>
        <p>Insole thickness (mm): </p>
      </div>
      <div class="values">
        <!-- Already in mm -->
        <p>{{eq.crankLength}}</p>
        <p>{{lengthMM(eq.wheelDiameter)}}</p>
        <p>{{displayAngle(eq.seatPostAngle)}}</p>

        <p>{{lengthMM(eq.backOfSaddleToSitPosition)}}</p>
        <p>{{lengthMM(eq.saddleSitPositionToFront)}}</p>
        <!-- Note: already in mm-->
        <p>{{eq.saddleCompression}}</p>
        <p>{{eq.insoleThickness}}</p>
      </div>
      <mat-divider vertical="true"></mat-divider>
      <div class="titles">
        <p>Bicycle: </p>
        <p>Saddle: </p>
        <p>Pedals: </p>
        <p>Shoes: </p>
        <p>Insoles: </p>
      </div>
      <div class="text-values">
        <p>{{brandModel(eq.bicycleBrand, eq.bicycleModel)}}</p>
        <p>{{brandModel(eq.saddleBrand, eq.saddleModel)}}</p>
        <p>{{brandModel(eq.pedalsBrand, eq.pedalsModel)}}</p>
        <p>{{brandModel(eq.shoesBrand, eq.shoesModel)}}</p>
        <p>{{brandModel(eq.insolesBrand, eq.insolesModel)}}</p>
      </div>
    </div>
  </div>
</brm-card>
