import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, map } from 'rxjs';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { MotionDataService } from '../services/motion-data.service';
import { MotionData } from '../services/motion-data';
import { BikeAnalysis } from '../services/bike-analysis';

@Component({
  selector: 'motion-bike-setup',
  templateUrl: './bike-setup.component.html',
  styleUrls: ['./bike-setup.component.scss']
})
export class BikeSetupComponent implements OnInit {

  @Input() bikeAnalysis: BikeAnalysis | null = null;

  // Display a length value in millimeters
  public lengthMM(value?: number) {
    if(value) {
      return (value * 1000).toFixed();
    } else {
      return "/";
    }
  }

  public displayAngle(angle?: number) {
    if(angle) {
      return (angle).toFixed(1);
    } else {
      return "/";
    }
  }

  /////

  constructor() {

  }

  ngOnInit(): void {
  }
}
