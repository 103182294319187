import { Component, OnInit } from '@angular/core';
import { Routes, Router, ActivatedRoute, RouterEvent, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Title } from '@angular/platform-browser';
import  {NavigationItem } from '@shared/components/navigation/NavigationItem.interface'

import { WelcomeComponent } from '../welcome/welcome.component';
import { LoginComponent } from '@shared/components/login/login.component';
import { UserTableComponent } from '@shared/components/user-table/user-table.component';
import { OperatorSessionListComponent } from '@shared/components/operator-session-list/operator-session-list.component';
import { UserSessionListComponent } from '@shared/components/user-session-list/user-session-list.component';
import { DevTestComponent } from '../dev-test/dev-test.component';
import { Motion3DComponent } from '../motion3d/motion3d.component';
import { MotionRecordingListComponent } from '../motion-recording-list/motion-recording-list.component';
import { RecordingInfoComponent } from '../recording-info/recording-info.component';
import { RecordingDetailsBikeComponent } from '../recording-details-bike/recording-details-bike.component';
import { RecordingDetailsCyclingComponent } from '../recording-details-cycling/recording-details-cycling.component';
import { MotionOperatorViewComponent } from '../motion-operator-view/motion-operator-view.component';
import { BrmCloudService } from '@shared/lib/brm-cloud.service';
//import { Test3DComponent } from '../test-3d/test-3d.component';
import { TestLayoutComponent } from '../test-layout/test-layout.component';
import { RecordingDetailsCyclingAdvancedComponent } from '../recording-details-cycling-advanced/recording-details-cycling-advanced.component';
import { UserProfileComponent } from '@shared/components/user-profile/user-profile.component';
import { OperatorCyclistsComponent } from '../operator-cyclists/operator-cyclists.component';
import { MotionDataService } from '../services/motion-data.service';
import { RecordingsViewComponent } from '../recordings-view/recordings-view.component';
import { filter, of, switchMap } from 'rxjs';
import { CyclingAnalysisComponent } from '../cycling-analysis/cycling-analysis.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  get title() : string {
    /*if(this.motionData.activeCyclist) {
      const name = this.brmCloud.fullName(this.motionData.activeCyclist);
      return "My Motion - " + name;
    } else*/ {
      return "My Motion";
    }
  }

  navItems: NavigationItem[] = [
    { route : { title: "Welcome", path: "welcome", component: WelcomeComponent } },
    { route : { title: "User Profile", path: "user-profile", component: UserProfileComponent } },
    //{ route: { title: "Operator Sessions", path: "operator-sessions", component: OperatorSessionListComponent } },
    //{ route: { title: "User Sessions", path: "user-sessions", component: UserSessionListComponent } },
    {
      access: "motion", hideWithoutAccess: true,
      route: {
        title: "Cyclists", path: "cyclists", component: OperatorCyclistsComponent,
        data: { breadcrumb: [ { label: "{{cyclistName}}", url: '' } ] }
      }
    },
    {
      hideWithAccess: "motion",
      route: {
        title: "Recordings",
        path: "motion-recordings",
        component: RecordingsViewComponent,
        data: { breadcrumb: [ { label: "Recordings", url: '' } ] }
      }
    }
  ];

  otherRoutes: Routes = [
    { path: "cyclists", component: OperatorCyclistsComponent,
      canActivate: [this.checkDataAccess.bind(this)],
    },
    { path: "cyclist", redirectTo: "cyclists", pathMatch: "full" },
    { path: "cyclist/:cyclistID", component: UserProfileComponent },

    { path: "cyclist/:cyclistID/motion-recordings", component: RecordingsViewComponent,
      canActivate: [this.checkDataAccess.bind(this)],
    },
    { path: "cyclist/:cyclistID/motion-recording", redirectTo: "motion-recordings", pathMatch: "full" },
    { path: "cyclist/:cyclistID/motion-recording/:recordingID", component: RecordingInfoComponent },
    { path: "cyclist/:cyclistID/motion-recording/:recordingID/details-bike", component: RecordingDetailsBikeComponent },
    { path: "cyclist/:cyclistID/motion-recording/:recordingID/details-cycling", component: RecordingDetailsCyclingComponent },
    { path: "cyclist/:cyclistID/motion-recording/:recordingID/details-cycling-advanced", component: RecordingDetailsCyclingAdvancedComponent },
    { path: "cyclist/:cyclistID/motion-recording/:recordingID/cycling-analysis", component: CyclingAnalysisComponent },

    // Development routes
    { path: "dev-test", component: DevTestComponent },
    //{ path: "test-3d", component: Test3DComponent },
    { path: "test-layout", component: TestLayoutComponent, title: "Layout Test" },
    { path: "user-table-test", component: UserTableComponent },

    // DEPRECATED
    { path: "motion-operator-view", component: MotionOperatorViewComponent },
  ];

  public constructor(private titleService: Title, private router: Router, private route: ActivatedRoute, private brmCloud: BrmCloudService, private motionData: MotionDataService) {
    this.titleService.setTitle(this.title);
  }

  checkDataAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(!this.brmCloud.userID) {
      console.log("No Access!")
      return false;
    }

    console.log("Access!");
    return true;
  }

  ngOnInit() {
    /*console.log(this.router.url);

    // DEBUG TEMP
    this.route.paramMap.subscribe(params => {
      console.log("Route params: ", params);
    });

    this.route.paramMap.subscribe(params => {
      console.log("Router param map:", params);
      const keys = params.keys;
      for(const key of keys) { console.log("Key: ", key); };

      const cyclistID = params.get('cyclistID');
      const recordingID = params.get('recordingID');

      if(cyclistID) {
        console.log("Cyclist ID: ", cyclistID);
      }

      if(recordingID) {
        console.log("Recording ID: ", recordingID);
      }
    });
    this.route.queryParams.subscribe(params => {
      console.log("Route query params: ", params);
    });
    this.route.url.subscribe(url => {
      console.log("Route url: ", url);
    });
    this.route.data.subscribe(data => {
      console.log("Route data: ", data);
    });
    for(const childRoute of this.route.children) {
      console.log("Child route: ", childRoute.snapshot.url);
    }
    let parent = this.route.parent;
    while(parent) {
      console.log("Parent route: ", parent.snapshot.url);
      parent = parent.parent;
    }*/

    ////

    /*const id = this.route.snapshot.paramMap.get('cyclistID');
    console.log("Cyclist ID from snapshot:", id);*/

    /*this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const id = this.route.snapshot.paramMap.get('cyclistID');
        console.log(id);
    });*/

    /*this.router.events.subscribe((eventInfo) => {
      console.log("Router event: ", eventInfo);
      const routerInfo = eventInfo as RouterEvent;
      console.log("Router event URL: ", routerInfo.url);
      console.log("Router URL: ", this.router.url);
      const keys = this.route.snapshot.paramMap.keys;
      console.log(keys);
    });*/
  }
}
