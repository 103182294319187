import { OnInit, OnDestroy, AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, of, switchMap } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { BrmDataService } from '@shared/brm-data.service';
import { UserDocument } from '@shared/lib/user-document.interface';

@Component({
  selector: 'motion-operator-view',
  templateUrl: './motion-operator-view.component.html',
  styleUrls: ['./motion-operator-view.component.scss']
})

// DEPRECATED
export class MotionOperatorViewComponent implements AfterViewInit {
  selectedCyclist: UserDocument | null = null;

  constructor(public readonly brmCloud: BrmCloudService) {

  }

  ngAfterViewInit(): void {
    this.brmCloud.loggedOnUser$.subscribe(userDoc => {
      console.log("User logged on");
    });
  }

  handleCyclistSelected(cyclist: UserDocument) {
    this.selectedCyclist = cyclist;
    console.log("Cyclist selected:", this.selectedCyclist);
  }
}
