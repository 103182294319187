import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore'
import firebase from 'firebase/compat/app';

import { map, Observable, of, switchMap } from 'rxjs';
import { BrmCloudService } from './brm-cloud.service';
import { UserDocument } from './user-document.interface';


@Injectable({
  providedIn: 'root'
})
export class BrmDataService {
  constructor(private readonly brmCloud: BrmCloudService, private readonly firestore: AngularFirestore) {
    // TEMP Enable debugging
    //firebase.setLogLevel('debug');
  }

  operatorSessions(operatorID: string) {
    const collection = this.firestore.collection(`operatorData/${operatorID}/sessions`, ref => ref.orderBy('date', 'desc'));
    return collection.valueChanges({ idField: 'sessionID' });
  }

  userSessions(userEmail: string) {
    const collection = this.firestore.collectionGroup('sessions', ref => ref.where('email', '==', userEmail).orderBy('date', 'desc'));
    return collection.valueChanges({ idField: 'sessionID' });
  }

  operatorCyclists(operatorID: string) {
    let collection;
    if(this.isReadAll()) {
      console.log("Querying all cyclists for read-all account");
      collection = this.firestore.collection<UserDocument>(`users`, ref => ref.orderBy('lastRecordingTime', 'desc'));
    } else {
      collection = this.firestore.collection<UserDocument>(`users`, ref => ref.where('readers', 'array-contains', operatorID).orderBy('lastRecordingTime', 'desc'));
    }

    return collection.valueChanges({ idField: 'userID' }).pipe(map(docs => {
      for(let doc of docs) {
        this.brmCloud.processUserDocument(doc);
      }
      return docs;
    }));
  }

  // We have to treat the read-all account specifically
  isReadAll() {
    return this.brmCloud.userInfo?.email === 'readall@bioracermotion.com';
  }
}
