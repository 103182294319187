import { OnInit, OnDestroy, AfterViewInit, Component, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, of, switchMap } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { BrmDataService } from '@shared/brm-data.service';
import { UserDocument } from '@shared/lib/user-document.interface';
import { MotionDataService } from '../services/motion-data.service';
import { OperatorCyclistsListComponent } from '@shared/components/operator-cyclists-list/operator-cyclists-list.component';

@Component({
  selector: 'motion-operator-cyclists',
  templateUrl: './operator-cyclists.component.html',
  styleUrls: ['./operator-cyclists.component.scss']
})


export class OperatorCyclistsComponent implements OnInit, AfterViewInit {
  // Note: the view child does not exist yet at ngAfterViewInit cause of the *ngIf we use
  @ViewChild(OperatorCyclistsListComponent) set cyclistsList(list: OperatorCyclistsListComponent) {
    if(list) {
      console.log("List view:", list);

      // Select active cyclist
      const cyclist = this.motionData.activeCyclist || this.motionData.prevActiveCyclist;
      if(cyclist) {
        console.log("Setting selected cyclist to", cyclist);
        // We don't emit the selected signal to prevent the recording list to be opened as a result
        list.selectCyclist(cyclist, false);
      }
    }
  }

  constructor(public readonly brmCloud: BrmCloudService, public readonly motionData: MotionDataService, private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.route.snapshot.data.breadcrumb = [
      { label: 'Home', url: '/' },
      { label: 'Motion', url: '/motion' },
    ];
  }

  ngAfterViewInit(): void {
    this.brmCloud.loggedOnUser$.subscribe(userDoc => {
      console.log("User logged on");
    });
  }

  handleCyclistSelected(cyclist: UserDocument) {
    this.motionData.setActiveCyclist(cyclist.userID);
    //this.motionData.activeCyclist = cyclist;
    console.log("Cyclist selected:", this.motionData.activeCyclist);

    // Open recordings view
    this.openRecordingsView(cyclist.userID);
  }

  openRecordingsView(cyclistID: string) {
    this.router.navigateByUrl(`/cyclist/${cyclistID}/motion-recordings`);
  }
}
