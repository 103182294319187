import { Component, Input } from '@angular/core';
import { Routes } from '@angular/router';
import { NavigationItem } from '../navigation/NavigationItem.interface';

@Component({
  selector: 'brm-app',
  templateUrl: './brm-app.component.html',
  styleUrls: ['./brm-app.component.scss']
})
export class BRMAppComponent {
  @Input() title = 'Bioracer Motion';
  @Input() navItems: NavigationItem[] = [ ];
  @Input() otherRoutes: Routes = [];
}
