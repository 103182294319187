import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore'
import { AngularFireFunctions } from '@angular/fire/compat/functions'
import { Observable, of, map, switchMap, firstValueFrom } from 'rxjs';
import { BRMUser } from './BRMUser';
import firebase from 'firebase/compat/app';
import Timestamp = firebase.firestore.Timestamp;
import { UserDocument, UserProfile } from './user-document.interface';

import { MatDialog } from '@angular/material/dialog';
import { LoginDialog } from '@shared/components/login/login.component';
import { RegisterUserDialog } from '@shared/components/register-user/register-user.component';
import { FirebaseError } from 'firebase/app';


export interface AccessRights {
  [index: string]: {
    type: string;
    secondsLeft: number
  };
}

@Injectable({
  providedIn: 'root'
})
export class BrmCloudService {

  // The currently logged on user (document)
  loggedOnUser$: Observable<any|null>;
  loggingOn = false;
  registeringUser = false;

  // Current user ID and info
  userID: string|null = null;
  userInfo: UserDocument|null = null;

  // Full name of currently logged on user (automatically set when the user changes)
  userFullName: string|null = null;

  // The current access rights
  userAccess: AccessRights = {};

  accessName(type: string) {
    if(type === "motion") {
      return "Bioracer Motion";
    } else if(type === "aero") {
      return "Bioracer Aero";
    } else if(type === "staticfit") {
      return "The Grid";
    } else if(type === "admin") {
      return "Administrator";
    }

    return undefined;
  }

  hasAccess(type: string) {
    if(type.length <= 0) {
      console.warn("We need a rights type to check access");
      return false;
    }

    if(!this.userID) {
      //console.log("User is not logged on, so no access to " + type);
      return false;
    }

    //console.log("this.userAccess", this.userAccess);
    const a = this.userAccess ? this.userAccess[type] : null;
    if(!a) {
      //console.log("User has no access to " + type)
      return false;
    }

    const timeLeft = (a.secondsLeft === -1) ? "forever" : `with ${a.secondsLeft} seconds left`;
    //console.log(`User has access to ${type} as ${a.type} ${timeLeft}`);
    return a;
  }

  get isMotionOperator() : boolean {
    return this.hasAccess("motion") && this.userAccess["motion"]?.type === "operator";
  }
  get isAeroOperator() : boolean {
    return this.hasAccess("aero") != false;
  }
  get isGridOperator() : boolean {
    return this.hasAccess("staticfit") != false;
  }
  get isOperator() : boolean {
    return this.isMotionOperator || this.isAeroOperator || this.isGridOperator;
  }

  get isAdmin() : boolean {
    return this.hasAccess("admin") != false;
  }

  constructor(public readonly auth: AngularFireAuth, public readonly firestore: AngularFirestore, public readonly functions: AngularFireFunctions, public dialog: MatDialog) {
    // Observable for the logged on user's info
    this.loggedOnUser$ = auth.user.pipe(
      switchMap(user => {
        if(user) {
            return this.userDoc(user.uid);
          } else {
            return of(null);
        }
      })
    );

    this.loggedOnUser$.subscribe(userDoc => {
      console.log("logged on user doc:", userDoc);

      // Store current info
      this.userInfo = userDoc;

      // We store the full name as convenience
      if(userDoc) {
        this.userFullName = this.fullName(userDoc);
      } else {
        this.userFullName = null;
      }
    });

    // Handle other stuff when logon status changes
    auth.user.subscribe(user => { this.handleAuthenticationChanged(user); });
  }

  async login(email: string, password: string) {
    console.log(`Logging in to the Bioracer Motion cloud using ${email}`);

    this.loggingOn = true;
    return this.auth.signInWithEmailAndPassword(email, password).finally(() => {
      this.loggingOn = false;
    });
  }

  async logout() {
    return this.auth.signOut();
  }

  async resetPassword(email: string, askConfirmation: boolean) {
    if(!email.length) {
      console.error("Cannot reset password without email address");
      return false;
    }

    // Optionally ask for confirmation
    if(askConfirmation) {
      if(!confirm("Are you sure you want to reset the password for " + email + "?")) {
        return false;
      }
    }

    return this.auth.sendPasswordResetEmail(email)
    .then(function() {
        const msg = "An email has been sent to your email address (" + email + ") to let you choose a new password.\n\n" +
            "Please click the link in that email within an hour.";
        alert(msg);
        return true;
    })
    .catch(resetError => {
        const msg = "Error sending email to " + email + " to let you choose a new password:\n" + resetError.message;
        alert(msg);
        return false;
    });
  }

  /////

  openLoginDialog(): void {
    // TEMP TEST
    //console.log("Currently logged on user: ", this.brmCloud.getLoggedOnUser())

    const dialogRef = this.dialog.open(LoginDialog, {
      restoreFocus: false,
      //data: this.brmCloud.getLoggedOnUser()
    });

    // The dialog is closed after login is successfull or cancelled
    dialogRef.afterClosed().subscribe(result => {
      // Manually restore focus to the menu trigger since the element that
      // opens the dialog won't be in the DOM any more when the dialog closes.
      //console.log("Menu trigger: ", this.menuTrigger);
      //this.menuTrigger.focus();

      console.log(`Dialog result: ${result}`);

      if(result) {
        console.log("Login successful");
      }

      //this.info = result;
    });
  }

  /////

  handleAuthenticationChanged(user: any) {
    if(user) {
      console.log(`User has logged on with email ${user.email}`)
      this.getCustomClaims(user);

      // Store current info
      this.userID = user.uid;
    } else {
      console.log("User has logged out");
      this.userID = null;
      this.userAccess = { };
    }
  }

  getCustomClaims(fbUser: firebase.User) {
    console.log('Checking custom user claims');
    fbUser.getIdTokenResult().then(idTokenResult => {
      this.userAccess = idTokenResult.claims.access;

      // DEBUG
      console.log("Access rights:", this.userAccess);
    });
  }

  ///// Register User /////

  openRegisterUserDialog(): void {
    const dialogRef = this.dialog.open(RegisterUserDialog, {
      restoreFocus: false,
      //data: this.brmCloud.getLoggedOnUser()
    });

    // The dialog is closed after login is successfull or cancelled
    dialogRef.afterClosed().subscribe(result => {
      // Manually restore focus to the menu trigger since the element that
      // opens the dialog won't be in the DOM any more when the dialog closes.
      //console.log("Menu trigger: ", this.menuTrigger);
      //this.menuTrigger.focus();

      console.log(`Dialog result: ${result}`);

      if(result) {
        console.log("Register User successful");
      }

      //this.info = result;
    });
  }

  async registerUser(email: string, firstName: string, lastName: string, langCode: string) {
    if(!email.length) {
      console.error("Cannot register user without email address");
      return;
    }

    const func = this.functions.httpsCallable('createUser');
    if(!func) {
      console.error("Cannot find createUser function");
      return;
    }

    console.log(`Registering user with email ${email}`);
    this.registeringUser = true;

    const response$ = func({
      email: email,
      firstName: firstName,
      lastName: lastName,
      language: langCode,
      // gender
      // referral
    });

    try {
      const result = await firstValueFrom(response$);
      console.log("Register User successful", result);
      return result;
    } catch(error) {
      console.error("Register User failed: ", error);
    } finally {
      console.log("Done registering user");
      this.registeringUser = false;
    }
  }

  ///// Users /////

  private userDocRef(userID: string) {
    return this.firestore.doc<UserDocument>(`users/${userID}`);
  }

  userDoc(userID: string | null): Observable<UserDocument | undefined> {
    if(userID == null) {
      return of(undefined);
    }

    const docRef = this.userDocRef(userID);

    return docRef.valueChanges({ idField: 'userID' }).pipe(map(
      doc => this.processUserDocument(doc)
    ));
  }

  processUserDocument(userInfo: UserDocument|undefined) {
    if(!userInfo) {
      return userInfo;
    }

    const result = userInfo;

    // Convert timestamps to date objects
    let t = result.createdAt;
    if(t) {
      /* Some times are still in milliseconds instead of a real Timestamp */
      if(typeof t === "number") {
        result.createdAtDate = new Date(t);
      } else {
        result.createdAtDate = t.toDate();
      }
    }

    t = result.lastRecordingTime;
    if(t) {
      /* Some times are still in milliseconds instead of a real Timestamp */
      if(typeof t === "number") {
        result.lastRecordingDate = new Date(t);
      } else {
        result.lastRecordingDate = t.toDate();
      }
    }

    t = result.profile?.birthday;
    if(t) {
      /* Some times are still in milliseconds instead of a real Timestamp */
      if(typeof t === "number") {
        result.profile.birthdayDate = new Date(t);
      } else {
        result.profile.birthdayDate = t.toDate();
      }
    }

    //console.log(result);
    return result;
  }

  async saveUserProfile(userID: string | null, profile: UserProfile) {
    if(userID == null) {
      console.warn("Cannot save user profile without user ID");
      return;
    }

    // Fill in firestore date
    if(profile.birthdayDate) {
      profile.birthday = firebase.firestore.Timestamp.fromDate(profile.birthdayDate);
    }

    console.log(`Saving user profile for ${userID}`, profile);

    const docRef = this.userDocRef(userID);
    await docRef.update({ profile: profile });

    console.log(`Done saving user profile for ${userID}`);
  }

  fullName(userDoc?: UserDocument) {
    if(!userDoc || !userDoc.profile) {
      return "";
    }

    const hasFN = userDoc.profile.firstName?.length > 0;
    const hasLN = userDoc.profile.lastName?.length > 0;

    if(hasFN && hasLN) {
      return userDoc.profile.firstName + " " + userDoc.profile.lastName;
    } else if(hasFN) {
      return userDoc.profile.firstName;
    } else {
      return userDoc.profile.lastName;
    }
  }

  ///// Utilities /////

  // Recursively convert all Timestamp instances to Date
  // Note: we don't use this because it messes with Typescript's type checking
  convertDates(doc : any) {
    for(let key in doc) {
      if(doc[key] instanceof Timestamp) {
        console.log(`Converting ${key} to Date`, doc[key], doc[key].toDate());
        doc[key] = doc[key].toDate();
      } else if(doc[key] instanceof Object) {
        this.convertDates(doc[key]);
      }
    }
  }
}
