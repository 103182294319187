import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BrmCloudService } from '@shared/lib/brm-cloud.service';
import { ServiceLocation } from '@shared/lib/user-document.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore';

@Component({
  selector: 'brm-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @Output() pictureSelected = new EventEmitter<File>();

  savingProfile = false;
  hasError = false;
  errorMessage = "";

  get profile() { return this.brmCloud.userInfo!.profile; }
  get company() { return this.profile.company!; }
  get serviceLocation() { return this.company.serviceLocation!; }

  constructor(public readonly brmCloud: BrmCloudService, private storage: AngularFireStorage, private firestore: AngularFirestore, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    // Make sure a service location is added to the user profile
    this.brmCloud.loggedOnUser$.subscribe((userInfo) => {
      if(!userInfo || !this.brmCloud.userInfo) {
        return;
      }

      if(!this.brmCloud.userInfo.profile) {
        console.log("User has no profile yet. Creating empty profile");
        this.brmCloud.userInfo.profile = {
          firstName: "",
          lastName: "",
          language: "",
          gender: "",
          birthdayDate: new Date(),
          birthday: Timestamp.now(),
          bodyHeight: 0,
          bodyWeight: 0
        };
      }

      if(this.brmCloud.isOperator) {
        if(!this.brmCloud.userInfo.profile.company) {
          console.log("Operator has no company profile yet. Creating empty profile");
          this.brmCloud.userInfo.profile.company = {
            name: "",
            address: "",
            phone: "",
            email: "",
            website: ""
          };
        }

        if(this.brmCloud.userInfo.profile.company.serviceLocation) {
          console.log("Company profile contains service location:", this.brmCloud.userInfo.profile.company.serviceLocation);
        } else {
          console.log("Company profile does not contain service location. Using default location");
          this.brmCloud.userInfo.profile.company.serviceLocation = {
            showLocation: false,
            latitude: 0,
            longitude: 0
          };
        }
      }
    });
  }

  saveProfile() {
    this.savingProfile = true;

    console.log("Profile to save:", this.profile);

    this.brmCloud.saveUserProfile(this.brmCloud.userID, this.profile)
    .then(()=> {
      this.savingProfile = false;
      this.showMessage("User Profile Saved", "OK");
    })
    .catch((error) => {
      this.savingProfile = false;
      this.hasError = true;
      this.errorMessage = error.message;
      this.showMessage(`Error saving user profile: ${error.message}`);
    });
  }

  showMessage(message: string, action?: string) {
    // Auto-dismiss when no action
    const options = action ? { } : { duration:3000 };
    const ref = this.snackBar.open(message, action, options);
    ref.afterDismissed().subscribe(() => { console.log("Snackbar dismissed"); });
    ref.onAction().subscribe(() => { console.log("Snackbar action triggered"); });
  }

  ///// Location /////

  get serviceLocationUrl() {
    const locInfo = this.serviceLocation;

    if(locInfo) {
      return this.googleMapsLink(locInfo.longitude, locInfo.latitude);
    } else {
      return null;
    }
  }

  getLocation() {
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // Set the service location from the received position
        let locInfo = this.serviceLocation;
        locInfo.latitude = position.coords.latitude;
        locInfo.longitude = position.coords.longitude;
      }, (error) => {
        this.showMessage(`Error getting current location: ${error.message}`);
      });
    } else {
      this.showMessage("Geolocation is not supported on your browser");
    }
  }

  googleMapsLink(longitude: number, latitude: number) {
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  }

  googleMapsMultiLink(locations: Array<{ latitude: number, longitude: number}>) {
    let url = "https://www.google.com/maps/dir/";
    for(const loc of locations) {
        url += `${loc.latitude},${loc.longitude}/`;
    }

    return url;
  }

  ///// Company Logo /////

  // TODO Move logic to BRM Cloud service

  onFileSelected(event: Event) {
    if(!this.brmCloud.isOperator) {
      return;
    }

    const files = (event.target as HTMLInputElement).files;
    const file = files ? files[0] : null;
    if (file) {
      this.pictureSelected.emit(file);
      const fileExtension = file.name.split('.').pop();
      const filePath = `users/${this.brmCloud.userID}/companyLogo.${fileExtension}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      // Get the download URL once the image is uploaded
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((url) => {
              this.company.logoURL = url;
              this.saveImageUrlToFirestore(url);
            });
          })
        )
        .subscribe();
    }
  }

  saveImageUrlToFirestore(url: string) {
    if(!this.brmCloud.isOperator) {
      return;
    }

    this.firestore
      .collection('users')
      .doc(this.brmCloud.userID!)
      .set(
        {
          profile: {
            company: {
              logoURL: url,
            },
          },
        },
        { merge: true }
      );
  }

  removeCompanyLogo() {
    // Remove from storage
    const ref = this.companyLogoStorageRef();
    if(ref) {
      ref.delete();
    }

    // Remove URL from Firestore
    this.company.logoURL = "";
    this.saveImageUrlToFirestore("");
  }

  companyLogoStorageRef() {
    return this.company.logoURL ? this.storage.refFromURL(this.company.logoURL) : null;
  }
}
