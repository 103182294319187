import { OnInit, OnDestroy, AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, of, switchMap } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { BrmDataService } from '@shared/brm-data.service';
import { UserDocument } from '@shared/lib/user-document.interface';
import { MotionDataService } from '../services/motion-data.service';
import { MotionRecordingInfo } from '../services/motion-recording-info.interface';
import { Location } from '@angular/common';

@Component({
  selector: 'motion-recordings-view',
  templateUrl: './recordings-view.component.html',
  styleUrls: ['./recordings-view.component.scss']
})


export class RecordingsViewComponent implements AfterViewInit {

  cyclist?: UserDocument;
  cyclistID?: string;

  constructor(
    public readonly brmCloud: BrmCloudService, public readonly motionData: MotionDataService,
    private route: ActivatedRoute, private location: Location) {

  }

  ngAfterViewInit(): void {

    // We set the cyclistID from the URL
    // and we default to the active cyclist or the logged on user
    this.route.paramMap.subscribe(params => {
      console.log("Params", params)
      const cyclistID = params.get('cyclistID');
      if(cyclistID) {
        // Set active cyclist from URL
        this.motionData.setActiveCyclist(cyclistID);

        this.cyclistID = cyclistID;
        this.brmCloud.userDoc(this.cyclistID).subscribe(doc => { this.cyclist = doc; });
      } else if(this.motionData.activeCyclist) {
        this.cyclist = this.motionData.activeCyclist;
        this.cyclistID = this.cyclist?.userID;
        this.updateUrl();
      } else {
        this.brmCloud.loggedOnUser$.subscribe(userDoc => {
          // Set logged on user as active cyclist
          this.motionData.setActiveCyclist(cyclistID);

          this.cyclist = userDoc;
          this.cyclistID = this.cyclist?.userID;
          this.updateUrl();
        });
      }
    });

    this.brmCloud.loggedOnUser$.subscribe(userDoc => {
      console.log("User logged on");
    });
  }

  handleRecordingSelected(recording: MotionRecordingInfo) {
    this.motionData.setActiveRecording(recording.recordingID);
    //this.motionData.activeRecording = recording;
    console.log("Recording selected:", this.motionData.activeRecording);
  }

  updateUrl() {
    const url = `/cyclist/${this.cyclistID}/motion-recordings`;
    this.location.replaceState(url);
  }
}
