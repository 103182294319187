import { Component, Input, OnInit } from '@angular/core';
import { BikeAnalysis } from '../services/bike-analysis';

@Component({
  selector: 'motion-bike-markers',
  templateUrl: './bike-markers.component.html',
  styleUrls: ['./bike-markers.component.scss']
})
export class BikeMarkersComponent implements OnInit {

  @Input() bikeAnalysis: BikeAnalysis | null = null;

  // Optional road bike markers
  public get hasHeadTube() { return this.bikeAnalysis?.headTubePos != undefined; }
  public get hasHoods() { return this.bikeAnalysis?.hoodsPos != undefined; }
  public get hasBothHoods() { return this.bikeAnalysis?.hoodsBothSides; }

  // Optional TT bike markers
  public get hasPads() { return this.bikeAnalysis?.elbowPadsPos != undefined; }
  public get hasBothPads() { return this.bikeAnalysis?.elbowPadsBothSides; }
  public get hasShifters() { return this.bikeAnalysis?.shiftersPos != undefined; }
  public get hasBothShifters() { return this.bikeAnalysis?.shiftersBothSides; }

  constructor() {

  }

  ngOnInit(): void {
  }
}
