<!-- Only for TT bikes -->
<brm-card *ngIf="bikeAnalysis && bikeAnalysis.shiftersPos" title="Shifters">
  <img id="bikeImage" src="assets/bike-report/TT Bike Report - Shifters.svg" alt="Bike" />

  <div class="content">
    <div class="titles">
      <p class="xy">Shifters X (mm): </p>
      <p class="xy">Shifters Y (mm): </p>
      <p>Shifters reach (mm): </p>
      <p>Shifters drop (mm): </p>
      <p>Shifters width (mm): </p>
      <p>Height difference (mm): </p>
    </div>
    <div class="values">
      <p>{{lengthMM(bikeAnalysis.shiftersX)}}</p>
      <p>{{lengthMM(bikeAnalysis.shiftersY)}}</p>
      <p>{{lengthMM(bikeAnalysis.shiftersReach)}}</p>
      <p>{{lengthMM(bikeAnalysis.shiftersDrop)}}</p>
      <p>{{lengthMM(bikeAnalysis.shiftersWidth)}}</p>
      <p>{{diffMM(bikeAnalysis.shiftersHeightDifference)}}</p>
    </div>
  </div>
</brm-card>
