import { OnInit, OnDestroy, AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, of, switchMap } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { BrmDataService } from '@shared/brm-data.service';

@Component({
  selector: 'motion-user-session-list',
  templateUrl: './user-session-list.component.html',
  styleUrls: ['./user-session-list.component.scss']
})


export class UserSessionListComponent implements AfterViewInit {

  userID: string | null;
  sessions$: Observable<any[]>;

  dataSource: MatTableDataSource<any>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [ 'date', 'email', 'description', 'actions' ];

  constructor(public readonly brmCloud: BrmCloudService, private brmData: BrmDataService, private firestore: AngularFirestore, private storage: AngularFireStorage, private router: Router) {

  }

  ngAfterViewInit(): void {
    // The recordings collection is dependent on the logged on user
    this.sessions$ = this.brmCloud.loggedOnUser$.pipe(
      switchMap(info => {
        if(info) {
          console.log("Querying all sessions for email", info.email);
          return this.brmData.userSessions(info.email);
        } else {
          return of([ ]);
        }
      })
    );

    // Set data source from recordings
    this.sessions$.subscribe(sessions => {
      this.dataSource = new MatTableDataSource(sessions);
    });

    // Store current user ID
    this.brmCloud.loggedOnUser$.subscribe(userDoc => {
      this.userID = userDoc?.userID;
    });
  }

  showDetails(userID: string, sessionID: string) {
    this.router.navigateByUrl('/userSessions/' + userID + '/' + sessionID);
  }

}
