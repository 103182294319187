import * as THREE from "three";
import { Color } from "three";
import { LineSegments2, LineSegmentsGeometry, LineMaterial } from 'three-fatline';
import { MotionData, TrackingPoint, PointFitting, RecordingPointFrameData, Vector3 } from "../services/motion-data";
import { MotionAnalysis } from "../services/motion-analysis";
import { PointAnalysis } from "../services/point-analysis";

export class MotionBody3D extends THREE.Object3D {
  pointSize: number = 5.0;
  pointColor: THREE.Color = new THREE.Color("#4444FF");
  lineWidth: number = 3;
  //lineColor: THREE.Color = new THREE.Color("#DDDDDD");
  lineColor: number = 0x000000;
  perspective: boolean = true;

  pointsGeometry: THREE.BufferGeometry;
  pointMaterial: THREE.PointsMaterial;
  points: THREE.Points;

  linesGeometry: LineSegmentsGeometry;
  lineMaterial: LineMaterial;
  lines: LineSegments2;

  referenceLinesGeometry: LineSegmentsGeometry;
  referenceLinesMaterial: LineMaterial;
  referenceLines: LineSegments2;
  referenceLinesColor: number = 0x00FF00;

  frameBoundingBox: THREE.Box3 | null;
  recordingBoundingBox: THREE.Box3 | null;

  generatePoints(frameIdx: number) {
    return this.pointAnalysis.calcAllTransformedPositions(frameIdx).map(p => [p.x, p.y, p.z]).flat();
  }

  generateBodyLinePoints(frameIdx: number) {
    let linePoints: number[] = [];
    let fromPos = null;

    // Left body
    let pointTypes = TrackingPoint.leftBodyPoints;
    for(const pType of pointTypes) {
      const pos = this.pointAnalysis.calcTransformedPosition(pType, frameIdx);
      if(pos) {
        if(fromPos) {
          linePoints = linePoints.concat([fromPos.x, fromPos.y, fromPos.z, pos.x, pos.y, pos.z]);
        }

        fromPos = pos;
      }
    }

    // Line from front of foot to ankle
    let p1 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.LeftFootFront, frameIdx);
    let p2 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.LeftAnkle, frameIdx);
    if(p1 && p2) {
      linePoints = linePoints.concat([p1.x, p1.y, p1.z, p2.x, p2.y, p2.z]);
    }

    fromPos = null;

    // Right body
    pointTypes = TrackingPoint.rightBodyPoints;
    for(const pType of pointTypes) {
      const pos = this.pointAnalysis.calcTransformedPosition(pType, frameIdx);
      if(pos) {
        if(fromPos) {
          linePoints = linePoints.concat([fromPos.x, fromPos.y, fromPos.z, pos.x, pos.y, pos.z]);
        }

        fromPos = pos;
      }
    }

    // Line from front of foot to ankle
    p1 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.RightFootFront, frameIdx);
    p2 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.RightAnkle, frameIdx);
    if(p1 && p2) {
        linePoints = linePoints.concat([p1.x, p1.y, p1.z, p2.x, p2.y, p2.z]);
    }

    // Pelvis and shoulders

    // Horizontal lines between pelvis (1 and 2)
    p1 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.LeftPelvis1, frameIdx);
    p2 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.RightPelvis1, frameIdx);
    if(p1 && p2) {
      linePoints = linePoints.concat([p1.x, p1.y, p1.z, p2.x, p2.y, p2.z]);
    }
    p1 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.LeftPelvis2, frameIdx);
    p2 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.RightPelvis2, frameIdx);
    if(p1 && p2) {
      linePoints = linePoints.concat([p1.x, p1.y, p1.z, p2.x, p2.y, p2.z]);
    }

    // Cross in pelvis
    p1 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.LeftPelvis1, frameIdx);
    p2 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.RightPelvis2, frameIdx);
    if(p1 && p2) {
      linePoints = linePoints.concat([p1.x, p1.y, p1.z, p2.x, p2.y, p2.z]);
    }
    p1 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.LeftPelvis2, frameIdx);
    p2 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.RightPelvis1, frameIdx);
    if(p1 && p2) {
      linePoints = linePoints.concat([p1.x, p1.y, p1.z, p2.x, p2.y, p2.z]);
    }

    // Horizontal line between shoulders
    p1 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.LeftShoulder, frameIdx);
    p2 = this.pointAnalysis.calcTransformedPosition(TrackingPoint.RightShoulder, frameIdx);
    if(p1 && p2) {
      linePoints = linePoints.concat([p1.x, p1.y, p1.z, p2.x, p2.y, p2.z]);
    }

    return linePoints;
  }

  generateReferenceLinesPoints(frameIdx: number) {
    const startPos = this.pointAnalysis.calcTransformedPosition(TrackingPoint.FrameBack, frameIdx);
    const endPos = this.pointAnalysis.calcTransformedPosition(TrackingPoint.FrameFront, frameIdx);
    if(!startPos || !endPos) {
      return [];
    }

    return [startPos.x, startPos.y, startPos.z, endPos.x, endPos.y, endPos.z];
  }

  /////

  constructor(protected pointAnalysis: PointAnalysis) {
    super();

    // Recording bounding box
    const min = this.pointAnalysis.metrics.metrics.boundsMin;
    const max = this.pointAnalysis.metrics.metrics.boundsMax;
    this.recordingBoundingBox = new THREE.Box3(new THREE.Vector3(min.x, min.y, min.z), new THREE.Vector3(max.x, max.y, max.z));

    // Points
    // Note: Geometry is filled in later
    this.pointsGeometry = new THREE.BufferGeometry();
    this.pointMaterial = new THREE.PointsMaterial( {
      color: this.pointColor,
      size: this.pointSize,
      sizeAttenuation: this.perspective
    } );

    this.points = new THREE.Points( this.pointsGeometry, this.pointMaterial );
    this.add(this.points);

    // Lines
    // Note: Geometry is filled in later
    this.linesGeometry = new LineSegmentsGeometry();
    this.lineMaterial = new LineMaterial( {
        color: this.lineColor,
        linewidth: this.lineWidth
    } );

    this.lines = new LineSegments2(this.linesGeometry, this.lineMaterial);
    this.lines.scale.set(1,1,1);
    this.add(this.lines);

    // Reference lines
    // Note: Geometry is filled in later
    this.referenceLinesGeometry = new LineSegmentsGeometry();
    this.referenceLinesMaterial = new LineMaterial( {
        color: this.referenceLinesColor,
        linewidth: this.lineWidth
    } );

    this.referenceLines = new LineSegments2(this.referenceLinesGeometry, this.referenceLinesMaterial);
    this.referenceLines.scale.set(1,1,1);
    this.add(this.referenceLines);

    // Set to start time
    this.restartAnimation();
  }

  protected currentFrameIdx: number = -1;
  protected currentPoints: number[];
  protected currentBodyLinePoints: number[];
  protected currentReferenceLinesPoints: number[];
  loop: boolean = true;

  setAnimationTime(time: number) {
    const startTime = this.pointAnalysis.data.startTime;
    const endTime = this.pointAnalysis.data.endTime;
    const duration = endTime - startTime;
    const numFrames = this.pointAnalysis.data.numPointFrames();

    //console.debug("Setting animation time to " + time);
    let loopedTime = time;
    if(this.loop) {
      const numLoops = Math.floor((time - startTime) / duration);
      loopedTime -= numLoops * duration;
      //console.debug("numLoops: ", numLoops, "loopedTime: ", loopedTime);
    }

    if(duration > 0 && loopedTime >= startTime && loopedTime <= endTime) {
      // We need the frame in all the frames
      const u = loopedTime / this.pointAnalysis.data.duration;
      const frameIdx = Math.floor(u * numFrames);
      this.setCurrentFrameIdx(frameIdx);
    }
  }

  restartAnimation() {
    this.setAnimationTime(this.pointAnalysis.data.startTime);
  }

  setCurrentFrameIdx(frameIdx: number) {
    if(this.currentFrameIdx == frameIdx) {
      return;
    }

    //console.debug("Setting current frame to " + frameIdx);

    if(frameIdx < 0 || frameIdx >= this.pointAnalysis.data.numPointFrames()) {
        console.warn("Invalid frame index ", frameIdx);
        return;
    }

    this.currentFrameIdx = frameIdx;
    this.currentPoints = this.generatePoints(this.currentFrameIdx);
    this.currentBodyLinePoints = this.generateBodyLinePoints(this.currentFrameIdx);
    this.currentReferenceLinesPoints = this.generateReferenceLinesPoints(this.currentFrameIdx);

    this.setPoints(this.currentPoints, this.currentBodyLinePoints, this.currentReferenceLinesPoints);
  }

  setPoints(pointPositions: Iterable<number>, linePositions: Iterable<number>, referenceLinesPositions: Iterable<number>) {
    this.pointsGeometry.setAttribute( 'position', new THREE.Float32BufferAttribute( pointPositions, 3 ) );
    this.pointsGeometry.computeBoundingSphere();

    //this.linesGeometry.setAttribute( 'position', new THREE.Float32BufferAttribute( linePositions, 3 ) );
    //this.linesGeometry.computeBoundingSphere();
    this.linesGeometry.setPositions(Array.from(linePositions));
    this.lines.computeLineDistances();

    this.referenceLinesGeometry.setPositions(Array.from(referenceLinesPositions));
    this.referenceLines.computeLineDistances();

    // We use the points for the bounding box
    this.pointsGeometry.computeBoundingBox();
    this.frameBoundingBox = this.pointsGeometry.boundingBox;
  }

  // The line rendering needs a resolution
  setResolution(width: number, height: number) {
    console.log("Setting resolution to " + width + "x" + height);

    this.lineMaterial.resolution = new THREE.Vector2(width, height);
    this.lines.computeLineDistances();
    this.lines.scale.set(1,1,1);

    this.referenceLinesMaterial.resolution = new THREE.Vector2(width, height);
    this.referenceLines.computeLineDistances();
    this.referenceLines.scale.set(1,1,1);
  }
}

///// Interfaces /////

interface MappedPoints {
  [type: number]: Vector3;
}
