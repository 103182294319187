<mat-toolbar color="primary" role="header">
    <!-- Menu toggle button (for small screens) -->
    <!--<button mat-icon-button aria-label="Menu toggle">
        <mat-icon>menu</mat-icon>
    </button>-->
    <img class="logo" [alt]="title" [src]="logo" />
    <ng-content></ng-content>
    <span class="headerTitle">{{ title }}</span>

    <span class="spacer"></span>

    <img class="product-logo" *ngIf="brmCloud.isAdmin" src="assets/ascension.png" alt="Administrator" [matTooltip]="adminToolTip"/>
    <img class="product-logo" *ngIf="brmCloud.isMotionOperator" src="assets/motion-logo-rounded.png" alt="Bioracer Motion" [matTooltip]="motionToolTip"/>
    <img class="product-logo" *ngIf="brmCloud.isAeroOperator" src="assets/bra-logo-rounded-white-border.svg" alt="Bioracer Aero" [matTooltip]="aeroToolTip"/>
    <img class="product-logo" *ngIf="brmCloud.isGridOperator" src="assets/grid-logo-rounded.png" alt="The Grid" [matTooltip]="gridToolTip"/>

    <brm-login></brm-login>
</mat-toolbar>
