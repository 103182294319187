<div *ngIf="brmCloud.loggedOnUser$ | async; else notLoggedOn" class="mat-elevation-z8">
  <!-- Search Bar-->
  <div class="row">
    <mat-form-field class="expand">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyTextFilter($event)" placeholder="Search" #input>
    </mat-form-field>

    <mat-slide-toggle [(ngModel)]="showCyclingRecordings" [ngModelOptions]="{standalone: true}" (toggleChange)="applyTypeFilter">Cycling recordings</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="showBikePictures" [ngModelOptions]="{standalone: true}" (toggleChange)="applyTypeFilter">Bike pictures</mat-slide-toggle>
  </div>

  <table *ngIf="(recordings$ | async)?.length; else noData" #table mat-table [dataSource]="dataSource" class="full-width-table" aria-label="Elements" (onDataChanged)="table.renderRows()">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let rec">
        <!--<a [routerLink]="['/motion-recording', cyclistID, rec.recordingID, 'details-' + rec.recordingType]">{{rec.name}}</a>-->
        {{rec.name}}
      </td>
    </ng-container>

    <!-- Recording time Column -->
    <ng-container matColumnDef="recordingTime">
      <th mat-header-cell *matHeaderCellDef>Recording Time</th>
      <td mat-cell class="recording-time" *matCellDef="let rec">{{rec.recordingDate | date:'medium'}}</td>
    </ng-container>

    <!-- Recording type Column -->
    <ng-container matColumnDef="recordingType">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let rec">{{fullRecordingType(rec)}}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let rec">
        <button *ngIf="cyclistID && isCyclingRecording(rec)" mat-icon-button (click)="$event.stopPropagation(); showAnalysis(cyclistID, rec.recordingID)" aria-label="Download">
          <mat-icon>videocam</mat-icon>
        </button>
        <!-- Download only for admins -->
        <button *ngIf="cyclistID && brmCloud.hasAccess('admin')" mat-icon-button (click)="$event.stopPropagation(); motionData.downloadRecordingData(cyclistID, rec.recordingID)" aria-label="Analysis">
          <mat-icon>download</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Rows-->
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
    <tr mat-row
      [id]="'row-' + recording?.recordingID"
      *matRowDef="let recording; let row = index; columns: displayedColumns;"
      [class.new-section]="row > 0 && recording?.sectionTitle"
      [class.is-selected]="selectedRecording?.recordingID === recording?.recordingID"
      (click)="selectRecording(recording)"
    ></tr>
  </table>

  <ng-template #noData>
    <div style="padding: 1em">There are no recordings for this cyclist.</div>
  </ng-template>
</div>

<ng-template #notLoggedOn>
  <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> to see your data.
</ng-template>
