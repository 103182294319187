import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Environment configuration
import { environment } from '../environments/environment';

// Used Firebase modules
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule, PERSISTENCE, USE_DEVICE_LANGUAGE, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth'
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

// Our modules and components
import { BRMAppComponent } from '../components/brm-app/brm-app.component';
import { LoginComponent, LoginDialog } from '../components/login/login.component';
import { RegisterUserComponent, RegisterUserDialog } from '../components/register-user/register-user.component';
import { HeaderComponent } from '../components/header/header.component';
import { NavigationComponent } from '../components/navigation/navigation.component';
import { OperatorSessionListComponent } from '../components/operator-session-list/operator-session-list.component'
import { UserSessionListComponent } from '../components/user-session-list/user-session-list.component'
import { OperatorCyclistsListComponent } from '../components/operator-cyclists-list/operator-cyclists-list.component';
import { AppRoutingModule } from './app-routing.module';
import { UserProfileComponent } from '../components/user-profile/user-profile.component';

import { MatBreadcrumbModule } from '@shared/mat-breadcrumb/mat-breadcrumb.module';

// Bundled Angular Material modules
import {MaterialModule} from './material.module';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

// User Table
import { UserTableComponent } from '../components/user-table/user-table.component';

import { CardComponent } from '../components/card/card.component';

// Layout
import {FlexLayoutModule} from '@angular/flex-layout';


@NgModule({
    declarations: [
        BRMAppComponent,
        HeaderComponent,
        NavigationComponent,
        UserTableComponent,
        LoginComponent, LoginDialog,
        RegisterUserComponent, RegisterUserDialog,
        OperatorSessionListComponent,
        UserSessionListComponent,
        OperatorCyclistsListComponent,
        UserProfileComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule, BrowserAnimationsModule,
        FormsModule, ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        FlexLayoutModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule, AngularFireAuthGuardModule,
        AngularFirestoreModule,
        MaterialModule,
        MatFormFieldModule,
        MatBreadcrumbModule,

        CardComponent,
    ],
    exports: [
        // Common Angular modules
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        FlexLayoutModule,
        MaterialModule,
        // Our shared components
        BRMAppComponent,
        HeaderComponent,
        NavigationComponent,
        LoginComponent, LoginDialog,
        RegisterUserComponent, RegisterUserDialog,
        OperatorSessionListComponent,
        UserSessionListComponent,
        OperatorCyclistsListComponent,
        UserTableComponent,
        UserProfileComponent,
        MatBreadcrumbModule,
    ],
    providers: [
        { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },
        { provide: USE_DEVICE_LANGUAGE, useValue: true },
        { provide: PERSISTENCE, useValue: 'local' },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
    ],
    bootstrap: [
        LoginDialog,
        RegisterUserDialog
    ]
})
export class SharedModule { }
