<div id="root" *ngIf="brmCloud.loggedOnUser$ | async; else notLoggedOn" fxLayout="column" fxFlexFill>
  <div fxLayout="row" fxLayoutGap="0.5em" fxFlex>
    <div fxLayout="column" fxFlex="50">
      <h1>Cyclists</h1>
      <div class="cyclists" fxFlex>
        <motion-operator-cyclists-list (cyclistSelected)="handleCyclistSelected($event)"></motion-operator-cyclists-list>
      </div>
    </div>
    <mat-divider vertical="true"></mat-divider>
    <div class="column" fxLayout="column" fxFlex="50">
      <h1 *ngIf="!selectedCyclist"><em>Select a cyclist</em></h1>
      <h1 *ngIf="selectedCyclist">{{ brmCloud.fullName(selectedCyclist) }}</h1>
      <div class="recordings" fxFlex>
        <motion-recording-list *ngIf="selectedCyclist" [cyclistID]="selectedCyclist.userID"></motion-recording-list>
      </div>
    </div>
  </div>
</div>

<ng-template #notLoggedOn>
  <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> to see your data.
</ng-template>
