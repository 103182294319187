import { Component, Input, OnInit } from '@angular/core';
import { MotionRecordingInfo } from '../services/motion-recording-info.interface';

@Component({
  selector: 'motion-body-measurements',
  templateUrl: './body-measurements.component.html',
  styleUrls: ['./body-measurements.component.scss']
})
export class BodyMeasurementComponent implements OnInit {

  @Input() recording: MotionRecordingInfo | null = null;

  // True if the recording info has anything to display
  public hasData() {
    const bm = this.recording?.bodyMeasurements;
    if(!bm) {
      return false;
    }

    if(bm.inseamLength || bm.shoulderWidth || bm.armLengthLeft || bm.torsoLength) {
      return true;
    }

    return false;
  }

  // Display a length value in millimeters
  public lengthMM(value?: number) {
    if(value) {
      return (value * 1000).toFixed();
    } else {
      return "/";
    }
  }

  public displayAngle(angle?: number) {
    if(angle) {
      return (angle).toFixed(1);
    } else {
      return "/";
    }
  }

  /////

  constructor() {

  }

  ngOnInit(): void {
  }
}
