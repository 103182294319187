import { TrackingPoint, Vector2, Vector3 } from '../services/motion-data';
import { PointAnalysis, PointMetrics } from '../services/point-analysis';
import { AngleMetrics, MotionAnalysis } from '../services/motion-analysis';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'brm-cycling-shoulders-info',
  templateUrl: './cycling-shoulders-info.component.html',
  styleUrls: ['./cycling-shoulders-info.component.scss']
})
export class CyclingShouldersInfoComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() motionAnalysis: MotionAnalysis | null = null;

  leftShoulderPointMetrics: PointMetrics | null = null;
  rightShoulderPointMetrics: PointMetrics | null = null;

  // Instability in millimeters
  movementMM: Vector3 = { x: 0, y: 0, z: 0 };

  // Add TrackingPoint class so we can use in our html
  TrackingPoint = TrackingPoint;

  /////

  constructor() {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.debug("[CyclingShouldersInfoComponent] changes: ", changes);

    let needsReload = false;

    if(changes.motionAnalysis) {
      needsReload = true;
    }

    if(needsReload) {
      this.refreshData();
    }
  }

  /////

  refreshData() {
    console.log("Refreshing shoulder info data");

    if(this.motionAnalysis) {
      this.leftShoulderPointMetrics = this.motionAnalysis.pointAnalysis.metrics.pointMetrics[TrackingPoint.LeftShoulder];
      this.rightShoulderPointMetrics = this.motionAnalysis.pointAnalysis.metrics.pointMetrics[TrackingPoint.RightShoulder];

      // We use the average movement
      this.movementMM.x = 1000.0 * (this.leftShoulderPointMetrics.width + this.rightShoulderPointMetrics.width) / 2;
      this.movementMM.y = 1000.0 * (this.leftShoulderPointMetrics.height + this.rightShoulderPointMetrics.height) / 2;
      this.movementMM.z = 1000.0 * (this.leftShoulderPointMetrics.depth + this.rightShoulderPointMetrics.depth) / 2;
    } else {
      this.leftShoulderPointMetrics = null;
      this.rightShoulderPointMetrics = null;

      this.movementMM = { x: 0, y: 0, z: 0 };
    }
  }

}
