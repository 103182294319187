<div *ngIf="brmCloud.loggedOnUser$ | async; else notLoggedOn">
<div *ngIf="recording$ | async as recording; else noAccess" fxLayout="column" fxLayoutGap="1em">

  <motion-recording-report-header [recording]="recording" fxFlexFill></motion-recording-report-header>

  <!-- <brm-card title="Movement" id="movement">
    <div class="controls">
      <mat-slide-toggle [(ngModel)]="slowMotion" [ngModelOptions]="{standalone: true}">Slow Motion</mat-slide-toggle>
    </div>

    <brm-motion3d #motion_right *ngIf="cyclistID && recordingID" [cyclistID]="cyclistID" [recordingID]="recordingID" [slowMotion]="slowMotion"></brm-motion3d>
    <brm-motion3d #motion_back *ngIf="cyclistID && recordingID" [cyclistID]="cyclistID" [recordingID]="recordingID" [slowMotion]="slowMotion"></brm-motion3d>
  </brm-card> -->

  <brm-cycling-knee-info [motionAnalysis]="motionAnalysis"></brm-cycling-knee-info>
  <brm-cycling-ankle-info [motionAnalysis]="motionAnalysis"></brm-cycling-ankle-info>
  <brm-cycling-shoulders-info [motionAnalysis]="motionAnalysis"></brm-cycling-shoulders-info>
  <brm-cycling-pelvis-info [motionAnalysis]="motionAnalysis"></brm-cycling-pelvis-info>

  <brm-pedalcycles-graph [motionAnalysis]="motionAnalysis"></brm-pedalcycles-graph>

  <brm-card *ngIf="recording.comments" title="Comments">
    <p>{{recording.comments}}</p>
  </brm-card>

  <motion-body-measurements [recording]="recording" *ngIf="recording.bodyMeasurements"></motion-body-measurements>
  <motion-equipment [recording]="recording" *ngIf="recording.equipment"></motion-equipment>

</div>
</div>

<ng-template #notLoggedOn>
  <div>

    <h1>Not Logged On</h1>

    <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> with your Bioracer Motion account to see data.<br />

  </div>
</ng-template>

<ng-template #noAccess>
<div>

  <h1>No Access</h1>

  You don't have access to this data.<br />
  <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> with a Bioracer Motion account with the correct permissions to see the data.<br />

</div>
</ng-template>
