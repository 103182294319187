<brm-card *ngIf="motionAnalysis" title="Pelvis">
  <div class="column" style="max-width: 33%">
    <div class="column">
      <h3>Back view</h3>
      <brm-point-trace class="image"
        [pointAnalysis]="motionAnalysis.pointAnalysis"
        [pointTypes]="[ TrackingPoint.LeftPelvis1, TrackingPoint.RightPelvis1 ]"
        [projectionAxis]="2"
        [drawMidpoint]="true"
        [drawExtendedLine]="true"
        [marginRelative]="0.5"
      ></brm-point-trace>
    </div>

    <div class="column">
      <h3>Top view</h3>
      <brm-point-trace class="image"
        [pointAnalysis]="motionAnalysis.pointAnalysis"
        [pointTypes]="[ TrackingPoint.LeftPelvis1, TrackingPoint.RightPelvis1 ]"
        [projectionAxis]="1"
        [drawMidpoint]="true"
        [drawExtendedLine]="true"
        [marginRelative]="0.5"
      ></brm-point-trace>
    </div>
  </div>

  <div class="content">
    <div class="titles">
      <p>Lateral movement (mm): </p>
      <p>Vertical movement (mm): </p>
      <p>Forward-backward movement (mm):</p>
    </div>
    <div class="values">
      <p>{{instabilityMM.x.toFixed()}}</p>
      <p>{{instabilityMM.y.toFixed()}}</p>
      <p>{{instabilityMM.z.toFixed()}}</p>
    </div>
  </div>
</brm-card>
