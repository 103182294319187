import { MotionData, RecordingPointData, TrackingPoint, Vector3, markerThickness, Vector2, wiredMarkers } from "./motion-data";
import { MotionRecordingInfo } from "./motion-recording-info.interface";
import { PointAnalysis, getLeftProjectedPos, getDistance, radToDeg } from "./point-analysis";

export class BikeAnalysis {
  // Re-export marker thickness
  get markerThickness() { return markerThickness; }

  // Marker thickness in millimeters
  get markerThicknessMM() { return this.markerThickness * 1000; }

  // Settings
  get backOfSaddleToSitPosition(): number { return this.recording?.equipment.backOfSaddleToSitPosition || 0.12; }
  get saddleSitPositionToFront(): number { return this.recording?.equipment.saddleSitPositionToFront || 0.15; }

  // Bicycle Type
  // Note: road bike is used when not specific type is set
  get isRoadBike() { return this.recording?.equipment.bicycleType == 'roadBike' || this.recording?.equipment.bicycleType == 'racing' || (!this.isTTBike && !this.isMountainBike) }
  get isTTBike() { return this.recording?.equipment.bicycleType == 'ttBike'; }
  get isMountainBike() { return this.recording?.equipment.bicycleType == 'mountainBike'; }

  get wiredHarness() { return wiredMarkers; }

  pointAnalysis: PointAnalysis;

  crankPos: Vector3|undefined;
  sitPos: Vector3|undefined;

  handlebarPos: Vector3|undefined;
  headTubePos: Vector3|undefined;
  leftHoodPos: Vector3|undefined;
  rightHoodPos: Vector3|undefined;

  public get hoodsPos(): Vector3|undefined {
    return this.getCenter(this.leftHoodPos, this.rightHoodPos);
  }

  // TT Bike Specific
  leftElbowPadPos: Vector3|undefined;
  rightElbowPadPos: Vector3|undefined;
  leftShifterPos: Vector3|undefined;
  rightShifterPos: Vector3|undefined;

  public get elbowPadsPos(): Vector3|undefined {
    return this.getCenter(this.leftElbowPadPos, this.rightElbowPadPos);
  }

  public get shiftersPos(): Vector3|undefined {
    return this.getCenter(this.leftShifterPos, this.rightShifterPos);
  }

  // Projected positions
  public get crankPos2D() { return this.crankPos ? getLeftProjectedPos(this.crankPos) : undefined; }
  public get sitPos2D() { return this.sitPos ? getLeftProjectedPos(this.sitPos) : undefined; }
  public get handlebarPos2D() { return this.handlebarPos ? getLeftProjectedPos(this.handlebarPos) : undefined; }
  public get headTubePos2D() { return this.headTubePos ? getLeftProjectedPos(this.headTubePos) : undefined; }
  public get leftHoodPos2D() { return this.leftHoodPos ? getLeftProjectedPos(this.leftHoodPos) : undefined; }
  public get rightHoodPos2D() { return this.rightHoodPos ? getLeftProjectedPos(this.rightHoodPos) : undefined; }
  public get hoodsPos2D() { return this.hoodsPos ? getLeftProjectedPos(this.hoodsPos) : undefined; }
  public get leftElbowPadPos2D() { return this.leftElbowPadPos ? getLeftProjectedPos(this.leftElbowPadPos) : undefined; }
  public get rightElbowPadPos2D() { return this.rightElbowPadPos ? getLeftProjectedPos(this.rightElbowPadPos) : undefined; }
  public get leftShifterPos2D() { return this.leftShifterPos ? getLeftProjectedPos(this.leftShifterPos) : undefined; }
  public get rightShifterPos2D() { return this.rightShifterPos ? getLeftProjectedPos(this.rightShifterPos) : undefined; }
  public get elbowPadsPos2D() { return this.elbowPadsPos ? getLeftProjectedPos(this.elbowPadsPos) : undefined; }
  public get shiftersPos2D() { return this.shiftersPos ? getLeftProjectedPos(this.shiftersPos) : undefined; }


  ///// Saddle /////

  // Horizontal distance between crank axle and sit position
  public get seatX() { return this.horizontalDistance(this.crankPos2D, this.sitPos2D); }

  // Vertical distance between crank axle and sit position
  public get seatY() { return this.verticalDistance(this.crankPos2D, this.sitPos2D); }

  // Euclidean distance between crank axle and sit position
  public get seatHeight() { return this.euclideanDistance(this.crankPos2D, this.sitPos2D); }

  // Angle between ground and (crank axle - sit position)
  public get effectiveSeatAngle() {
    const p1 = this.crankPos2D;
    const p2 = this.sitPos2D;
    if(!p1 || !p2) {
      return undefined;
    }

    const dir = { x: p2.x - p1.x, y: p2.y - p1.y };
    const dist = Math.sqrt(dir.x * dir.x + dir.y * dir.y);
    const normDir = { x: dir.x / dist, y: dir.y / dist };

		const angleRad = Math.atan2(normDir.y, normDir.x);
    return radToDeg(angleRad);
  }

  // Horizontal distance between crank axle and sit position
  // (same as seatX)
  public get saddleSetback() { return this.seatX; }

  // Horizontal distance between crank axle and front of saddle
  // Used for UCI rules
  public get saddleFrontSetback() {
    const seatX = this.seatX;
    if(seatX) {
      return seatX - this.saddleSitPositionToFront;
    } else {
      return undefined;
    }
  }

  ///// Handle bar /////

  // Horizontal distance between crank axle and handle bar
  public get handlebarX() { return this.horizontalDistance(this.crankPos2D, this.handlebarPos2D); }

  // Vertical distance between crank axle and handle bar
  public get handlebarY() { return this.verticalDistance(this.crankPos2D, this.handlebarPos2D); }

  // Euclidean distance between sit position and handle bar
  public get handlebarReach() { return this.euclideanDistance(this.sitPos2D, this.handlebarPos2D); }

  // Vertical distance from sit position to handle bar (negative when handle bar is above sit position)
  public get handlebarDrop() {
    const p1 = this.sitPos2D;
    const p2 = this.handlebarPos2D;
    if(!p1 || !p2) {
      return undefined;
    }

    return p1.y - p2.y;
  }

  ///// Frame /////

  // Vertical distance between crank axle and head tube
  public get frameStack() { return this.verticalDistance(this.crankPos2D, this.headTubePos2D); }

  // Horizontal distance between crank axle and head tube
  public get frameReach() { return this.horizontalDistance(this.crankPos2D, this.headTubePos2D); }

  ///// Hoods /////

  // Horizontal distance between crank axle and hoods
  public get hoodsX() { return this.horizontalDistance(this.crankPos2D, this.hoodsPos); }

  // Vertical distance between crank axle and hoods
  public get hoodsY() { return this.verticalDistance(this.crankPos2D, this.hoodsPos2D); }

  // Euclidean distance between sit position and hoods
  public get hoodsReach() { return this.euclideanDistance(this.sitPos2D, this.hoodsPos2D); }

  // Vertical distance from sit position to hoods (negative when hoods are above sit position)
  public get hoodsDrop() {
    if(!this.sitPos || !this.hoodsPos) {
      return undefined;
    }

    const sitPos2D = getLeftProjectedPos(this.sitPos);
    const hoods2D = getLeftProjectedPos(this.hoodsPos);

    return sitPos2D.y - hoods2D.y;
  }

  // True when both hood markers are used
  public get hoodsBothSides(): boolean {
    return this.leftHoodPos != undefined && this.rightHoodPos != undefined;
  }

  // Horizontal distance between left and right hood
  public get hoodsWidth(): number|undefined {
    return this.getWidth(this.leftHoodPos, this.rightHoodPos);
  }

  // Vertical distance between left and right hood (positive when right is above left)
  public get hoodsHeightDifference(): number|undefined {
    return this.getHeightDifference(this.leftHoodPos, this.rightHoodPos);
  }

  ///// Elbow Pads /////

  // Horizontal distance between crank axle and elbow pads
  public get elbowPadsX() { return this.horizontalDistance(this.crankPos2D, this.elbowPadsPos2D); }

  // Vertical distance between crank axle and elbow pads
  public get elbowPadsY() { return this.verticalDistance(this.crankPos2D, this.elbowPadsPos2D); }

  // Euclidean distance between sit position and elbow pads
  public get elbowPadsReach() { return this.euclideanDistance(this.sitPos2D, this.elbowPadsPos2D); }

  // Vertical distance from sit position to elbow pads (negative when elbow pads are above sit position)
  public get elbowPadsDrop() {
    if(!this.sitPos || !this.elbowPadsPos) {
      return undefined;
    }

    const sitPos2D = getLeftProjectedPos(this.sitPos);
    const elbowPads2D = getLeftProjectedPos(this.elbowPadsPos);

    return sitPos2D.y - elbowPads2D.y;
  }

  // True when both elbow pad markers are used
  public get elbowPadsBothSides(): boolean {
    return this.leftElbowPadPos != undefined && this.rightElbowPadPos != undefined;
  }

  // Horizontal distance between left and right elbow pad
  public get elbowPadsWidth(): number|undefined {
    return this.getWidth(this.leftElbowPadPos, this.rightElbowPadPos);
  }

  // Vertical distance between left and right elbow pad (positive when right is above left)
  public get elbowPadsHeightDifference(): number|undefined {
    return this.getHeightDifference(this.leftElbowPadPos, this.rightElbowPadPos);
  }

  ///// Shifters /////

  // Horizontal distance between crank axle and shifters
  public get shiftersX() { return this.horizontalDistance(this.crankPos2D, this.shiftersPos2D); }

  // Vertical distance between crank axle and shifters
  public get shiftersY() { return this.verticalDistance(this.crankPos2D, this.shiftersPos2D); }

  // Euclidean distance between sit position and shifters
  public get shiftersReach() { return this.euclideanDistance(this.sitPos2D, this.shiftersPos2D); }

  // Horizontal distance between crank axle and shifters
  public get shiftersHorizontalReach() {
    if(!this.crankPos || !this.shiftersPos) {
      return undefined;
    }

    const crankPos2D = getLeftProjectedPos(this.crankPos);
    const shifters2D = getLeftProjectedPos(this.shiftersPos);

    return Math.abs(shifters2D.x - crankPos2D.x);
  }

  // Vertical distance from sit position to shifters (negative when shifters are above sit position)
  public get shiftersDrop() {
    if(!this.sitPos || !this.shiftersPos) {
      return undefined;
    }

    const sitPos2D = getLeftProjectedPos(this.sitPos);
    const shifters2D = getLeftProjectedPos(this.shiftersPos);

    return sitPos2D.y - shifters2D.y;
  }

  // True when both shifter markers are used
  public get shiftersBothSides(): boolean {
    return this.leftShifterPos != undefined && this.rightShifterPos != undefined;
  }

  // Horizontal distance between left and right shifter
  public get shiftersWidth(): number|undefined {
    return this.getWidth(this.leftShifterPos, this.rightShifterPos);
  }

  // Vertical distance between left and right shifter (positive when right is above left)
  public get shiftersHeightDifference(): number|undefined {
    return this.getHeightDifference(this.leftShifterPos, this.rightShifterPos);
  }

  // Needed for UCI rules
  public get shiftersPadsDrop(): number|undefined {
    if(!this.shiftersPos || !this.elbowPadsPos) {
      return undefined;
    }

    const shifters2D = getLeftProjectedPos(this.shiftersPos);
    const elbowPads2D = getLeftProjectedPos(this.elbowPadsPos);

    return shifters2D.y - elbowPads2D.y;
  }

  /////

  constructor(public readonly recording: MotionRecordingInfo, public readonly data: MotionData) {
    this.runAnalysis();

  }

  runAnalysis() {
    // We use the average position for our bike points
    this.pointAnalysis = new PointAnalysis(this.data);

    // We offset the marker thickness
    const noOffset: Vector3 = {x: 0, y: 0, z: 0};
    const vertOffset: Vector3 = {x: 0, y: -markerThickness, z: 0};
    const leftOffset: Vector3 = {x: markerThickness, y: 0, z: 0};
    const rightOffset: Vector3 = {x: -markerThickness, y: 0, z: 0};

    this.crankPos = this.getAvgPos(TrackingPoint.CrankLeft, leftOffset);
    this.sitPos = this.getAvgPos(TrackingPoint.SitPosition, vertOffset);

    this.handlebarPos = this.getAvgPos(TrackingPoint.HandleBar, vertOffset);
    this.headTubePos = this.getAvgPos(TrackingPoint.HeadTube, leftOffset);
    this.leftHoodPos = this.getAvgPos(TrackingPoint.LeftBrakeLeverHood, vertOffset);
    this.rightHoodPos = this.getAvgPos(TrackingPoint.RightBrakeLeverHood, vertOffset);

    this.leftElbowPadPos = this.getAvgPos(TrackingPoint.LeftElbowPad, vertOffset);
    this.rightElbowPadPos = this.getAvgPos(TrackingPoint.RightElbowPad, vertOffset);
    this.leftShifterPos = this.getAvgPos(TrackingPoint.LeftShifter, vertOffset);
    this.rightShifterPos = this.getAvgPos(TrackingPoint.RightShifter, vertOffset);
  }

  getAvgPos(pointType: number, offset: Vector3) {
    var result = undefined;

    const pointName = TrackingPoint.pointName(pointType);
    const point = this.pointAnalysis.metrics.pointMetrics[pointType];
    if(point) {
      result = point.avg;
      result.x += offset.x;
      result.y += offset.y;
      result.z += offset.z;

      console.log(pointName + " pos: " + JSON.stringify(result));
    } else {
      console.warn(pointName + " not found!");
    }

    return result;
  }

  ///// Utils /////

  horizontalDistance(from? : Vector2, to? : Vector2) {
    if(!from || !to) {
      return undefined;
    }

    return Math.abs(to.x - from.x);
  }

  verticalDistance(from? : Vector2, to? : Vector2) {
    if(!from || !to) {
      return undefined;
    }

    return Math.abs(to.y - from.y);
  }

  euclideanDistance(from? : Vector2, to? : Vector2) {
    if(!from || !to) {
      return undefined;
    }

    return getDistance(from, to);
  }

  getCenter(p1? : Vector3, p2? : Vector3): Vector3|undefined {
    const x = (p1 && p2) ? (p1.x + p2.x) / 2 : p1 ? p1.x : p2 ? p2.x : undefined;
    const y = (p1 && p2) ? (p1.y + p2.y) / 2 : p1 ? p1.y : p2 ? p2.y : undefined;
    const z = (p1 && p2) ? (p1.z + p2.z) / 2 : p1 ? p1.z : p2 ? p2.z : undefined;
    return (x && y && z) ? { x: x, y: y, z: z } : undefined;
  }

  // Note: from p1 to p2
  getHeightDifference(p1? : Vector3, p2? : Vector3): number|undefined {
    // We need both points
    if(!p1 || !p2) {
      return undefined
    }

    return p2.y - p1.y;
  }

  getWidth(p1? : Vector3, p2? : Vector3): number|undefined {
    // We need both points
    if(!p1 || !p2) {
      return undefined
    }

    return p2.x - p1.x;
  }

  // Position info relative to the crank position
  public positionInfo(pos2D? : Vector2) {
    const crank2D = this.crankPos2D;
    if(pos2D && crank2D) {
      const diffX = pos2D.x - crank2D.x;
      const diffY = pos2D.y - crank2D.y;
      return `[${(diffX * 1000).toFixed()}mm; ${(diffY * 1000).toFixed()}mm]`;
    } else {
      return "/";
    }
  }

}
