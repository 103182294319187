import { OnInit, OnDestroy, AfterViewInit, Component, ViewChild, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, of, switchMap } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BrmCloudService } from '@shared/brm-cloud.service';
import { BrmDataService } from '@shared/brm-data.service';
import { UserDocument } from '@shared/lib/user-document.interface';

@Component({
  selector: 'motion-operator-cyclists-list',
  templateUrl: './operator-cyclists-list.component.html',
  styleUrls: ['./operator-cyclists-list.component.scss']
})


export class OperatorCyclistsListComponent implements AfterViewInit, AfterViewChecked {
  // Emitted when a cyclist is selected
  @Output() cyclistSelected = new EventEmitter<UserDocument>();

  userID: string | null;
  cyclists$: Observable<UserDocument[]>;

  selectedCyclist: UserDocument|null = null;
  _scrolledToSelectedCyclist = false;

  dataSource: MatTableDataSource<UserDocument>;

  // Note: we use *ngIf so the table does not always exist
  @ViewChild('table') table!: MatTable<UserDocument>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [ 'name', 'email', 'lastRecordingTime' ];

  constructor(public readonly brmCloud: BrmCloudService, private brmData: BrmDataService, private firestore: AngularFirestore, private storage: AngularFireStorage, private router: Router) {

  }

  ngAfterViewInit(): void {
    // The cyclists collection is dependent on the logged on user
    this.cyclists$ = this.brmCloud.loggedOnUser$.pipe(
      switchMap(info => {
        if(info) {
          console.log("Querying all cyclists for operator with email", info.email);
          return this.brmData.operatorCyclists(info.userID);
        } else {
          return of([ ]);
        }
      })
    );

    // Set data source from cyclists
    this.cyclists$.subscribe(cyclists => {
      this.dataSource = new MatTableDataSource(cyclists);

      // Custom filter
      this.dataSource.filterPredicate = (data, filter) => {
        return this.checkFilter(data, filter);
      };

      // Scroll to selected cyclist
      this._scrolledToSelectedCyclist = false;
      if(this.selectedCyclist) {
        this.scrollToCyclist(this.selectedCyclist);
      }
    });

    // Store current user ID
    this.brmCloud.loggedOnUser$.subscribe(userDoc => {
      this.userID = userDoc?.userID;
    });
  }

  ngAfterViewChecked(): void {
    // Scroll to selected cyclist if it was not ready before
    if(!this._scrolledToSelectedCyclist && this.selectedCyclist) {
      if(this.scrollToCyclist(this.selectedCyclist)) {
        this._scrolledToSelectedCyclist = true;
      }
    }
  }

  selectCyclist(cyclist: UserDocument, emitSignal: boolean = true) {
    console.log("Cyclist selected with name " + this.brmCloud.fullName(cyclist));
    this.selectedCyclist = cyclist;

    // Scroll to selected row
    this.scrollToCyclist(cyclist);

    if(emitSignal) {
      this.cyclistSelected.emit(cyclist);
    }
  }

  scrollToCyclist(cyclist: UserDocument) {
    // Scroll to selected row
    const elName = `row-${cyclist.userID}`;
    const el = document.getElementById(elName);
    //console.log("Scrolling to", elName, el);
    el?.scrollIntoView({ block: 'center', });

    return (el != null);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log("Filtering cyclists:", filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  checkFilter(userDoc: UserDocument, filter: string) {
    // Check email address
    const checkEmail = userDoc.email.toLowerCase();
    if(checkEmail.indexOf(filter) >= 0) {
      return true;
    }

    // Check name
    const checkName = this.brmCloud.fullName(userDoc)?.toLowerCase();
    if(checkName.indexOf(filter) >= 0) {
      return true;
    }

    return false;
  }
}
