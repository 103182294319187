import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum View {
  Left = 0,
  Top,
  Back,
  Perspective
}

@Component({
  selector: 'motion-view-selection',
  templateUrl: './view-selection.component.html',
  styleUrls: ['./view-selection.component.scss']
})
export class ViewSelectionComponent implements OnInit {

  // Expose enum
  View = View;

  // Optional 3D view
  @Input() add3DView: boolean = false;

  // Emitted when a view is clicked (also when that view is already selected)
  @Output() viewClicked = new EventEmitter<View>();

  projectionAxis: View = View.Left;

  /////

  constructor() {

  }

  ngOnInit(): void {
  }

  /////

  handleClick(view: View) {
    this.viewClicked.emit(view);
  }

}
