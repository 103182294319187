<brm-card *ngIf="bikeAnalysis && bikeAnalysis.hoodsPos" title="Brake Lever Hoods">
  <!-- Image dependent on bicycle type -->
  <img id="bikeImage" *ngIf="bikeAnalysis.isRoadBike" src="assets/bike-report/Road Bike Report - Brake Lever Hoods.svg" alt="Bike" />
  <img id="bikeImage" *ngIf="bikeAnalysis.isMountainBike" src="assets/bike-report/Mountain Bike Report - Brake Lever Hoods.svg" alt="Bike" />

  <div class="content">
    <div class="titles">
      <p class="xy">Hoods X (mm): </p>
      <p class="xy">Hoods Y (mm): </p>
      <p>Hoods reach (mm): </p>
      <p>Hoods drop (mm): </p>
      <p>Handlebar width (mm): </p>
      <p>Height difference (mm): </p>
    </div>
    <div class="values">
      <p>{{lengthMM(bikeAnalysis.hoodsX)}}</p>
      <p>{{lengthMM(bikeAnalysis.hoodsY)}}</p>
      <p>{{lengthMM(bikeAnalysis.hoodsReach)}}</p>
      <p>{{lengthMM(bikeAnalysis.hoodsDrop)}}</p>
      <p>{{lengthMM(bikeAnalysis.hoodsWidth)}}</p>
      <p>{{diffMM(bikeAnalysis.hoodsHeightDifference)}}</p>
    </div>
  </div>
</brm-card>
