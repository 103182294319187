<div class="row">
  <mat-form-field>
    <mat-label>Point 1</mat-label>
    <mat-select [(ngModel)]="settings.pointID1" [ngModelOptions]="{standalone: true}">
      <mat-option *ngFor="let point of availablePoints" [value]="point.pointID" [disabled]="settings.pointID2 === point.pointID">{{point.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Point 2</mat-label>
    <mat-select [(ngModel)]="settings.pointID2" [ngModelOptions]="{standalone: true}">
      <mat-option value="No Point">No Point</mat-option>
      <mat-option *ngFor="let point of availablePoints" [value]="point.pointID" [disabled]="settings.pointID1 === point.pointID">{{point.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-slide-toggle [(ngModel)]="settings.drawMidpoint" [ngModelOptions]="{standalone: true}">Draw midpoint</mat-slide-toggle>

  <mat-form-field>
    <mat-label>View</mat-label>
    <mat-select [(ngModel)]="settings.projectionAxis" [ngModelOptions]="{standalone: true}">
      <mat-option [value]="0">Left View</mat-option>
      <mat-option [value]="1">Top View</mat-option>
      <mat-option [value]="2">Back View</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-button-toggle-group [(ngModel)]="settings.projectionAxis" name="view" aria-label="View">
    <mat-button-toggle #leftView [value]="0"><img class="icon" [src]="leftView.checked ? 'assets/icons/ViewLeft - selected.png' : 'assets/icons/ViewLeft.png'" /></mat-button-toggle>
    <mat-button-toggle #topView [value]="1"><img class="icon" [src]="topView.checked ? 'assets/icons/ViewTop - selected.png' : 'assets/icons/ViewTop.png'" /></mat-button-toggle>
    <mat-button-toggle #backView [value]="2"><img class="icon" [src]="backView.checked ? 'assets/icons/ViewBack - selected.png' : 'assets/icons/ViewBack.png'" /></mat-button-toggle>
  </mat-button-toggle-group>
</div>

<brm-point-trace [pointAnalysis]="pointAnalysis" [pointTypes]="selectedPoints" [projectionAxis]="settings.projectionAxis" [drawMidpoint]="settings.drawMidpoint"></brm-point-trace>
