<div *ngIf="brmCloud.loggedOnUser$ | async; else notLoggedOn" class="mat-elevation-z8">
  <!-- Search Bar-->
  <mat-form-field fxFlexFill>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
  </mat-form-field>

  <table *ngIf="(cyclists$ | async)?.length; else noData" #table mat-table [dataSource]="dataSource" class="full-width-table" aria-label="Elements" (onDataChanged)="table.renderRows()">
    <!-- Cyclist name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let cyclist">
        {{ brmCloud.fullName(cyclist) }}
      </td>
    </ng-container>

    <!-- Cyclist email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let cyclist">{{cyclist.email}}</td>
    </ng-container>

    <!-- Last recording time Column -->
    <ng-container matColumnDef="lastRecordingTime">
      <th mat-header-cell *matHeaderCellDef>Last Recording Time</th>
      <td mat-cell *matCellDef="let cyclist">{{cyclist.lastRecordingDate | date:'medium'}}</td>
    </ng-container>

    <!-- Rows-->
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
    <tr mat-row
      [id]="'row-' + cyclist?.userID"
      *matRowDef="let cyclist; columns: displayedColumns;"
      [class.is-selected]="selectedCyclist?.userID === cyclist?.userID"
      (click)="selectCyclist(cyclist)"
    ></tr>
  </table>

  <ng-template #noData>
    <div style="padding: 1em">There are no cyclists with you as operator.</div>
  </ng-template>
</div>

<ng-template #notLoggedOn>
  <a href="javascript:void(0);" role="button" (click)="brmCloud.openLoginDialog()">Log on</a> to see your data.
</ng-template>
